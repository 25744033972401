import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { isNil, omitBy } from 'lodash-es';
import MembersResult from './members-result';
import useApi from '../../hooks/useApi';
import { DataVerificationRequest, Member, TENANTS, Tenant } from '../../types';
import { styles } from './style';
import {
  InputDataVerificationSchema,
  DateOfBirthDataVerificationSchema,
  EmailDataVerificationSchema,
} from '../../schemas';
import './styles.less';
import { padding } from '@mui/system';

const initialFormData = {
  value: undefined,
  valid: true,
  touched: false,
  error: false,
};

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

const SearchMember = () => {
  const [member, setMember] = useState<any>({ dateOfBirth: null, tenant: Tenant.FLORIDA_BLUE });

  const [formData, setFormData] = useState<any>({
    tenant: { ...initialFormData, schema: InputDataVerificationSchema },
    memberId: { ...initialFormData },
    insurer: { ...initialFormData },
    zip: { ...initialFormData },
    mrn: { ...initialFormData },
    npi: { ...initialFormData },
    firstName: { ...initialFormData, schema: InputDataVerificationSchema },
    lastName: { ...initialFormData, schema: InputDataVerificationSchema },
    dateOfBirth: { ...initialFormData, schema: DateOfBirthDataVerificationSchema },
    email: { ...initialFormData, schema: EmailDataVerificationSchema },
    organisationId: { ...initialFormData },
  });

  const [searchingMember, setSearchingMember] = useState<any>({ dateOfBirth: null });
  const [membersResult, setMembersResult] = useState<Member[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isApiLookupEnabled, setIsApiLookupEnabled] = useState<boolean>(false);

  const { dataLookup } = useApi();

  const validateField = (value, schema) => {
    try {
      const result = schema.validateSync(value);
      if (!result?.error) {
        return false;
      }
    } catch (err: any) {
      return err?.message || err;
    }
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitted(true);
      if (Object.values(formData).some((el: any) => !el.valid)) return;

      setIsLoading(true);

      const { members } = await dataLookup({
        ...omitBy(member, isNil),
        searchByApi: isApiLookupEnabled,
        dateOfBirth: member.dateOfBirth.format(DATE_FORMAT),
      } as DataVerificationRequest);
      setMembersResult(members);
      setSearchingMember(member);
    } catch (err) {
      setMembersResult([]);
      setIsSubmitted(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getRequiredField = (field: string) => {
    switch (member.tenant) {
      case Tenant.ALEDADE:
        return ['memberId', 'firstName', 'lastName', 'dateOfBirth', 'email'].some(
          (el) => el === field,
        );
      case Tenant.AGILON:
        return ['memberId', 'firstName', 'lastName', 'dateOfBirth', 'mrn'].some(
          (el) => el === field,
        );
      case Tenant.ANTHEM:
      case Tenant.UHC:
        return ['memberId', 'firstName', 'lastName', 'dateOfBirth', 'organisationId', 'mrn'].some(
          (el) => el === field,
        );
      case Tenant.FLORIDA_BLUE:
      case Tenant.MAXHEALTH:
      case Tenant.MY_CARE:
      case Tenant.HUMANA:
      case Tenant.MOCK:
        return (
          ['memberId', 'firstName', 'lastName', 'dateOfBirth'].some((el) => el === field) &&
          isApiLookupEnabled
        );
      case Tenant.PRIVIA:
        return ['memberId', 'firstName', 'lastName', 'dateOfBirth', 'zip'].some(
          (el) => el === field,
        );
      case Tenant.YUVO:
        return ['firstName', 'lastName', 'dateOfBirth'].some((el) => el === field);
      default:
        return false;
    }
  };

  const genericTextField = (
    field: keyof DataVerificationRequest,
    props: TextFieldProps | any,
    convertToValue: (value) => any = (value) => value,
    helperText?: string,
  ) => {
    return (
      <TextField
        helperText={helperText || (isSubmitted && formData[field].error)}
        value={member?.[field] ?? ''}
        variant="outlined"
        error={isSubmitted && !formData[field].valid}
        required={getRequiredField(field)}
        disabled={isLoading}
        onChange={({ target }) => {
          const value = !target?.value ? null : convertToValue(target?.value);
          setMember({ ...member, [field]: value });
          setFormData({
            ...formData,
            [field]: {
              ...formData[field],
              touched: true,
              valid: formData[field]?.schema
                ? !validateField(value, formData[field].schema)
                : formData[field].valid,
              error: formData[field]?.schema
                ? validateField(value, formData[field].schema)
                : formData[field].error,
              value,
            },
          });
        }}
        {...props}
      />
    );
  };

  const changeLookupType = (tenant: Tenant) => {
    setMember({ ...member, tenant });
    if (
      (tenant === Tenant.ANTHEM ||
        tenant === Tenant.ALEDADE ||
        tenant === Tenant.UHC ||
        tenant === Tenant.MOCK ||
        tenant === Tenant.HUMANA) &&
      !isApiLookupEnabled
    ) {
      setIsApiLookupEnabled(true);
    }
  };

  return (
    <div className="search-member-form">
      {isLoading && <CircularProgress size={34} thickness={4} sx={styles.loaderPosition} />}
      <Box
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
        id="member-lookup-form"
        sx={{ ...styles.gridRoot, ...styles.userFormRoot } as any}
      >
        <Box sx={{ ...styles.gridRoot, ...styles.memberProductsRow } as any}>
          <FormControlLabel
            label="Lookup by API"
            control={
              <Checkbox
                style={{ padding: '9px' }}
                size="small"
                color="default"
                inputProps={{ 'aria-label': 'checkbox with default color' }}
                checked={isApiLookupEnabled}
                disabled={
                  member.tenant === Tenant.ANTHEM ||
                  member.tenant === Tenant.ALEDADE ||
                  member.tenant === Tenant.UHC ||
                  member.tenant === Tenant.MOCK ||
                  member.tenant === Tenant.HUMANA ||
                  isLoading
                }
                onChange={({ target: { checked } }) => setIsApiLookupEnabled(checked)}
              />
            }
          />
        </Box>
        <Box sx={{ ...styles.gridRoot, ...styles.memberProductsRow } as any}>
          <Autocomplete
            value={member?.tenant || null}
            options={TENANTS}
            renderInput={(params) => (
              <TextField variant="outlined" {...params} label="Tenant" required />
            )}
            onChange={(_, value) => changeLookupType(value)}
            disabled={isLoading}
          />
          {genericTextField('firstName', {
            label: 'First Name',
          })}
        </Box>
        <Box sx={{ ...styles.gridRoot, ...styles.memberProductsRow } as any}>
          {genericTextField('memberId', {
            label: 'Member ID',
          })}
          {genericTextField('lastName', {
            label: 'Last Name',
          })}
        </Box>
        <Box sx={{ ...styles.gridRoot, ...styles.memberProductsRow } as any}>
          {genericTextField('insurer', { label: 'Insurer' })}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date of Birth"
              inputFormat="MM/DD/YYYY"
              value={member?.dateOfBirth}
              onChange={(value) => {
                const systemTimezoneOffset = dayjs().utcOffset();
                const selectedDate = dayjs(value).subtract(systemTimezoneOffset, 'minute');

                setFormData({
                  ...formData,
                  dateOfBirth: {
                    ...formData.dateOfBirth,
                    touched: true,
                    valid: value
                      ? !validateField(selectedDate.toDate(), formData.dateOfBirth.schema)
                      : formData.dateOfBirth.valid,
                    error: value
                      ? validateField(selectedDate.toDate(), formData.dateOfBirth.schema)
                      : formData.dateOfBirth.error,
                    value: selectedDate.isValid() ? selectedDate.toDate() : undefined,
                  },
                });

                if (!selectedDate.isValid()) return;

                setMember({
                  ...member,
                  dateOfBirth: value,
                });
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params} // Should be first
                  variant="outlined"
                  error={isSubmitted && !formData.dateOfBirth.valid}
                  helperText={isSubmitted && formData.dateOfBirth.error}
                  required
                  disabled={isLoading}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ ...styles.gridRoot, ...styles.memberProductsRow } as any}>
          {genericTextField('zip', { label: 'Zip Code' })}
          {genericTextField('email', {
            label: 'User Email',
          })}
        </Box>
        <Box sx={{ ...styles.gridRoot, ...styles.memberProductsRow } as any}>
          {genericTextField('mrn', {
            label: 'MRN',
          })}
          {genericTextField('organisationId', {
            label: 'Organisation ID',
          })}
        </Box>
        <Box sx={{ ...styles.gridRoot, ...styles.memberProductsRow } as any}>
          {genericTextField('npi', {
            label: 'User Npi',
            type: 'number',
          })}
          <Button
            type="submit"
            form="member-lookup-form"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            Lookup
          </Button>
        </Box>
      </Box>

      {membersResult && <MembersResult members={membersResult} searchParams={searchingMember} />}
    </div>
  );
};

export default SearchMember;
