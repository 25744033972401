/* eslint-disable */
import GeoType from './GeoType';

const geoTypeToGeocoderRequest = (geo: GeoType) => {
  const { address, zip, geocode } = geo;
  if (geocode) {
    return { location: { lat: geocode.latitude, lng: geocode.longitude } };
  }
  return { address: address || zip };
};

const geocode: (geo: GeoType) => Promise<google.maps.GeocoderResult[]> = (geo: GeoType) => {
  const geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode(geoTypeToGeocoderRequest(geo), (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        reject(status);
      } else {
        resolve(results!);
      }
    });
  });
};

const getZipCodeItem = (geocoderResult: google.maps.GeocoderResult) =>
  geocoderResult.address_components.find((addComponent) =>
    addComponent.types.includes('postal_code'),
  );

const getState = (geocoderResult: google.maps.GeocoderResult) =>
  geocoderResult.address_components.find((addComponent) =>
    addComponent.types.includes('administrative_area_level_1'),
  );

const getCity = (geocoderResult: google.maps.GeocoderResult) =>
  geocoderResult.address_components.find(
    (addComponent) =>
      addComponent.types.includes('locality') ||
      addComponent.types.includes('administrative_area_level_1'),
  );

const getClosestZipCode = async (
  geocoderResult: google.maps.GeocoderResult,
  shouldRetryByGeocode: boolean,
) => {
  let zipCodeItem = getZipCodeItem(geocoderResult);

  if (!zipCodeItem && shouldRetryByGeocode) {
    // if the address dont have a zip code - gets the nearest one by geocode
    const {
      geometry: { location },
    } = geocoderResult;
    try {
      const retryResults = await geocode({
        geocode: {
          latitude: location.lat(),
          longitude: location.lng(),
        },
      });
      const [firstRetryResult] = retryResults;
      zipCodeItem = getZipCodeItem(firstRetryResult);
    } catch (error) {
      console.error(`Google api failed with error: ${error}`);
    }
  }

  return zipCodeItem;
};

const getGeoAddressAndZip: (
  geo: GeoType,
  withState?: boolean,
  withCity?: boolean,
) => Promise<GeoType> = async (geo: GeoType, withState?: boolean, withCity?: boolean) => {
  if (geo.geocode && geo.address) return geo;
  try {
    const [firstGeocoderResult] = await geocode(geo);
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      formatted_address,
      geometry: { location },
    } = firstGeocoderResult;
    const zip = await getClosestZipCode(firstGeocoderResult, !geo.geocode);

    const state = withState ? { state: getState(firstGeocoderResult)?.short_name } : {};
    const city = withCity ? { city: getCity(firstGeocoderResult)?.long_name } : {};

    return {
      address: formatted_address,
      zip: zip?.long_name,
      geocode: {
        latitude: location.lat(),
        longitude: location.lng(),
      },
      ...city,
      ...state,
    };
  } catch (error) {
    console.error(`Google api failed with error: ${error}`);
    return geo;
  }
};

export default getGeoAddressAndZip;
