import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import * as uuid from 'uuid';
import { useForm } from 'react-form';
import { BkmdModal, ModalHeader, Toast } from '@getvim/atomic-ui';
import { isEmpty, isString, isDate } from 'lodash-es';
import { Loader, Button } from '@getvim/atomic-ui';

import { AppointmentType, CreatedAppointmentType } from '../../types';
import Field from '../form-field';
import { fields } from './appointmentModalDef';
import useApi from '../../hooks/useApi';

import './index.less';
import 'react-datepicker/dist/react-datepicker.css';

const { ToastTypes, createToast } = Toast;

const AppointmentModal = (props: {
  member?: any;
  closeModal?: any;
  updateSlots?: any;
  modalVisibility?: any;
  providers: Array<any>;
  patients: Array<any>;
  providerSlots?: Array<{
    startTime: string;
    endTime: string;
  }>;
  defaultSelectedProvider?: any;
  getPatients?: any;
  getProviders?: any;
  onAppointmentCreate?: (appointment: AppointmentType) => void;
}) => {
  const {
    closeModal,
    modalVisibility,
    providers,
    patients,
    providerSlots = [],
    defaultSelectedProvider,
    updateSlots,
    getPatients,
    getProviders,
    onAppointmentCreate,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [slots, setSlots] = useState<any>(providerSlots);

  const [hasEmptyRequiredFields, setHasEmptyRequiredFields] = useState<boolean>();
  const [isWithChanges, setIsWithChanges] = useState(false);

  const getNearest30MinuteSlot = () => {
    const momentTime = moment(new Date(), 'HH:mm').seconds(0).milliseconds(0);
    const minutes = momentTime.minutes();

    if (minutes < 30) {
      momentTime.minutes(30);
    } else {
      momentTime.add(1, 'hours').minutes(0);
    }

    const verifyOcuppiedSlot = () => {
      return Boolean(
        slots.find(({ startTime }) => {
          return momentTime.toDate().toISOString() === new Date(startTime).toISOString();
        }),
      );
    };

    while (verifyOcuppiedSlot()) {
      momentTime.add(30, 'minutes');
    }

    setFormData((prev) => {
      return {
        ...prev,
        startTime: momentTime.toDate(),
      };
    });
  };

  const initialData = {
    reasonForVisit: undefined,
    startTime: undefined,
    provider: undefined,
    patient: undefined,
    externalId: undefined,
  };

  const api = useApi();

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>(
    !isEmpty(modalVisibility)
      ? modalVisibility
      : {
          ...initialData,
          provider: providers
            .filter((provider: { id: string }) => provider.id === defaultSelectedProvider.id)
            .find((provider: { firstName: any; lastName: any }) => ({
              ...provider,
              providerName: `${provider?.firstName} ${provider?.lastName}`,
            })),
          patient: patients[0],
        },
  );

  useEffect(() => {
    const isEmpty = isWithEmptyRequiredFields();

    setHasEmptyRequiredFields(isEmpty);
  }, [formData]);

  useEffect(() => {
    setSlots(providerSlots);
    if (isEmpty(modalVisibility)) {
      getNearest30MinuteSlot();
    }
  }, [providerSlots]);

  const isWithEmptyRequiredFields = (): boolean => {
    const requiredFields = Object.values(fields)
      //@ts-ignore
      .filter((item) => item?.required)
      .map(({ field }) => field);

    const emptyRequiredField = requiredFields.find((fieldName) => {
      return isDate(formData[fieldName]) ? !formData[fieldName] : isEmpty(formData[fieldName]);
    });

    return Boolean(emptyRequiredField);
  };

  const getDefaultSelectedProvider = () => {
    return !isEmpty(modalVisibility)
      ? [
          {
            ...formData?.provider,
            providerName: `${formData?.provider?.firstName} ${formData?.provider?.lastName}`,
          },
        ]
      : [
          ...providers
            .filter((provider: { id: string }) => provider.id === defaultSelectedProvider.id)
            .map((provider: { firstName: any; lastName: any }) => ({
              ...provider,
              providerName: `${provider?.firstName} ${provider?.lastName}`,
            })),
        ];
  };

  const getDefaultSelectedPatient = () => {
    return !isEmpty(modalVisibility)
      ? [
          {
            ...formData?.patient,
            patientName: `${formData?.patient?.firstName} ${formData?.patient?.lastName}`,
          },
        ]
      : [{ ...patients[0], patientName: `${patients[0]?.firstName} ${patients[0]?.lastName}` }];
  };

  const defaultValues = useMemo(
    () => ({
      ...formData,
      provider: getDefaultSelectedProvider()[0],
      patient: getDefaultSelectedPatient()[0],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formData.startTime],
  );

  const formProps = useForm({
    defaultValues,
    onSubmit: async () => {
      const appt =
        !!modalVisibility && modalVisibility?.id
          ? await bookAppointment(true)
          : await bookAppointment();
      onAppointmentCreate &&
        onAppointmentCreate({
          ...appt,
          provider: { id: appt.providerId },
          assessment: [],
          metadata: [],
        });
    },
  });

  const { Form, setFieldValue } = formProps;

  const bookAppointment = async (reschedule = false): Promise<CreatedAppointmentType> => {
    const payload = {
      // toISOString adds microseconds that break slot availability check
      startDate: moment(formData.startTime).seconds(0).milliseconds(0).toISOString(),
      endDate: moment(formData.startTime)
        .add(30, 'minutes')
        .seconds(0)
        .milliseconds(0)
        .toISOString(),
      npi: formData.provider.npi,
      reasonForVisit: formData.reasonForVisit?.trim(),
      locationId: providers.find((provider) => provider.id === formData.provider.id).clinics[0]
        .location.id,
      providerId: formData.provider.id,
      patientId: formData.patient.id,
      externalId: formData.externalId?.trim(),
    };

    setIsLoading(true);
    try {
      const bookedAppt = reschedule
        ? await api.updateAppointment({ ...payload, id: modalVisibility.id })
        : await api.bookAppointment(payload);

      updateSlots();
      closeModal();
      setFormData({ ...initialData });

      return bookedAppt;
    } catch (err: any) {
      createToast({
        title: 'Error!',
        message: err.message,
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });

      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const excludeBookedSlots = (date: Date) => {
    return slots.find((slot: { startTime: string | number | Date }) => {
      return date.getTime() === new Date(slot.startTime).getTime();
    }) || date.getTime() < new Date().getTime()
      ? 'react-datepicker__time-list-item--disabled'
      : null;
  };

  const getProviderAvailability = async ({ id, date }: { id: string; date?: Date }) => {
    const provider = providers.find((provider: { id: string }) => provider.id === id);
    const locationId = provider.clinics[0]?.location?.id;
    if (!locationId) return;

    const slotsDate = date || new Date();
    const providerSlots = await api.getProviderAvailability({
      id: provider.id,
      locationId,
      startDate: moment(slotsDate).startOf('month').toDate(),
      days: moment(slotsDate).daysInMonth(),
    });
    setSlots(providerSlots);
  };

  const updateFormData = ({ field, value }: { field: string; value: any }) => {
    setIsWithChanges(true);

    setFormData((prevState: any) => ({
      ...prevState,
      [`${field}`]: value,
    }));
  };

  const modalBody = (
    <div className="body-section">
      <div className="appointment">
        <div className="appointment__select-wrapper">
          <Field
            className="select-container clean-input-container v2-input"
            labelKey="patientName"
            defaultSelected={getDefaultSelectedPatient()}
            renderMenuItemChildren={(name: React.ReactNode) => {
              return <div>{name}</div>;
            }}
            options={patients.map((patient: any) => ({
              ...patient,
              patientName: `${patient.firstName} ${patient.lastName}`,
            }))}
            onChange={(value: any[]) => {
              updateFormData({
                field: 'patient',
                value: value[0],
              });
            }}
            onInputChange={(value: string) => {
              if (getPatients) {
                getPatients(value);
              }
            }}
            id="patient-select"
            submitted={submitted}
            description="Patient*"
            placeholder="Select member"
            disableFilter={formData.patient?.id}
            clearButton
            {...fields.patient}
          />
        </div>

        <div className="appointment__title-wrapper">
          <Field
            className="select-container clean-input-container v2-input"
            submitted={submitted}
            value={
              isString(formData?.reasonForVisit)
                ? formData?.reasonForVisit
                : initialData.reasonForVisit
            }
            inputType="textarea"
            onChange={(e: any) => {
              return updateFormData({
                field: 'reasonForVisit',
                value: e.target.value?.replace(/^\s*/, ''),
              });
            }}
            description="Reason For Visit*"
            clearButton
            {...fields.reasonForVisit}
          />
        </div>
        <div className="appointment__select-wrapper">
          <Field
            className="select-container clean-input-container v2-input"
            labelKey="providerName"
            defaultSelected={getDefaultSelectedProvider()}
            renderMenuItemChildren={(name: React.ReactNode) => {
              return <div>{name}</div>;
            }}
            options={providers
              .map((provider: any) => ({
                ...provider,
                providerName: `${provider.firstName} ${provider.lastName}`,
              }))
              .filter((provider) => provider.clinics.length)}
            onChange={(value: any[]) => {
              const isSelectedSameProvider = value[0]?.id === formData.provider?.id;
              updateFormData({
                field: 'provider',
                value: value[0],
              });
              if (!isEmpty(value) && !isSelectedSameProvider) {
                // Reset appointment date and get selected provider availability
                getProviderAvailability({ id: value[0].id });
                updateFormData({
                  field: 'startTime',
                  value: null,
                });
                setFieldValue('startTime', undefined);
              }
            }}
            onInputChange={(value: string) => {
              if (getProviders) {
                getProviders(value);
              }
            }}
            id="provider-select"
            submitted={submitted}
            description="Provider*"
            placeholder="Select provider"
            disableFilter={formData.provider?.id}
            clearButton
            {...fields.provider}
          />
        </div>

        <div className="appointment__datepicker-wrapper">
          <Field
            className="select-container clean-input-container v2-input v2-style input"
            submitted={submitted}
            dateType
            value={formData?.startTime}
            onChange={(date: any) => {
              return updateFormData({
                field: 'startTime',
                value: date,
              });
            }}
            selected={formData?.startTime}
            timeClassName={(date: Date) => excludeBookedSlots(date)}
            onMonthChange={(date: any) =>
              getProviderAvailability({ id: defaultSelectedProvider.id, date })
            }
            label="DateTime Range*"
            clearButton
            {...fields.startTime}
          />
        </div>
        <div className="appointment__title-wrapper">
          <Field
            submitted={submitted}
            className="select-container clean-input-container v2-input v2-style"
            inputType
            value={formData.externalId}
            onChange={(e: { target: { value: string } }) => {
              updateFormData({
                field: 'externalId',
                value: e.target.value ? e.target.value?.replace(/^\s*/, '') : undefined,
              });
            }}
            label="External ID"
            clearButton
            {...fields.externalId}
          />
        </div>
      </div>
    </div>
  );

  return (
    <BkmdModal
      isOpen={!!modalVisibility}
      handleClose={closeModal}
      autoFocus={false}
      name="appointment-modal"
      className="appointment-modal-v2 sidebar-modal left"
      baseClassName="left-menu-backdrop"
    >
      <div id={`appointment-modal-${uuid.v1()}`}>
        <Form method="post" className="appointment-modal-form">
          <ModalHeader onClose={closeModal}>
            <div className="header-title">
              {!!modalVisibility && modalVisibility.id ? 'Edit appointment' : 'Add new appointment'}
            </div>
          </ModalHeader>

          <div className="dialog-body">
            {modalBody}
            {(isLoading || isEmpty(providers)) && (
              <Loader type="dots" size="small" label="Loading" />
            )}
          </div>
          <div className="dialog-footer">
            <Row className="footer-btns">
              <Col xs={6}>
                <Button
                  width="small"
                  buttonType="small"
                  bgColor="themedOutline"
                  className="cancel-btn"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
              </Col>

              <Col xs={6}>
                <Button
                  type="submit"
                  buttonType="small"
                  width="small"
                  className="apply-btn"
                  disabled={hasEmptyRequiredFields || !isWithChanges}
                  onClick={() => setSubmitted(true)}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </BkmdModal>
  );
};
export default AppointmentModal;
