import React, { useEffect, useState } from 'react';
import { deprecated } from '@getvim/atomic-ui';

import Loader from '../loader';
import { Provider } from '../../types';
import defaultAvatarImage from '../../assets/images/provider_avatar6.jpeg';
import './styles.less';
import { useUserData } from '../../hooks/useUserData';
import ConfirmDeleteModal from '../confirm-delete-modal';

const PROVIDERS_LIMIT = 10;

interface ProviderItem extends Provider {
  me?: boolean;
}

interface ProvidersListProps {
  providers: { data: ProviderItem[]; total: number };
  isLoading: boolean;
  handleSelect(item: Provider): void;
  activePage: number;
  setActivePage(page: number): void;
  getProviders: ({
    offset,
    limit,
    search,
  }: {
    offset: number;
    limit: number;
    search?: string;
  }) => void;
  deleteProvider(id: string): void;
}

const ProvidersList = (props: ProvidersListProps) => {
  const {
    providers,
    isLoading,
    handleSelect,
    activePage,
    setActivePage,
    getProviders,
    deleteProvider,
  } = props;

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [providerToBeDeleted, setProviderToBeDeleted] = useState<Provider | null>(null);

  const { isAdmin } = useUserData();

  useEffect(() => {
    if (deleteConfirmed && providerToBeDeleted) {
      deleteProvider(providerToBeDeleted.id);
      setProviderToBeDeleted(null);
      setDeleteConfirmed(false);
    }
  }, [deleteConfirmed]);

  const getPagesAmount = (itemsAmount: number, pageSize: number): number => {
    const remainder = itemsAmount % pageSize;
    const fullPagesAmount = (itemsAmount - remainder) / pageSize;

    return fullPagesAmount + (remainder ? 1 : 0);
  };

  return (
    <div className="providers-list">
      {isLoading && <Loader />}

      {!isLoading &&
        providers.data.map((provider: ProviderItem) => {
          const { id, fullName, gender, profilePicture, me } = provider;

          return (
            <div
              onClick={() => {
                handleSelect(provider);
              }}
              className="provider-item"
              key={`provider-${id}`}
            >
              <div className="provider-avatar">
                <img src={profilePicture || defaultAvatarImage} alt="" />
              </div>
              <div className="provider-text-side">
                <div className="provider-title">
                  <h4 className="provider-title__text">{fullName}</h4>
                  {!me && isAdmin && (
                    <h4
                      className="provider-title__delete-icon"
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setProviderToBeDeleted(provider);
                        setConfirmDeleteModalOpen(true);
                      }}
                    />
                  )}
                  {me ? ' (me)' : ''}
                </div>
                {gender ? (
                  <div className="gender">
                    <b>Gender:</b> {gender}
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      {!isLoading && (
        <deprecated.Pagination
          active={Number(activePage)}
          maxButtons={3}
          pagesNumber={getPagesAmount(providers.total, PROVIDERS_LIMIT)}
          onClick={(newPageNumber) => {
            getProviders({ offset: (newPageNumber - 1) * PROVIDERS_LIMIT, limit: PROVIDERS_LIMIT });
            setActivePage(newPageNumber);
          }}
        />
      )}
      {confirmDeleteModalOpen && providerToBeDeleted && (
        <ConfirmDeleteModal
          dataToBeDeleted={providerToBeDeleted.fullName}
          onConfirmed={() => {
            setDeleteConfirmed(true);
            setConfirmDeleteModalOpen(false);
          }}
          onClose={() => setConfirmDeleteModalOpen(false)}
          isOpen={confirmDeleteModalOpen}
        />
      )}
    </div>
  );
};

export default ProvidersList;
