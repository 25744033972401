import { Buffer } from 'buffer';
import config, { ConfigI } from '../config/index';

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function ab2str(buf) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return String.fromCharCode.apply(null, new Uint16Array(buf));
}

const encryptData = async ({ data }: { data: string }): Promise<string | undefined> => {
  const publicKey = config.VIM_PUBLIC_KEY;

  let cryptoKey;
  try {
    // base64 decode the string to get the binary data
    const binaryDerString = atob(publicKey);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);
    cryptoKey = await crypto.subtle.importKey(
      'spki',
      binaryDer,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );
  } catch (e) {
    console.error(e);
  }

  try {
    const dataBuff = new TextEncoder().encode(data);
    return Buffer.from(
      await crypto.subtle.encrypt({ name: 'RSA-OAEP' }, cryptoKey, dataBuff),
    ).toString('base64');
  } catch (e) {
    console.error(e);
  }
};

const encryptFileBuffer = async (buff: string) => {
  const data = new TextEncoder().encode(buff);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return ab2str(hash);
};

export { encryptFileBuffer, encryptData };
