export enum Tenant {
  UHC = 'uhc',
  AGILON = 'agilon',
  ANTHEM = 'anthem',
  ALEDADE = 'aledade',
  MAXHEALTH = 'maxhealth',
  MY_CARE = 'myCare',
  FLORIDA_BLUE = 'florida',
  MOCK = 'mock',
  PRIVIA = 'privia',
  YUVO = 'yuvo',
  HUMANA = 'humana',
}

export const TENANTS: Tenant[] = [
  Tenant.FLORIDA_BLUE,
  Tenant.UHC,
  Tenant.AGILON,
  Tenant.ALEDADE,
  Tenant.ANTHEM,
  Tenant.MAXHEALTH,
  Tenant.MY_CARE,
  Tenant.MOCK,
  Tenant.PRIVIA,
  Tenant.YUVO,
  Tenant.HUMANA,
];

export interface Member {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
  memberId?: string;
  mrn?: string;
  zip?: string;
  insurer?: string;
  lineOfBusiness?: string;
}

export interface DataVerificationRequest {
  tenant: Tenant;
  memberId?: string;
  insurer?: string;
  zip?: string;
  mrn?: string;
  firstName?: string;
  lastName?: string;
  dateOfBirth: Date;
  email?: string;
  organisationId?: string;
  npi?: number;
  searchByApi: boolean;
}

export interface DataVerificationResponse {
  members: Member[];
}
