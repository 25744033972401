import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Dashboard from './pages-v2/dashboard';
import Patients from './pages-v2/patients';
import Providers from './pages-v2/providers';
import PatientProfile from './pages-v2/patient-profile';
import Organizations from './pages-v2/organizations';
import Login from './pages-v2/login';
import IdpInitiatedLogin from './pages-v2/idp-initiated-login';
import Calendar from './pages-v2/calendar';
import Audit from './pages-v2/audit';
import DataVerification from './pages-v2/data-verification';
import PrivateRoute from './components-v2/private-route';
import { UserRole } from './types/user';

const RoutesV2Temporary = (props: { alias }) => {
  const { alias } = props;

  const V2_PREFIX = '/v2';

  return (
    <>
      <Route exact path={`${V2_PREFIX}/idp-initiated-login`} component={IdpInitiatedLogin} />
      <Route exact path={`${V2_PREFIX}/login`} component={Login} />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/providers`}
        roles={[UserRole.ADMIN]}
        render={() => <Providers {...props} />}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/patients`}
        render={() => <Patients />}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/audit`}
        roles={[UserRole.ADMIN]}
        render={() => <Audit />}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/data-verification`}
        roles={[UserRole.ADMIN, UserRole.DATA_ACCESS]}
        render={() => <DataVerification />}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/calendar`}
        render={() => <Calendar />}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/organizations`}
        roles={[UserRole.ADMIN]}
        render={() => <Organizations {...props} />}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/patient/:id`}
        render={(res: { match: { params: { id: any } } }) => {
          const { id } = res.match.params;

          return <PatientProfile patientId={id} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/providers`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/providers`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/patients`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/patients`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/patients`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/patients`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/calendar`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/calendar`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/calendar`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/calendar`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/organizations`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/organizations`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/patient/:id`}
        render={(res: { match: { params: { id: any } } }) => {
          const { id } = res.match.params;
          return <Redirect to={`${V2_PREFIX}/${alias}/patient/${id}`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/patient/:id`}
        render={(res: { match: { params: { id: any } } }) => {
          const { id } = res.match.params;
          return <Redirect to={`${V2_PREFIX}/${alias}/patient/${id}`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/audit`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/audit`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/data-verification`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/data-verification`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}/data-verification`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}/data-verification`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/`}
        render={() => {
          return <Redirect to={`${V2_PREFIX}/${alias}`} />;
        }}
        isV2
      />
      <PrivateRoute
        exact
        path={`${V2_PREFIX}/${alias}`}
        render={() => {
          return <Dashboard />;
        }}
        isV2
      />
    </>
  );
};

export default RoutesV2Temporary;
