export const styles = {
  inspect: {
    display: 'flex',
    alignItems: 'center',
  },
  box: { display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' },
  flexGrow: { flexGrow: 1 },
  button: {
    '&.MuiButton-root': {
      padding: '15px',
    },
    fontSize: '14px',
  },
};
