import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@getvim/atomic-ui';
import { debounce } from 'lodash-es';

import ProvidersList from '../../components-v2/providers-list';
import Search from '../../components-v2/search';
import UserHeaderInfo from '../../components-v2/user-header-info';
import { ItemModal } from '../../components-v2/provider-modal/itemModal';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import useApi from '../../hooks/useApi';
import { CreateProviderRequest, Provider } from '../../types';

import './styles.less';
import { GlobalContext } from '../../contexts/global';

const PROVIDERS_OFFSET = 0;
const PROVIDERS_LIMIT = 10;

interface ProviderItem extends Provider {
  me?: boolean;
}

const Providers = (props) => {
  const { fetchUserData } = props;
  const user = useUserData();

  const api = useApi();
  const history = useHistory();

  const [itemModalOpen, setItemModalOpen] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<ProviderItem | null>(null);

  const isNewItem = !selectedProvider;

  const [clinics, setClinics] = useState([]);
  const [avatars, setAvatars] = useState<any[]>([]);
  const [providers, setProviders] = useState<{ data: Provider[]; total: number }>({
    data: [],
    total: 0,
  });
  const [organizations, setOrganizations] = useState<{ id: number; alias: string; name: string }[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const [organizationIdFilter, setOrganizationIdFilter] = useState<number>();
  const [patientFilter, setPatientFilter] = useState<string>('');
  const [activePage, setActivePage] = useState<number>(1);
  const { enableImageUploader } = useContext(GlobalContext);

  useEffect(() => {
    debouncedGetProviders();
  }, [user, filter, organizationIdFilter]);

  useEffect(() => {
    getClinics();
    getOrganizations();
    if (enableImageUploader) {
      getProviderAvatars();
    }
    debouncedGetProviders();
  }, []);

  const getClinics = async () => {
    const { data } = await api.getClinics();
    setClinics(data);
  };

  const getProviderAvatars = async () => {
    const existingAvatars = await api.getProviderAvatars();
    setAvatars(existingAvatars);
  };

  const getProviders = async ({
    offset,
    limit,
    search,
    organizationId,
  }: {
    offset: number;
    limit: number;
    search?: string;
    organizationId?: number;
  }) => {
    try {
      setIsLoading(true);

      const providers = await api.getProviders({
        offset,
        limit,
        onBoarded: true,
        search: search || filter,
        organizationId: organizationId || organizationIdFilter,
        sort: 'desc(updated_at)',
      });
      setProviders({
        total: providers.meta?.total,
        data: [{ ...user, me: true }, ...providers.data],
      });
      setIsLoading(false);
    } catch (err) {
      setProviders({ data: [], total: 0 });
      setIsLoading(false);
    }
  };

  const debouncedGetProviders = debounce(() => {
    getProviders({
      offset: PROVIDERS_OFFSET,
      limit: PROVIDERS_LIMIT,
      search: filter,
      organizationId: organizationIdFilter,
    });
  }, 500);

  const getOrganizations = async () => {
    const { data } = await api.getOrganizations({ limit: 500 });
    setOrganizations(data);
  };

  const openModal = () => {
    setItemModalOpen(true);
  };

  const itemModalToggle = () => {
    setItemModalOpen(!itemModalOpen);
  };

  const handleItemCreate = async (
    provider: CreateProviderRequest,
    options: { fileChecksum?: string },
  ) => {
    await api.createProvider(provider, options);
  };

  const handleUpdateItem = async (
    id: string,
    provider: any,
    options: { fileChecksum?: string },
  ) => {
    await api.updateProvider(id, provider, options);
  };

  const handleDeleteItem = async (id: string) => {
    await api.deleteProvider(id);
  };

  return (
    <div className="providers-page gray-bg">
      <div className="header-content-v2">
        <Search filter={patientFilter} setFilter={setPatientFilter} />
        <UserHeaderInfo name={user.firstName} />
      </div>
      <div className="providers-page__list-header">
        <h1 className="providers-page__list-header__title">Providers</h1>
        <Button
          className="providers-page__new-item-btn"
          buttonType="small"
          onClick={() => setItemModalOpen(true)}
        >
          <i className="icon-plus-fat i-va-fix-2" />
          &nbsp;
          <span>New Provider</span>
        </Button>
      </div>
      <div className="provider-list-content-wrap">
        <ProvidersList
          handleSelect={(item) => {
            setSelectedProvider(item);
            openModal();
          }}
          setOrganizationIdFilter={setOrganizationIdFilter}
          setFilter={setFilter}
          providers={providers}
          organizations={organizations}
          isLoading={isLoading}
          activePage={activePage}
          setActivePage={setActivePage}
          getProviders={getProviders}
          deleteProvider={async (id) => {
            await handleDeleteItem(id);
            await getProviders({
              offset: (activePage - 1) * PROVIDERS_LIMIT,
              limit: PROVIDERS_LIMIT,
            });
          }}
        />
      </div>
      {itemModalOpen && (
        <ItemModal
          newItemTitle="Create Provider"
          isOpen={itemModalOpen}
          onSave={async (provider, options) => {
            await handleItemCreate(provider, options);
            setItemModalOpen(false);
            setSelectedProvider(null);
            await getProviders({
              offset: (activePage - 1) * PROVIDERS_LIMIT,
              limit: PROVIDERS_LIMIT,
            });
          }}
          onClose={() => {
            itemModalToggle();
            setSelectedProvider(null);
          }}
          isAdmin={user.isAdmin}
          clinics={clinics}
          isNewItem={isNewItem}
          onUpdate={async (
            id,
            provider,
            { newAlias, fileChecksum }: { newAlias?: string; fileChecksum?: string },
          ) => {
            await handleUpdateItem(id, provider, { fileChecksum });
            setItemModalOpen(false);
            if (selectedProvider?.me) {
              if (newAlias) {
                setIsLoading(true);
                history.push(`/${newAlias}/providers`);
              }
              await fetchUserData();
              setSelectedProvider(null);
              return;
            }
            setSelectedProvider(null);
            await getProviders({
              offset: (activePage - 1) * PROVIDERS_LIMIT,
              limit: PROVIDERS_LIMIT,
            });
          }}
          onRemove={async (id) => {
            await handleDeleteItem(id);
            setItemModalOpen(false);
            setSelectedProvider(null);
            await getProviders({
              offset: (activePage - 1) * PROVIDERS_LIMIT,
              limit: PROVIDERS_LIMIT,
            });
          }}
          selectedProvider={selectedProvider}
          avatars={avatars}
        />
      )}
    </div>
  );
};

export default WithApiData(Providers);
