import { useCallback } from 'react';
import { useUserData } from '../hooks/useUserData';
import { UserRole } from '../types/user';

export const roleAccess = ({
  allowed,
  userRole,
}: {
  allowed: UserRole[];
  userRole: UserRole | null;
}) => userRole && allowed.includes(userRole);

export const useRoleAccess = () => {
  const user = useUserData();

  return useCallback(
    (allowed: UserRole[]): boolean => !!roleAccess({ allowed, userRole: user.role }),
    [user.role],
  );
};
