import React, { useState } from 'react';
import * as uuid from 'uuid';
import { Button, Col, Row } from 'react-bootstrap';
import { BkmdModal, ModalHeader } from '@getvim/atomic-ui';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { enableSaveApptAsPdfButton } from '../../utils/featureFlagClient';
import Formatter from '../../utils/formatter';
import useApi from '../../hooks/useApi';
import { AppointmentType, MetadataType, TaxonomyType } from '../../types';
import { AssessmentCPT } from './Assessment-cpt';
import { Referrals } from './Referrals';
import { Orders } from './Orders';
import './styles.less';
import { AssessmentICD } from './Assessment-icd';
import Subjective from './Subjective';
import Loader from '../loader';
import Objective from './Objective';
import PatientInstructions from './PatientInstructions';
import DownloadApppointmentButton from '../download-appointment-button';
import { Switch } from '../switch';
import { omit } from 'lodash-es';

const TABS = [
  { title: 'Subjective', id: 1, namespace: 'subjective' },
  { title: 'Objective', id: 2, namespace: 'objective' },
  { title: 'Assessment', id: 3, namespace: 'assessment' },
  { title: 'Plan', id: 4, namespace: 'plans' },
  { title: 'Billing Information', id: 5, namespace: 'billing' },
  { title: 'Patient Instructions', id: 8, namespace: 'patient-instructions' },
  { title: 'Referrals', id: 6, namespace: 'referrals' },
  { title: 'Orders', id: 7, namespace: 'orders' },
];

interface ClinicalNotesModalProps {
  isOpen: boolean;
  modalTitle: string;
  patient: any;
  onClose: () => void;
  onApptLockToggle: (isLocked: boolean) => void;
  selectedAppointment: AppointmentType;
  enableLockUnlockApptFF: boolean;
}

export const ClinicalNotesModal = ({
  isOpen,
  modalTitle,
  patient,
  onClose,
  onApptLockToggle,
  selectedAppointment,
  enableLockUnlockApptFF,
}: ClinicalNotesModalProps) => {
  const { id, npi, startDate, externalId, isLocked, provider } = selectedAppointment;

  const [appointmentMetadata, setAppointmentMetadata] = useState<MetadataType[]>([]);
  const [isApptLocked, setIsApptLocked] = useState(enableLockUnlockApptFF && isLocked);
  const [activeTab, setActiveTab] = useState(TABS[0].id);
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();
  const appointmentDate = Formatter.formatDOB(startDate);

  const [enableSaveApptAsPdfBtn] = useFeatureFlag({
    flagName: enableSaveApptAsPdfButton,
    defaultValue: false,
    team: Team.Interfaces,
  });

  const saveMetadata = async (metadata: MetadataType[]) => {
    setIsLoading(true);
    await Promise.all(
      metadata.map((item) => {
        return api.upsertMetadata(id, item);
      }),
    ).finally(() => setIsLoading(false));
  };

  const changeApptIsLocked = async (isLocked: boolean) => {
    setIsLoading(true);
    setIsApptLocked(isLocked);
    const updatedAppt = {
      ...omit(selectedAppointment, ['metadata', 'assessment']),
      isLocked,
    };
    await api.updateAppointment(updatedAppt);
    await onApptLockToggle(isLocked);
    setIsLoading(false);
  };

  const onSave = async () => {
    if ([TABS[0].id, TABS[1].id, TABS[2].id, TABS[3].id, TABS[5].id].includes(activeTab)) {
      await saveMetadata(appointmentMetadata);
    }
  };

  return (
    <div className="clinical-notes">
      <BkmdModal
        isOpen={isOpen}
        handleClose={onClose}
        autoFocus={false}
        name="clinical-notes-modal"
        className="clinical-notes-modal fixed-header"
      >
        <div
          id={`clinical-notes-modal-${uuid.v1()}`}
          className="clinical-notes-content"
          data-id="clinical-notes"
          data-appt-id={id}
          data-appt-external-id={externalId}
          data-appt-npi={npi}
          data-appt-provider-id={provider.id}
          data-appt-date={startDate}
          data-appt-is-locked={isApptLocked}
        >
          <ModalHeader onClose={onClose} title={modalTitle} />
          <div className="dialog-body">
            <div className="row">
              <div className="col-xs-6 text-center user-section">
                <div className="user-section__logo">
                  <img src={patient.profilePicture} alt="logo" />
                </div>
                <div className="user-section__info">
                  <span className="user-section__name">{`${patient.firstName} ${patient.lastName}`}</span>
                  <div>
                    <span className="user-section__dob">
                      <span className="user-section__dob__title">Date of Birth: </span>
                      <span className="user-section__dob__description">
                        {Formatter.formatDOB(patient.dob)}
                      </span>
                    </span>
                    <span className="user-section__insurer">
                      <span className="user-section__insurer__title">Insurer: </span>
                      <span className="user-section__insurer__description">{patient.insurer}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 text-center appointment-section">
                <div>
                  <span className="appointment-section__appointment-date">
                    <span className="appointment-section__appointment-date__title">
                      Appointment Date:
                    </span>
                    <span className="appointment-section__appointment-date__description">
                      {appointmentDate}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <span className="separate-line" />
            <button
              className="hidden"
              id="unfocus-tab-btn"
              onClick={() => {
                setActiveTab(-1);
              }}
            />
            <div className="body-section">
              <div className="body-section__tabs">
                {TABS.map(({ title, id, namespace }) => {
                  return (
                    <div
                      key={id}
                      onClick={() => {
                        setActiveTab(id);
                      }}
                      className={`body-section__tab body-section__tab--${
                        activeTab === id ? 'active' : null
                      }`}
                      id={namespace}
                    >
                      {title}
                    </div>
                  );
                })}
              </div>
              <div className="body-section__content-wrapper">
                {enableLockUnlockApptFF && (
                  <Switch
                    checked={isApptLocked}
                    disabled={isLoading}
                    label={isApptLocked ? 'Unlock' : 'Lock'}
                    onCheckedChange={async (isLocked) => await changeApptIsLocked(isLocked)}
                  />
                )}
                <div className={`body-section__content${isApptLocked ? ' disabled' : ''}`}>
                  {activeTab === TABS[0].id && (
                    <Subjective
                      selectedAppointment={selectedAppointment}
                      loading={isLoading}
                      onChange={(updatedMetadata) => {
                        setAppointmentMetadata(updatedMetadata);
                      }}
                      isApptLocked={isApptLocked}
                    />
                  )}
                  {activeTab === TABS[1].id && (
                    <Objective
                      selectedAppointment={selectedAppointment}
                      loading={isLoading}
                      onChange={(updatedMetadata) => {
                        setAppointmentMetadata(updatedMetadata);
                      }}
                      isApptLocked={isApptLocked}
                    />
                  )}
                  {activeTab === TABS[2].id && (
                    <AssessmentICD
                      patient={patient}
                      type={TaxonomyType.ICD}
                      loading={isLoading}
                      selectedAppointment={selectedAppointment}
                      onChange={(updatedMetadata) => {
                        setAppointmentMetadata(updatedMetadata);
                      }}
                      isApptLocked={isApptLocked}
                    />
                  )}
                  {activeTab === TABS[3].id && (
                    <AssessmentCPT
                      type={TaxonomyType.CPT}
                      selectedAppointment={selectedAppointment}
                      onChange={(updatedMetadata) => {
                        setAppointmentMetadata(updatedMetadata);
                      }}
                      isApptLocked={isApptLocked}
                    />
                  )}
                  {activeTab === TABS[4].id && (
                    <AssessmentCPT
                      type={TaxonomyType.CPT}
                      selectedAppointment={selectedAppointment}
                      isApptLocked={isApptLocked}
                    />
                  )}
                  {activeTab === TABS[5].id && (
                    <PatientInstructions
                      loading={isLoading}
                      onChange={(updatedMetadata) => {
                        setAppointmentMetadata(updatedMetadata);
                      }}
                      selectedAppointment={selectedAppointment}
                      isApptLocked={isApptLocked}
                    />
                  )}
                  {activeTab === TABS[6].id && (
                    <Referrals
                      patient={patient}
                      appointmentId={id}
                      api={api}
                      isApptLocked={isApptLocked}
                    />
                  )}
                  {activeTab === TABS[7].id && (
                    <Orders
                      patient={patient}
                      appointmentId={id}
                      api={api}
                      isApptLocked={isApptLocked}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <div className="footer-btns">
              <div>
                <Button
                  disabled={isLoading}
                  bsPrefix="btn btn-secondary"
                  className="cancel-btn"
                  onClick={onClose}
                >
                  Close
                </Button>
                <Button
                  style={{ position: 'relative' }}
                  disabled={isLoading || isApptLocked}
                  bsPrefix="btn"
                  className="btn btn-primary"
                  onClick={onSave}
                >
                  {isLoading ? <Loader /> : <>Save</>}
                </Button>
              </div>
              {enableSaveApptAsPdfBtn && (
                <DownloadApppointmentButton
                  appointmentId={id}
                  patientId={patient.id}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        </div>
      </BkmdModal>
    </div>
  );
};
