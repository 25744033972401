export default (value: any, schema: any) => {
  try {
    const result = schema.validateSync(value);
    if (!result?.error) {
      return false;
    }
  } catch (err: any) {
    return err?.message || err;
  }
};
