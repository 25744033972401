import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import * as uuid from 'uuid';
import Search from '../../components-v2/search';
import UserHeaderInfo from '../../components-v2/user-header-info';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import CalendarCheckIcon from '../../assets/icons/calendar-check.svg';
import ScrollIcon from '../../assets/icons/scroll.svg';
import GraphIcon from '../../assets/icons/graph.svg';
import AirplaneIcon from '../../assets/icons/airplane.svg';

import './styles.less';

const EVENTS_LIST = [
  {
    start: {
      dateTime: '2024-12-11T11:22:48.000',
    },
    name: 'John Doe',
    id: uuid.v1(),
  },
  {
    start: {
      dateTime: '2024-07-07T11:22:48.000',
    },
    name: 'Jake Smith',
    id: uuid.v1(),
  },
  {
    start: {
      dateTime: '2024-08-08T11:22:48.000',
    },
    name: 'Emily Brooks',
    id: uuid.v1(),
  },
  {
    start: {
      dateTime: '2024-09-09T11:22:48.000',
    },
    name: 'Michael Harris',
    id: uuid.v1(),
  },
];

const Dashboard = () => {
  const user = useUserData();
  return (
    <div className="dashboard v2 gray-bg">
      <div className="header-content-v2">
        <Search />
        <UserHeaderInfo name={user.firstName} />
      </div>
      <h1 className="dashboard-title">Welcome Dr. {user.firstName}!</h1>
      <div className="dashboard-content dashboard-top-content">
        <div className="specification-column">
          <ul>
            <li className="list-header">
              <img src={CalendarCheckIcon} alt="" />
              Appointments
            </li>
            {EVENTS_LIST.map((event) => (
              <li className="item-with-counter" key={event.id}>
                {moment(event.start.dateTime).format('MMM DD, YYYY')}
                <div className="item-value">{event.name}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="specification-column">
          <ul>
            <li className="list-header">
              <img src={ScrollIcon} alt="" />
              Medical Billing
            </li>
            <li>
              <div className="item-with-counter">
                Claims Received <div className="item-value">15</div>
              </div>
            </li>
            <li>
              <div className="item-with-counter">
                Claims in Progress <div className="item-value">3</div>
              </div>
            </li>
            <li>
              <div className="item-with-counter">
                Claims Requiring Action <div className="item-value">-</div>
              </div>
            </li>
          </ul>
        </div>
        <div className="specification-column">
          <ul>
            <li className="list-header">
              <img src={GraphIcon} alt="" />
              Usage
            </li>
            <li>
              <div className="item-with-counter">
                Faxes <div className="item-value">100/150</div>
              </div>
            </li>
            <li>
              <div className="item-with-counter">
                Statements Malled <div className="item-value">35</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="dashboard-content dashboard-bottom-content">
        <div className="specification-column">
          <ul className="messages">
            <li className="list-header">
              <img src={AirplaneIcon} alt="" />
              Messages
            </li>
            <li className="item-with-counter">
              <div>Prior Authorization</div>
              <div className="item-value">
                Prior Authorization alert: A new prior authorization has been approved for patient
                John Doe. Check details for next steps.
              </div>
            </li>
            <li className="item-with-counter">
              <div>Quest Diagnostics</div>
              <div className="item-value">
                Lab results available: Lab results for Emily Brooks are now available. Click to view
                and discuss with the patient.
              </div>
            </li>
            <li className="item-with-counter gray">
              <div>Sarah Collins</div>
              <div className="item-value">
                New fax received: A new document has arrived via fax from Sarah Collins. Review it
                in your inbox.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WithApiData(Dashboard);
