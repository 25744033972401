import React, { createContext, PropsWithChildren, useState, useContext, useEffect } from 'react';
import Loader from '../components/loader';
import config, { ConfigI } from './index';

const clientConfigContext = createContext<Partial<ConfigI>>({});

export function ClientConfigProvider({ children }: PropsWithChildren<any>) {
  const [clientConfig, setClientConfig] = useState<ConfigI>();

  useEffect(() => {
    setClientConfig(config);
  }, [config]);

  if (!clientConfig) {
    return <Loader />;
  }
  return (
    <clientConfigContext.Provider value={clientConfig}>{children}</clientConfigContext.Provider>
  );
}

export default function useClientConfig() {
  return useContext(clientConfigContext);
}
