import React, { useState, useEffect, createRef, useRef } from 'react';
import { Button, Table } from 'react-bootstrap';
import { EllipsisText, Toast } from '@getvim/atomic-ui';
import moment from 'moment';
import debounce from 'debounce';
import { Box, TextareaAutosize } from '@mui/material';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import {
  AppointmentType,
  TaxonomyType,
  AssessmentType,
  Patient,
  PatientProblem,
  PatientProblemStatus,
  Taxonomy,
  MetadataType,
} from '../../types';
import useApi from '../../hooks/useApi';
import { CleanInput } from '../clean-input';
import { SelectTypeahead, DescriptionPosition } from '../select-typeahead';
import Formatter from '../../utils/formatter';

import './styles.less';

const ASSESSMENT_NOTES_METADATA_KEY = 'assessment_notes';

const { ToastTypes, createToast } = Toast;

interface AssessmentICDProps {
  patient?: Patient;
  selectedAppointment: AppointmentType;
  type: TaxonomyType;
  isApptLocked: boolean;
  handleLoading: (value: boolean) => void;
  timeUpdateForWbTriggered: undefined | Date;
}

const AssessmentICD = ({
  patient,
  selectedAppointment,
  type,
  isApptLocked,
  handleLoading,
  timeUpdateForWbTriggered,
}: AssessmentICDProps) => {
  const [selected, setSelected] = useState<Taxonomy[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [generalNotes, setGeneralNotes] = useState<string>();

  const [icdCodes, setIcdCodes] = useState<Taxonomy[]>([]);
  const [assessments, setAssessments] = useState<AssessmentType[]>([]);

  const [activePatientProblemList, setActivePatientProblemList] = useState<PatientProblem[]>([]);
  const [pastPatientProblemList, setPastPatientProblemList] = useState<PatientProblem[]>([]);

  const generalNotesTimeout = useRef(null);

  const api = useApi();

  const notesInputElement = createRef<HTMLInputElement>();

  const assessmentsToDisplay = assessments.map((assessment) => {
    return {
      ...assessment,
      isExistsInActiveProblemList: !!activePatientProblemList.find((problem) =>
        problem.notes.find((note) => note.id === assessment.note?.id),
      ),
    };
  });

  const activeProblemListToDisplay = activePatientProblemList.map((problem) => {
    return {
      ...problem,
      isAvailableToAddToAppt: !assessments.find(
        (assessment) => assessment.taxonomy.id === problem.taxonomy.id,
      ),
    };
  });

  useEffect(() => {
    getPatientProblemList();
    getGeneralNotes();
  }, []);

  useEffect(() => {
    getPatientProblemList();
    getGeneralNotes();
  }, [timeUpdateForWbTriggered]);

  const getPatientProblemList = async () => {
    if (patient) {
      try {
        handleLoading(true);
        const data = await api.getPatientProblems(patient?.id, {});
        const resolvedProblemList: PatientProblem[] = [];
        const activeProblemsList = data.reduce(
          (previousValue: PatientProblem[], currentValue: PatientProblem) => {
            if (currentValue.status !== PatientProblemStatus.Resolved) {
              return [...previousValue, currentValue];
            }
            resolvedProblemList.push(currentValue);

            return previousValue;
          },
          [],
        );
        setActivePatientProblemList([...activeProblemsList]);
        setPastPatientProblemList([...resolvedProblemList]);
      } catch (err) {
      } finally {
        handleLoading(false);
      }
    }
  };

  useEffect(() => {
    getAssessments();
    getGeneralNotes();
  }, [selectedAppointment]);

  useEffect(() => {
    getTaxonomiesList();
  }, [assessments]);

  const getAssessments = async () => {
    if (selectedAppointment) {
      const filteredAssessments = selectedAppointment.assessment.reduce(
        (filtered: AssessmentType[], option: AssessmentType) => {
          if (option.taxonomy?.type === type) {
            filtered.push(option);
          }
          return filtered;
        },
        [],
      );
      setAssessments(filteredAssessments);
    }
  };

  const parseGeneralNotesMetadata = (metadata: MetadataType[]) => {
    return metadata?.find((item) => item.key === ASSESSMENT_NOTES_METADATA_KEY)?.value;
  };

  const getGeneralNotes = () => {
    const generalNotes = parseGeneralNotesMetadata(selectedAppointment.metadata);
    setGeneralNotes(generalNotes);
  };

  const getTaxonomiesList = async (search?: string) => {
    try {
      const { data } = await api.getTaxonomiesList({ type, search });
      const filteredData = data.filter(
        (taxonomy: { id: number }) =>
          !assessments.find((assessment) => assessment.taxonomy.id === taxonomy.id),
      );

      setIcdCodes(filteredData);
    } catch (e) {}
  };

  const deleteIcdCode = async (item: AssessmentType) => {
    handleLoading(true);
    try {
      await api.removeAssessmentFromAppointment({
        noteId: item.note.id,
        appointmentId: selectedAppointment.id,
      });
      icdCodes.push(item.taxonomy);
      setIcdCodes([...icdCodes]);
      const updatedAssessments = assessments.filter(({ taxonomy }) => {
        return taxonomy.id !== item.taxonomy.id;
      });
      setAssessments(updatedAssessments);
      await getPatientProblemList();
    } finally {
      handleLoading(false);
    }
  };

  const addICDCodes = async ({ taxonomy }: { taxonomy: Taxonomy }) => {
    handleLoading(true);
    try {
      const itemToAdd = { taxonomy, note: { notes, id: -1 } };
      const { noteId } = await api.addAssessmentToAppointment({
        appointmentId: selectedAppointment.id,
        taxonomyId: itemToAdd.taxonomy.id,
        notes: itemToAdd.note.notes?.trim(),
      });
      itemToAdd.note.id = noteId;
      const icdCodesList = icdCodes.filter((item) => item.id !== taxonomy.id);
      setIcdCodes([...icdCodesList]);
      setAssessments([itemToAdd, ...assessments]);
      setSelected([]);
      setNotes('');
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      handleLoading(false);
    }
  };

  const addActiveProblemToAssessments = async (item: PatientProblem) => {
    setSelected([icdCodes.find((code) => code.id === item.taxonomy.id)!]);
    notesInputElement.current?.focus();
  };

  const addICDCodeToActivePatientProblemList = async (assessment: AssessmentType, event) => {
    const changedCheckTo = event.target.checked;

    if (patient) {
      handleLoading(true);
      if (changedCheckTo) {
        await api.createPatientProblem(patient.id, {
          ...(assessment.note?.id
            ? {
                noteId: assessment.note?.id,
              }
            : {
                taxonomyId: assessment.taxonomy.id,
                notes: assessment.note?.notes,
              }),
        });
      } else {
        const { id: problemId } =
          activePatientProblemList.find((item) => item.taxonomy.id === assessment.taxonomy.id) ||
          {};
        if (problemId) {
          await api.deletePatientProblem({
            patientId: patient.id,
            id: problemId,
            noteId: assessment.note.id,
          });
        }
      }
      getPatientProblemList();
      handleLoading(false);
    }
  };

  const triggerCollapsedRow = (item, stateFunc) => {
    const openStatus = !item.isOpen;
    stateFunc((prevState: any) => {
      const foundIndex = prevState.findIndex((x) => x.id === item.id);
      // eslint-disable-next-line no-param-reassign
      prevState[foundIndex].isOpen = openStatus;
      return [...prevState];
    });
  };

  const handleGeneralNotesChange = (event) => {
    const value = event.target?.value;
    setGeneralNotes(value);

    //@ts-ignore
    clearTimeout(generalNotesTimeout.current);
    //@ts-ignore
    generalNotesTimeout.current = setTimeout(() => {
      api.upsertMetadata(selectedAppointment.id, { key: ASSESSMENT_NOTES_METADATA_KEY, value });
    }, 3000);
  };

  let temp = true;

  return (
    <>
      <span className="main-section-title">Assessments</span>
      <div className="assessment-v2">
        <div className="block-left-v2">
          <div className="assessment-v2__select-wrapper">
            <SelectTypeahead
              description="* ICD"
              placeholder="Search codes"
              className="select-container-v2"
              labelKey="label"
              disableFilter
              filterBy={['label', 'description']}
              clearButton
              options={icdCodes}
              selected={selected}
              onInputChange={debounce((searchTerm: string) => getTaxonomiesList(searchTerm), 300)}
              onChange={(value) => {
                setSelected(value);
              }}
              descriptionPosition={DescriptionPosition.TOP}
              disabled={isApptLocked}
              // @ts-ignore
              renderMenuItemChildren={(label, option: Taxonomy) => (
                <div data-code={option.label}>
                  {option.label}
                  <div className="small-description" data-code={option.description}>
                    <small>{Formatter.toSentenceCase(option.description)}</small>
                  </div>
                </div>
              )}
            />

            <CleanInput
              disabled={isApptLocked}
              ref={notesInputElement}
              className="clean-input-container-v2"
              labelKey="notes"
              value={notes}
              placeholder="Add notes"
              description="Notes"
              onChange={(e: { target: { value: any } }) => {
                setNotes(e.target.value?.replace(/^\s*/, ''));
              }}
            />

            <Button
              disabled={!selected.length || isApptLocked}
              id="add-icd-btn"
              bsPrefix="btn btn-primary-v2"
              className="assessment-v2__add-icd-btn"
              onClick={() => {
                addICDCodes({ taxonomy: selected[0] });
              }}
            >
              <i className="icon-plus-fat i-va-fix-2" />
              &nbsp;
              <span>Add</span>
            </Button>
          </div>

          <div>
            <Box
              className="text-area-wrapper-v2 no-padding"
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <div className="title">
                <span>General notes</span>
              </div>
              <Box>
                <TextareaAutosize
                  data-test-id="assessment-general-notes"
                  data-type-id="assessment-general-notes"
                  style={{ width: '100%', resize: 'none' }}
                  maxRows={2}
                  minRows={2}
                  value={generalNotes}
                  disabled={isApptLocked}
                  onChange={handleGeneralNotesChange}
                />
              </Box>
            </Box>
          </div>

          <div className="table-wrapper-v2">
            <Table borderless hover>
              <thead>
                <tr>
                  <th className="label-column">ICD</th>
                  <th className="description-column">Description</th>
                  <th>Notes</th>
                  <th className="add-to-pl-column">Add to PL</th>
                  <th className="drop-icon-column" />
                </tr>
              </thead>
              <tbody>
                {assessmentsToDisplay.map((item: any) => {
                  return (
                    <>
                      <tr key={item.taxonomy?.id} className="main-row-v2"  data-code={item.taxonomy?.label}>
                        <td className="label-column">{item.taxonomy?.label}</td>
                        <td
                          className="description-column"
                          title={Formatter.toSentenceCase(item.taxonomy?.description)}
                        >
                          {Formatter.toSentenceCase(item.taxonomy?.description)}
                        </td>
                        <td className="" title={item.note?.notes}>
                          {item.note?.notes || '-'}
                        </td>
                        <td className="add-to-pl-checkbox">
                          <input
                            name={`patient-icd-checkbox-${item.taxonomy?.id}`}
                            id={`patient-icd-checkbox-${item.taxonomy?.id}`}
                            type="checkbox"
                            checked={item.isExistsInActiveProblemList}
                            onChange={(value: any) => {
                              if (isApptLocked) {
                                return;
                              }
                              addICDCodeToActivePatientProblemList(item, value);
                            }}
                          />
                          <label
                            className={`${isApptLocked ? 'disabled' : ''}`}
                            htmlFor={`patient-icd-checkbox-${item.taxonomy?.id}`}
                            id={`patient-icd-${item.taxonomy?.id}`}
                          >
                            {' '}
                          </label>
                        </td>
                        <td>
                          <IconTrash
                            className={`trash-icon${isApptLocked ? ' disabled' : ''}`}
                            onClick={() => !isApptLocked && deleteIcdCode(item)}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>

        <div className="vertical-line" />

        <div className="block-right-v2">
          <PatientProblemList
            title="Active problem list"
            problemsList={activeProblemListToDisplay}
            onExpandListItem={(item) => triggerCollapsedRow(item, setActivePatientProblemList)}
          />
          <PatientProblemList
            title="Past medical history"
            problemsList={pastPatientProblemList}
            onExpandListItem={(item) => triggerCollapsedRow(item, setPastPatientProblemList)}
          />
        </div>
      </div>
    </>
  );
};

const PatientProblemList = ({ title, problemsList, onExpandListItem }) => {
  return (
    <>
      <span className="table-title">{title}</span>

      <div className="table-wrapper-v2">
        <Table borderless>
          <thead>
            <tr>
              <th className="label-column">ICD</th>
              <th className="description-column">Description</th>
              <th className="date-column"> </th>
              <th className=""> </th>
            </tr>
          </thead>
          <tbody>
            {problemsList.map((item: any) => {
              const noBottomRadiusClass = item.isOpen ? 'no-bottom-radius' : '';

              return (
                <>
                  <tr className="spacing-row" />
                  <tr key={item.id} className="main-row-v2">
                    <td className={`assessment-icd ${noBottomRadiusClass}`}>
                      {item.taxonomy?.label}
                    </td>
                    <td title={Formatter.toSentenceCase(item.taxonomy?.description)}>
                      {Formatter.toSentenceCase(item.taxonomy?.description)}
                    </td>
                    <td />
                    <td />
                    <td className={noBottomRadiusClass}>
                      <div
                        className={`collapse-row-arrow ${item.isOpen ? 'is-opened' : ''}`}
                        onClick={() => onExpandListItem(item)}
                      ></div>
                    </td>
                  </tr>

                  {item.isOpen
                    ? item.notes.map((note: any, index) => {
                        const providerName = `${note.provider.firstName} ${note.provider.lastName}`;

                        const isLastRow = index + 1 == item.notes.length;
                        const lastRowClass = isLastRow ? 'last-expanded-row' : '';

                        return (
                          <>
                            <tr key={note.id} className="expanded-row">
                              <td className={`bordered ${lastRowClass}`} title={providerName}>
                                <EllipsisText
                                  tooltipContent={providerName}
                                  text={providerName}
                                  width="small"
                                  bgColor="darkBlue"
                                />
                              </td>
                              <td className="bordered" title={note.notes}>
                                <EllipsisText
                                  tooltipContent={note.notes}
                                  text={note.notes}
                                  width="small"
                                  bgColor="darkBlue"
                                />
                              </td>
                              <td
                                className="bordered"
                                colSpan={2}
                                title={moment(note.createdAt).format('DD/MM/YYYY')}
                              >
                                {moment(note.createdAt).format('DD/MM/YYYY')}
                              </td>
                              <td className={`bordered ${lastRowClass}`} />
                            </tr>
                          </>
                        );
                      })
                    : null}
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default AssessmentICD;
