import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';
import Loader from '../loader';
import { Toast } from '@getvim/atomic-ui';
import { isString } from 'lodash-es';

type Props = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  onChange: (metadata: MetadataType[]) => void;
  isApptLocked: boolean;
};

const GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY = 'patient-instructions_general_notes';

const { ToastTypes, createToast } = Toast;

const PatientInstructions: React.FC<Props> = ({
  selectedAppointment,
  loading,
  onChange,
  isApptLocked,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState({});
  const [generalNotes, setGeneralNotes] = useState<undefined | string>();

  const api = useApi();

  const handleGeneralNotesChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setGeneralNotes(event.target.value);
  };

  const parseAppointmentMetaData = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      setInitialData((current) => {
        return {
          ...current,
          [key]: value,
        };
      });
    });
  };
  useEffect(() => {
    const data: MetadataType[] = [];
    if (isString(generalNotes)) {
      data.push({ key: GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY, value: generalNotes });
    }
    onChange(data);
  }, [generalNotes]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    getAppointmentMedataData();
  }, [selectedAppointment]);

  const getAppointmentMedataData = async () => {
    if (selectedAppointment) {
      try {
        setLoading(true);
        const appointment = await api.getAppointment({ id: selectedAppointment.id });
        parseAppointmentMetaData(appointment.metadata);
      } catch (err: any) {
        createToast({
          title: `Oops, error!`,
          message: err.error?.message[0],
          type: ToastTypes.ERROR,
          html: true,
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box className="text-area-wrapper" display="flex" flexDirection="column" flexGrow={1}>
      {isLoading && <Loader />}
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={5}
          value={generalNotes ?? initialData[GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY]}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleGeneralNotesChanges}
        />
      </Box>
    </Box>
  );
};

export default PatientInstructions;
