import React, { useEffect, useState } from 'react';
import { Toast } from '@getvim/atomic-ui';

import { isNil, isEmpty } from 'lodash-es';
import { Loader } from '@getvim/atomic-ui';
import useApi from '../../hooks/useApi';
import { List } from './list';
import { IListItem, IPatientProps } from './types';
import { ListType } from '../../types';

import './styles.less';

interface ReferralsProps {
  patient: IPatientProps;
}

const { ToastTypes, createToast } = Toast;

export const ReferralsOrders = ({ patient }: ReferralsProps) => {
  const [referrals, setReferrals] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);

  const api = useApi();

  useEffect(() => {
    getReferrals();
    getOrders();
  }, []);

  const errorToastWrapper = async (cb, title = '') => {
    try {
      await cb();
    } catch (err: any) {
      createToast({
        title: `Oops, error! ${title}`,
        message: err.statusCode != '400' ? 'Something went wrong' : err.error?.message?.[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });

      throw err;
    }
  };

  const getReferrals = async () => {
    const referrals = await api.getReferrals({ patientId: patient.id });
    setReferrals(referrals.data);
  };

  const getOrders = async () => {
    const orders = await api.getOrders({ patientId: patient.id });
    setOrders(orders.data);
  };

  const prepareReferToForReferral = (referTo: IListItem['referTo']): string => {
    return !isEmpty(referTo) ? `${referTo?.firstName} ${referTo?.lastName}` : '-';
  };

  const prepareReferToForOrder = (referTo: IListItem['referTo']): string => {
    return `${referTo?.location}`;
  };

  const handleReferralCreate = async (referral: any) => {
    const cb = async () => {
      await api.createReferral(referral);
      getReferrals();
    };
    await errorToastWrapper(cb);
  };

  const handleOrderCreate = async (order: any) => {
    const cb = async () => {
      await api.createOrder(order);
      getOrders();
    };
    await errorToastWrapper(cb);
  };

  const handleReferralUpdate = async (referralId: number, referral: any) => {
    const cb = async () => {
      await api.updateReferral(referralId, referral);
      getReferrals();
    };
    await errorToastWrapper(cb);
  };

  const handleOrderUpdate = async (orderId: number, order: any) => {
    const cb = async () => {
      await api.updateOrder(orderId, order);
      getOrders();
    };
    await errorToastWrapper(cb);
  };

  const handleReferralDelete = async (referralId: number): Promise<void> => {
    const response = await api.deleteReferral(referralId);
    if (!response?.id) {
      // TODO: handle error on deleting
    }
    getReferrals();
  };

  const handleOrderDelete = async (orderId: number): Promise<void> => {
    const response = await api.deleteOrder(orderId);
    // TODO: handle error on deleting
    getOrders();
  };

  return (
    <>
      {(isNil(referrals) || isNil(orders)) && <Loader type="dots" size="small" label="Loading" />}

      {!isNil(referrals) && !isNil(orders) && (
        <div className="referrals-orders-v2">
          <List
            patient={patient}
            items={referrals}
            listType={ListType.referrals}
            newItemTitle="Add new referral"
            modalTitle="referral"
            prepareReferToText={prepareReferToForReferral}
            handleDeleteItem={handleReferralDelete}
            handleCreateItem={handleReferralCreate}
            handleUpdateItem={handleReferralUpdate}
          />
          <List
            patient={patient}
            items={orders}
            listType={ListType.orders}
            newItemTitle="Add new order"
            modalTitle="order"
            prepareReferToText={prepareReferToForOrder}
            handleDeleteItem={handleOrderDelete}
            handleCreateItem={handleOrderCreate}
            handleUpdateItem={handleOrderUpdate}
          />
        </div>
      )}
    </>
  );
};
