export enum TaxonomyType {
  SPECIALTY = 'specialty',
  ICD = 'icd',
  CPT = 'cpt',
  PRIORITY = 'priority',
  VISIT_TYPE = 'visit_type',
}

export interface Taxonomy {
  id: number;
  type: TaxonomyType;
  label: string;
  description: string;
}
