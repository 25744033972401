import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-form';
import * as uuid from 'uuid';
import { BkmdModal, ModalHeader, Toast } from '@getvim/atomic-ui';
import { isArray, isEmpty, omit } from 'lodash-es';
import debounce from 'debounce';
import { ReactComponent as InfoIcon } from '../../assets/icons/information-outline.svg';

import { fields } from './itemModalDef';
import Field from '../form-field';
import Formatter from '../../utils/formatter';
import Loader from '../loader';
import { ImageUploader } from '../profile-photo-upload/imageUploader';
import { PROVIDER_ROLES, TaxonomyType } from '../../types';
import './styles.less';
import { encryptData, encryptFileBuffer } from '../../utils/crypto';
import { GlobalContext } from '../../contexts/global';
import useApi from '../../hooks/useApi';

interface NewItemModalProps {
  isOpen: boolean;
  newItemTitle: string;
  onSave: (provider: any, { fileChecksum }: { fileChecksum?: string }) => void;
  onClose: () => void;
  isAdmin?: boolean;
  clinics: any;
  onUpdate: (
    id: string,
    body: any,
    options: { newAlias?: string; fileChecksum?: string },
  ) => Promise<void>;
  onRemove: (id: string) => void;
  isNewItem: boolean;
  selectedProvider?: any | null;
  avatars: string[];
}

interface ITaxonomy {
  id: number;
  label: string;
  description: string;
}

const { ToastTypes, createToast } = Toast;

export const ItemModal = ({
  isOpen,
  newItemTitle,
  onSave,
  onClose,
  clinics,
  isNewItem,
  onUpdate,
  selectedProvider,
  avatars,
}: NewItemModalProps) => {
  const initialData = {
    firstName: undefined,
    middleName: '',
    lastName: undefined,
    email: undefined,
    npi: undefined,
    specialty: '',
    degree: '',
    fax: '',
    phoneNumber: '',
    onBoarded: true,
    suffix: 'MD',
    gender: 'Male',
    role: PROVIDER_ROLES.PROVIDER.value,
    organizationId: undefined,
    visitTypeId: undefined,
    password: undefined,
    profilePicture: null,
  };

  const [formData, setFormData] = useState<any>(selectedProvider ?? initialData);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [updatedAlias, setUpdatedAlias] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [currentProfilePicture, setCurrentProfilePicture] = useState({
    file: undefined,
    imageURL: formData.profilePicture,
  });
  const [taxonomies, setTaxonomies] = useState<{
    specialty: ITaxonomy[];
    visit_type: ITaxonomy[];
  }>({
    specialty: [],
    visit_type: [],
  });
  const api = useApi();

  useEffect(() => {
    getOrganizations();
    getTaxonomiesList(TaxonomyType.SPECIALTY);
    getTaxonomiesList(TaxonomyType.VISIT_TYPE);
  }, []);

  const { enableImageUploader } = useContext(GlobalContext);

  const defaultValues = useMemo(
    () => ({
      ...formData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formProps = useForm({
    defaultValues,
    onSubmit: async () => {
      handleApplyClick();
    },
  });

  const {
    Form,
    meta: { canSubmit, isSubmitting },
  } = formProps;

  const getOrganizations = async (searchTerm?: string) => {
    const { data } = await api.getOrganizations({ search: searchTerm });
    setOrganizations(data);
  };

  const getTaxonomiesList = async (type: TaxonomyType) => {
    const { data } = await api.getTaxonomiesList({ type });
    setTaxonomies((prevState) => {
      return { ...prevState, [type]: data };
    });
  };

  // Update Form Field
  const updateFormData = ({ field, value }: { field: string; value: any }) => {
    setFormData((prevState: any) => {
      return {
        ...prevState,
        [`${field}`]: value,
      };
    });
  };

  // Reset Form
  const resetFormData = () => {
    setFormData({
      ...initialData,
    });
  };

  // OnClose Click Handler
  const handleClose = () => {
    resetFormData();
    onClose();
  };

  const loaderWrapper = async (cb) => {
    setLoading(true);
    try {
      await cb;
      resetFormData();
    } catch (err: any) {
      const error = err.statusCode != '400' ? 'Something went wrong' : undefined;
      createToast({
        title: `Oops, error!`,
        message:
          error ?? (isArray(err.error?.message) ? err.error?.message?.[0] : err.error?.message),
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (
    id: string,
    body: any,
    { fileChecksum }: { fileChecksum?: string },
  ) => {
    const newAlias =
      updatedAlias && updatedAlias !== selectedProvider.organization.alias
        ? updatedAlias
        : undefined;
    return loaderWrapper(onUpdate(id, body, { newAlias, fileChecksum }));
  };

  // Handle Save Click (Create, Update)
  const handleApplyClick = async () => {
    const adminFields = ['emailVerified', 'isAdmin', 'loaded', 'error', 'me', 'notFound'];
    const providerToSave = new FormData();
    let obj = {};
    if (!isNewItem) {
      obj = {
        ...omit(
          {
            ...formData,
            organizationId: formData.organizationId || formData.organization.id,
            visitTypeId: formData.visitType?.id || null,
          },
          [
            'organization',
            'id',
            'fullName',
            'npi',
            'clinics',
            'profilePicture',
            'phoneNumber',
            'fax',
            'visitType',
            ...adminFields,
          ],
        ),
      };
    } else {
      const decryptedPassword = await encryptData({ data: formData.password });
      obj = {
        ...omit(
          {
            ...formData,
            password: decryptedPassword,
          },
          ['profilePicture', 'phoneNumber', 'visitTypeId'],
        ),
      };
      providerToSave.append('clinics', JSON.stringify([{ id: clinics[0].id }]));
    }
    /** properties to formData */
    for (const [key, value] of Object.entries(obj)) {
      // @ts-ignore
      providerToSave.append(key, value);
    }
    /** phoneNumber to formData */
    providerToSave.append(
      'phoneNumber',
      JSON.stringify(
        Formatter.formatPhoneNumber(formData.phoneNumber.number || formData.phoneNumber),
      ),
    );
    providerToSave.append(
      'fax',
      JSON.stringify(Formatter.formatPhoneNumber(formData.fax.number || formData.fax)),
    );
    formData?.visitTypeId &&
      providerToSave.append('visitTypeId', JSON.stringify(formData.visitTypeId));
    let fileChecksum;
    if (currentProfilePicture.file) {
      const digestBuffer = await encryptFileBuffer(currentProfilePicture.file);
      providerToSave.append('file', currentProfilePicture.file);
      fileChecksum = digestBuffer.toString();
    } else {
      providerToSave.append('profilePicture', formData.profilePicture);
    }

    isNewItem
      ? loaderWrapper(onSave(providerToSave, { fileChecksum }))
      : handleUpdate(selectedProvider?.id!, providerToSave, { fileChecksum });
  };

  const formatAlphanumericInput = (newValue: string) => {
    return newValue
      ?.replace(/[±§!@#$%^&*()_+=,<>.?|/\\";:[\]{}~]/g, '')
      ?.replace(/^\s*/, '')
      ?.replace(/\s{2,}/, ' '); // allow only one space between words
  };

  return (
    <div>
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleClose}
        autoFocus={false}
        name="item-modal"
        className="item-modal-wrapper"
      >
        <div
          id={`item-modal ${uuid.v1()}`}
          className="dialog-wrapper"
          data-id="item-modal"
          data-item-data={JSON.stringify(formData)}
        >
          <Form method="post" className="item-modal-form">
            <ModalHeader onClose={handleClose}>
              <div className="header-title">
                <p>
                  {isNewItem
                    ? newItemTitle
                    : `Edit Provider: ${selectedProvider.fullName}${
                        selectedProvider?.me ? ' (me)' : ''
                      }`}
                </p>
              </div>
            </ModalHeader>
            <div className="dialog-body">
              {isNewItem && (
                <div className="header-hint">
                  <InfoIcon className="icon" />
                  <span className="content">
                    This provider will be added as a new user to the generic Vim EHR
                  </span>
                </div>
              )}
              {isLoading && <Loader />}

              {enableImageUploader && (
                <ImageUploader
                  formData={formData}
                  updateFormData={updateFormData}
                  currentProfilePicture={currentProfilePicture}
                  setCurrentProfilePicture={setCurrentProfilePicture}
                  avatars={avatars}
                />
              )}
              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.firstName}
                defaultValue={selectedProvider?.firstName || formData.firstName}
                labelKey="firstName"
                description="* First name"
                value={formData.firstName}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'firstName',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />
              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.middleName}
                defaultValue={selectedProvider?.middleName || formData.middleName}
                labelKey="middleName"
                description="Middle name"
                value={formData.middleName}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'middleName',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />
              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.lastName}
                defaultValue={selectedProvider?.lastName || formData.lastName}
                labelKey="lastName"
                description="* Last name"
                value={formData.lastName}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'lastName',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />
              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.email}
                defaultValue={selectedProvider?.email || formData.email}
                labelKey="email"
                showError="true"
                description="* Email"
                value={formData.email}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'email',
                    value: e.target.value?.replace(/^\s*/, ''),
                  });
                }}
              />
              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.npi}
                defaultValue={selectedProvider?.npi || formData.npi}
                labelKey="npi"
                description="* NPI"
                showError="true"
                value={formData.npi}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'npi',
                    value: e.target.value?.replace(/[^\d]*/g, ''),
                  });
                }}
              />
              <Field
                submitted={submitted}
                description="Speciality"
                className="select-container"
                labelKey="specialty"
                filterBy={['specialty']}
                options={taxonomies.specialty.map(({ label }) => {
                  return { specialty: label };
                })}
                onChange={(value: { specialty: any }[]) => {
                  updateFormData({
                    field: 'specialty',
                    value: value[0]?.specialty || '',
                  });
                }}
                renderMenuItemChildren={(specialty: React.ReactNode) => {
                  return <div>{specialty}</div>;
                }}
                // @ts-ignore
                defaultSelected={[{ specialty: formData?.specialty ?? initialData?.specialty }]}
                selected={isEmpty(formData?.specialty) ? [] : [formData?.specialty]}
                clearButton
                {...fields.specialty}
              />
              <Field
                inputType
                className="clean-input-container"
                submitted={submitted}
                {...fields.degree}
                defaultValue={selectedProvider?.degree || formData.degree}
                labelKey="degree"
                description="Degree"
                value={formData.degree}
                onChange={(e: { target: { value: any } }) => {
                  updateFormData({
                    field: 'degree',
                    value: formatAlphanumericInput(e.target.value),
                  });
                }}
              />
              {/* For now need hide Visit type */}
              {/* <Field
                submitted={submitted}
                description="Visit Type"
                className="select-container"
                labelKey="label"
                filterBy={['label']}
                options={taxonomies.visit_type}
                onChange={(value: { id: any; label: any }[]) => {
                  updateFormData({
                    field: 'visitType',
                    value: value[0],
                  });
                }}
                renderMenuItemChildren={(visitType: React.ReactNode) => {
                  return <div>{visitType}</div>;
                }}
                defaultSelected={[{ visitType: formData?.visitType?.label ?? undefined }]}
                selected={isEmpty(formData?.visitType) ? [] : [formData?.visitType]}
                clearButton
                {...fields.visitType}
              /> */}
              <Field
                inputType="tel"
                className="clean-input-container"
                submitted={submitted}
                {...fields.phoneNumber}
                labelKey="phoneNumber"
                showError="true"
                description="* Phone Number"
                value={
                  formData?.phoneNumber?.number
                    ? Formatter.valuePhoneNumber(formData?.phoneNumber?.number)
                    : formData?.phoneNumber
                }
                onChange={(e: { target: { value: any } }) => {
                  const fomattedNumber = e.target.value?.replace(/[^\d]*/g, '');
                  updateFormData({
                    field: 'phoneNumber',
                    value: fomattedNumber
                      ? Formatter.valuePhoneNumber(e.target.value?.replace(/[^\d]*/g, ''))
                      : fomattedNumber,
                  });
                }}
              />
              {!enableImageUploader && (
                <Field
                  inputType
                  className="clean-input-container"
                  submitted={submitted}
                  showError="true"
                  {...fields.profilePicture}
                  labelKey="profilePicture"
                  description="Profile Picture URL"
                  onChange={(e: { target: { value: any } }) => {
                    updateFormData({
                      field: 'profilePicture',
                      value: e.target.value,
                    });
                  }}
                />
              )}
              <Field
                submitted={submitted}
                description="Gender"
                className="clean-input-container"
                labelKey="gender"
                disableFilter
                inputProps={{ readOnly: true }}
                options={[{ label: 'Male' }, { label: 'Female' }].map(({ label }) => {
                  return { gender: label };
                })}
                onChange={(value: { gender: any }[]) => {
                  updateFormData({
                    field: 'gender',
                    value: value[0]?.gender,
                  });
                }}
                renderMenuItemChildren={(gender: React.ReactNode) => {
                  return <div>{gender}</div>;
                }}
                defaultSelected={[{ gender: formData?.gender ?? '' }]}
                {...fields.gender}
              />
              <Field
                submitted={submitted}
                description="Role"
                className="clean-input-container"
                labelKey="role"
                disableFilter
                disabled={PROVIDER_ROLES.ADMIN.value === formData?.role}
                inputProps={{ readOnly: true }}
                options={Object.values(omit(PROVIDER_ROLES, [`${PROVIDER_ROLES.ADMIN.value}`])).map(
                  ({ label, value }) => {
                    return { role: label, value };
                  },
                )}
                onChange={(value) => {
                  updateFormData({
                    field: 'role',
                    value: value[0]?.value,
                  });
                }}
                renderMenuItemChildren={(role: React.ReactNode) => {
                  return <div>{role}</div>;
                }}
                defaultSelected={[
                  {
                    role: PROVIDER_ROLES[formData?.role].label,
                  },
                ]}
                {...fields.role}
              />
              <Field
                submitted={submitted}
                description="* Organization"
                className="select-container"
                labelKey="organization"
                defaultSelected={[{ organization: formData?.organization?.name ?? '' }]}
                renderMenuItemChildren={(name: React.ReactNode) => {
                  return <div>{name}</div>;
                }}
                options={organizations.map(({ alias, name, id }) => {
                  return { organization: name, id, alias };
                })}
                onInputChange={debounce((searchTerm: string) => getOrganizations(searchTerm), 300)}
                onChange={(value: { alias: string; name: string; id: number }[]) => {
                  updateFormData({
                    field: 'organizationId',
                    value: value[0]?.id,
                  });
                  setUpdatedAlias(value[0]?.alias);
                }}
                emptyLabel={false}
                {...fields.organization}
              />
              {isNewItem && (
                <>
                  <Field
                    inputType="password"
                    className="clean-input-container"
                    submitted={submitted}
                    {...fields.password}
                    labelKey="password"
                    description="* Provider user password"
                    showError="true"
                    value={formData.password}
                    onChange={(e: { target: { value: any } }) => {
                      updateFormData({
                        field: 'password',
                        value: e.target.value?.replace(/^\s*/, ''),
                      });
                    }}
                  />
                  <Field
                    inputType="password"
                    className="clean-input-container"
                    submitted={submitted}
                    {...fields.passwordConfirm}
                    labelKey="passwordConfirm"
                    description="* Confirm password"
                    showError="true"
                    onChange={() => {}}
                  />
                </>
              )}
            </div>
            <div className="dialog-footer">
              <Row className="footer-btns">
                <Col xs={6}>
                  <Button
                    bsPrefix="btn btn-secondary"
                    className="cancel-btn"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    type="submit"
                    bsPrefix="btn btn-primary"
                    className="apply-btn"
                    disabled={isLoading}
                    onClick={() => setSubmitted(true)}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </BkmdModal>
    </div>
  );
};
