import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';

import './styles.less';
import useClientConfig from '../../config/useClientConfig';

const vimConsoleProdSSOLink = 'https://auth.getvim.com/samlp/YfCpFa52egIkajf8KhaoeuyWnIpff4z1';

const Login = (props: { auth0: { loginWithRedirect?: any; isAuthenticated?: any } }) => {
  const loginSuccessRedirect = () => {
    window.location.href = import.meta.env.VITE_ROUTE_BASE_PATH
      ? (import.meta.env.VITE_APP_ROUTE_BASE_PATH as string)
      : '/';
  };

  const { VIM_CONSOLE_SSO_LINK } = useClientConfig();

  const initiateSSO = () => {
    window.location.href = VIM_CONSOLE_SSO_LINK || vimConsoleProdSSOLink;
  };

  const urlParams = new URLSearchParams(window.location.search);
  const connection = urlParams.get('connection');

  const { isAuthenticated } = props.auth0;

  if (isAuthenticated) {
    loginSuccessRedirect();
    return null;
  } else if (connection === 'vim-console-saml-idp') {
    initiateSSO();
    return null;
  }

  return (
    <div className="login-page">
      <h2 className="login-form-title">
        <strong>Welcome,</strong> Let’s get started.
      </h2>
      <form name="form">
        <div className="form-group login-form-submit">
          <button
            className="btn btn-primary"
            onClick={() => {
              props.auth0.loginWithRedirect({
                redirect_uri: window.location.origin,
              });
            }}
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default withAuth0(Login);
