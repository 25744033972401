import React, { useState, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonGroupComponent } from '@getvim/atomic-ui';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { removeItem } from '../../utils/local-storage';
import { IconProfile2 } from '../../assets/icons';
import './styles.less';

const UserHeaderInfo = ({ name }: { name?: string }) => {
  const { logout } = useAuth0();

  const onLogout = useCallback(() => {
    removeItem('access_token');
    removeItem('user');
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const userMenuOptions = [
    {
      value: 'logout',
      text: 'Logout',
      icon: 'icon-logout',
      onClick: onLogout,
    },
  ];

  const userPopover = (
    <Popover className="btn-group-dropdown-popover" id="popover">
      <ButtonGroupComponent
        value=""
        direction="column"
        buttonType="dropdownOption"
        width="block"
        color="extraDarkGray"
        className="styled-button user-menu-icons"
        items={userMenuOptions}
      />
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger trigger="click" overlay={userPopover} placement="bottom" rootClose>
        <div className="user-header-info-v2">
          <div className="icon-container">
            <IconProfile2 />
          </div>
          <span className="user-name">Dr.&nbsp;{name}</span>
          <div className="user-menu-row-arrow"></div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default UserHeaderInfo;
