export const getItem = (name: string) => {
  return JSON.parse(localStorage.getItem(name) as string);
};

export const removeItem = (name: string) => {
  return localStorage.removeItem(name);
};

export const setItem = (name: string, value: any) => {
  return localStorage.setItem(name, JSON.stringify(value));
};

export const getAccessToken = () => {
  const sessionData = localStorage;

  let authData;

  for (const key in sessionData) {
    if (/@@auth0spajs@@/.test(key)) {
      authData = JSON.parse(sessionData[key]);
    }
  }

  return authData?.body?.access_token;
};

export const setAdapterConfig = (email: string) => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    removeItem('access_token');
    removeItem('user');
    return;
  }

  /** Do not change. Used by mock-ehr adapter */
  localStorage.setItem('access_token', accessToken);

  setItem('user', email);
};
