import React from 'react';
import { Button } from 'react-bootstrap';

import './styles.less';

export const NewOrganizationButton = ({ handleClick }: { handleClick: any }) => {
  return (
    <Button bsPrefix="" className="new-organization-btn" onClick={handleClick}>
      <i className="icon-plus-fat i-va-fix-2" />
      &nbsp;
      <span>New Organization</span>
    </Button>
  );
};
