export const setApiResponse = (responsePromise: Promise<any>, state: any, setHandler: any) => {
  return responsePromise
    .then((data) => {
      setHandler({
        ...data.data,
        loaded: true,
        error: false,
      });
      return data;
    })
    .catch((e) => {
      setHandler({
        ...state,
        error: true,
      });
    });
};

export const initialApiResponse = {
  loaded: false,
  error: false,
};
