import {
  InputSchema,
  InputOptionalSchema,
  EmailSchema,
  InputNpiSchema,
  PhoneNumberSchema,
  SelectInputSchema,
  SelectInputOptionalSchema,
  PasswordSchema,
} from '../../schemas';
import createValidator from '../../utils/createValidator';
import { CleanInput } from '../clean-input';
import { SelectTypeahead } from '../select-typeahead';
import { RadioButtons } from '../radio-buttons';

export const fields = {
  firstName: {
    name: 'firstName',
    field: 'firstName',
    required: true,
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  middleName: {
    name: 'middleName',
    field: 'middleName',
    component: CleanInput,
  },
  lastName: {
    name: 'lastName',
    field: 'lastName',
    required: true,
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  email: {
    name: 'email',
    field: 'email',
    required: true,
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.emailInput);
    },
  },
  npi: {
    name: 'npi',
    field: 'npi',
    required: true,
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputNpi);
    },
  },
  specialty: {
    name: 'specialty',
    field: 'specialty',
    component: SelectTypeahead,
  },
  visitType: {
    name: 'visitType',
    field: 'visitType',
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { visitType: value } : value;
        }
        return undefined;
      };

      return createValidator(getValue(), schema.selectInputOptional);
    },
  },
  degree: {
    name: 'degree',
    field: 'degree',
    component: CleanInput,
  },
  phoneNumber: {
    name: 'phoneNumber',
    field: 'phoneNumber',
    required: true,
    component: CleanInput,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return { ...value, number: value.number.replace(/\D/g, '') };
        }
        return undefined;
      };
      return createValidator(getValue(), schema.phoneNumber);
    },
  },
  gender: {
    name: 'gender',
    field: 'gender',
    required: true,
    component: RadioButtons,
    validate(value: any) {
      return createValidator(
        typeof value === 'string' ? { gender: value } : value,
        schema.selectInput,
      );
    },
  },
  role: {
    name: 'role',
    field: 'role',
    required: true,
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(
        typeof value === 'string' ? { role: value } : value,
        schema.selectInput,
      );
    },
  },
  organization: {
    name: 'organization',
    field: 'organization',
    formDataField: 'organizationId',
    required: true,
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { organization: value } : value;
        }
        return undefined;
      };

      return createValidator(getValue(), schema.selectInput);
    },
  },
  password: {
    name: 'password',
    field: 'password',
    required: true,
    component: CleanInput,
    validate(value: string) {
      return createValidator(value, schema.password);
    },
  },
  passwordConfirm: {
    name: 'passwordConfirm',
    field: 'passwordConfirm',
    required: true,
    component: CleanInput,
    validate(value: string) {
      // to show empty message but highlight
      if (!value) {
        return ' ';
      }
      // @ts-ignore
      if (value !== this.form.values.password) {
        return 'Password mismatch';
      }
      return false;
    },
  },
  profilePicture: {
    name: 'profilePicture',
    field: 'profilePicture',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional.url());
    },
  },
};

export const schema = {
  input: InputSchema,
  inputOptional: InputOptionalSchema,
  inputNpi: InputNpiSchema,
  phoneNumber: PhoneNumberSchema,
  selectInput: SelectInputSchema,
  selectInputOptional: SelectInputOptionalSchema,
  emailInput: EmailSchema,
  password: PasswordSchema,
};
