import React from 'react';

export default function SvgVimLoader() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" className="svg-icon svg-vim-loader" width="84px" height="71px" viewBox="0 0 84 71">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-6.000000, -6.000000)">
          <g className="heart" id="Page-1">
            <path d="M61.3584069,21.7258127 C66.0069363,15.8346814 64.8995833,7.36816561 58.8851225,2.81530588 C52.8701716,-1.73779819 44.2260539,-0.653155656 39.5775245,5.23773122 L32.11625,14.6923738 L49.5123284,36.7375683 L61.3584069,21.7258127 Z" id="Fill-13" fill="#083A6B"></path>
            <path d="M24.655098,5.23773122 C20.0068137,-0.653155656 11.362451,-1.73779819 5.3475,2.81530588 C-0.666960784,7.36816561 -1.77431373,15.8346814 2.87421569,21.7258127 L14.7202941,36.7375683 L25.745049,50.708419 C27.1737255,52.6487538 29.4872059,53.924229 32.1163725,53.924229 C34.7452941,53.924229 37.0590196,52.6487538 38.4876961,50.708419 L49.5122059,36.7375683 L24.655098,5.23773122 Z" id="Fill-15" fill="#09ACE4"></path>
          </g>
          <path className="ring" d="M53.9988353,8.39116102 C52.5609811,8.39116102 51.3913051,9.56099697 51.3913051,10.9986898 C51.3913051,12.4378384 52.5609811,13.608839 53.9988353,13.608839 C55.4378542,13.608839 56.6086949,12.4378384 56.6086949,10.9986898 C56.6086949,9.56099697 55.4378542,8.39116102 53.9988353,8.39116102 M53.998504,16 C51.2425013,16 49,13.7562544 49,10.9983172 C49,8.24224973 51.2425013,6 53.998504,6 C56.7563767,6 59,8.24224973 59,10.9983172 C59,13.7562544 56.7563767,16 53.998504,16" id="Fill-1" fill="#09ACE4"></path>
          <path className="plus" d="M84.0003359,63.50862 L84.0003359,65.0011195 L82.5058784,65.0011195 C81.6768559,65.0011195 81,65.6731472 81,66.5006717 L81,66.5006717 C81,67.3352489 81.6738327,67.9998881 82.5058784,67.9998881 L82.5058784,67.9998881 L84.0003359,67.9998881 L84.0003359,69.4944026 C84.0003359,70.3246138 84.6714814,71 85.5015116,71 L85.5015116,71 C86.3359086,71 87.0003359,70.3276364 87.0003359,69.4944026 L87.0003359,69.4944026 L87.0003359,67.9998881 L88.4971448,67.9998881 C89.3228082,67.9998881 90,67.3278603 90,66.5006717 L90,66.5006717 C90,65.6674379 89.3261673,65.0011195 88.4971448,65.0011195 L88.4971448,65.0011195 L87.0003359,65.0011195 L87.0003359,63.50862 C87.0003359,62.6767296 86.3275109,62 85.5015116,62 L85.5015116,62 C84.665435,62 84.0003359,62.6767296 84.0003359,63.50862 L84.0003359,63.50862 Z" id="Clip-15" fill="#09ACE4"></path>
          <circle className="circle" id="Oval-1" fill="#6CCEEF" cx="8.5" cy="43.5" r="2.5"></circle>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
