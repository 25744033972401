import React, { useState, useEffect } from 'react';
import { deprecated } from '@getvim/atomic-ui';
import Search from '../../components/search';
import OrganizationsList from '../../components/organizations-list';
import { NewOrganizationButton } from '../../components/organizations-list/newOrganizationButton';
import OrganizationModal from '../../components/organization-modal';
import UserHeaderInfo from '../../components/user-header-info';
import Loader from '../../components/loader';
import WithApiData from '../../hoks/withApiData';

import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';

import { Organization } from '../../types';

import './styles.less';

const ORGANIZATIONS_LIMIT = 10;

const Organizations = (props: any) => {
  const { fetchUserData } = props;
  const [activePage, setActivePage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
  const [organizations, setOrganizations] = useState<{
    data: Organization[];
    meta: { total: number };
  }>({
    data: [],
    meta: { total: 0 },
  });

  const isNewItem = !selectedOrganization;
  const api = useApi();
  const user = useUserData();

  const getLimitAndSkip = (): { skip: number; limit: number } => {
    return { skip: (activePage - 1) * ORGANIZATIONS_LIMIT, limit: ORGANIZATIONS_LIMIT };
  };

  const getOrganizations = async ({ offset, limit }: { offset?: number; limit?: number }) => {
    const LimitOffsetQuery = !offset && !limit ? getLimitAndSkip() : { offset, limit };
    setIsLoading(true);

    const organizationsData = await api.getOrganizationsList(LimitOffsetQuery);
    setOrganizations(organizationsData);
    setIsLoading(false);
  };

  useEffect(() => {
    getOrganizations({});
  }, []);

  const getPagesAmount = (itemsNumber: number, pageSize: number): number => {
    if (itemsNumber === pageSize) return 0;

    const pageNumberNoRemainder = Math.floor(itemsNumber / pageSize);
    const remainder = itemsNumber % pageSize;

    return pageNumberNoRemainder + (remainder ? 1 : 0);
  };

  return (
    <div className="organizations-list">
      <div className="header-content">
        <Search />
        <UserHeaderInfo name={user.firstName} />
      </div>
      <div className="organizations-list-header">
        <h1 className="dashboard-title">Organizations list</h1>
        <div className="new-organization-btn-wrapper">
          <NewOrganizationButton
            handleClick={() => {
              setIsOrganizationModalOpen(true);
            }}
          />
        </div>
      </div>
      {isLoading && <Loader />}

      {!isLoading && organizations && (
        <div className="organizations-list-content-wrap">
          <OrganizationsList
            organizationsList={organizations.data}
            onSelect={(organization: Organization) => {
              setSelectedOrganization(organization);
              setIsOrganizationModalOpen(true);
            }}
          />
        </div>
      )}
      {!isLoading && (
        <deprecated.Pagination
          active={Number(activePage)}
          maxButtons={3}
          pagesNumber={getPagesAmount(organizations.meta.total, ORGANIZATIONS_LIMIT)}
          onClick={(newPageNumber) => {
            getOrganizations({
              offset: (newPageNumber - 1) * ORGANIZATIONS_LIMIT,
              limit: ORGANIZATIONS_LIMIT,
            });
            setActivePage(newPageNumber);
          }}
        />
      )}
      {isOrganizationModalOpen && (
        <OrganizationModal
          isNewItem={isNewItem}
          organizationData={selectedOrganization}
          isOpen={isOrganizationModalOpen}
          onClose={() => {
            setIsOrganizationModalOpen(false);
            if (selectedOrganization) setSelectedOrganization(undefined);
          }}
          onSave={() => {
            setIsOrganizationModalOpen(false);
            getOrganizations({});
            if (selectedOrganization) {
              setSelectedOrganization(undefined);
              if (selectedOrganization.id === user?.organization?.id) {
                fetchUserData();
              }
            }
          }}
          onDelete={() => {
            setIsOrganizationModalOpen(false);
            getOrganizations({});
            if (selectedOrganization) setSelectedOrganization(undefined);
          }}
        />
      )}
    </div>
  );
};

export default WithApiData(Organizations);
