import React, { PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import useClientConfig from '../../config/useClientConfig';

export default function AuthProvider({ children }: PropsWithChildren<any>) {
  const { VIM_OAUTH0_DOMAIN, VIM_OAUTH0_CLIENT_ID, VIM_OAUTH0_AUDIENCE } = useClientConfig();
  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={VIM_OAUTH0_DOMAIN as string}
      clientId={VIM_OAUTH0_CLIENT_ID as string}
      redirectUri={window.location.origin}
      audience={VIM_OAUTH0_AUDIENCE}
    >
      {children}
    </Auth0Provider>
  );
}
