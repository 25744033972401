import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const Pages = ({
  active,
  maxButtons,
  pagesNumber,
  onClick,
  className,
}: {
  active: number;
  maxButtons: number;
  pagesNumber: number;
  onClick: (p: number) => any;
  className?: string;
}) => {
  const items: any[] = [];
  const startIndex = active - 1 || 1;
  const endIndex = Math.min(pagesNumber, maxButtons + startIndex - 1);
  for (let page = startIndex; page <= endIndex; page++) {
    items.push(
      <Pagination.Item active={active === page} onClick={() => onClick(page)} key={page}>
        {page}
      </Pagination.Item>,
    );
  }
  return (
    <Pagination className={className} size="lg">
      {active > 1 && <Pagination.Prev onClick={() => onClick(active - 1)} />}
      {items}
      {active < pagesNumber && (
        <Pagination.Next className="vim-pagination-next" onClick={() => onClick(active + 1)} />
      )}
    </Pagination>
  );
};

export default Pages;
