import React from 'react';
import { ReferralsOrdersNotes } from '../referral-order-notes';
import { ListType } from '../../types';
import './styles.less';

export const Referrals = ({
  patient,
  appointmentId,
  api,
  isApptLocked,
}: {
  patient: any;
  appointmentId: number;
  api: any;
  isApptLocked: boolean;
}) => {
  return (
    <ReferralsOrdersNotes
      title="Referrals"
      modalTitle="Referral"
      newItemTitle="New Referral"
      patient={patient}
      appointmentId={appointmentId}
      listType={ListType.referrals}
      isApptLocked={isApptLocked}
      getItemsByApi={async ({ patient, appointmentId }) => {
        return await api.getReferrals({ patientId: patient.id, appointmentId });
      }}
      prepareReferToText={(referTo: any): string => {
        return `${referTo?.firstName} ${referTo?.lastName}`;
      }}
      handleItemCreate={async (body) => {
        return await api.createReferral(body);
      }}
      handleItemDelete={async (referralId) => {
        return await api.deleteReferral(referralId);
      }}
      handleItemUpdate={async (referralId: number, body: any) => {
        return await api.updateReferral(referralId, body);
      }}
    />
  );
};
