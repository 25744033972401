import React from 'react';
import Formatter from '../../utils/formatter';
import './styles.less';

const PatientProfile = ({ profileData }: any) => {
  const { gender, memberId, insurer, dob, location, ehrInsurance, email, groupId, payerId } =
    profileData;
  return (
    <div className="personal-info">
      <div className="patient-profile-card">
        <div className="specification-column">
          <div className="blockTitle--wrapper">
            <div>
              <span>Personal info</span>
            </div>
          </div>
          <ul>
            {gender ? (
              <li className="gender" id="patient-gender" data-patient-gender={gender}>
                <b>Gender:</b> {gender}
              </li>
            ) : null}
            {memberId ? (
              <li className="gender" id="patient-member-id" data-patient-member-id={memberId}>
                <b>Member ID:</b> {memberId}
              </li>
            ) : null}
            {insurer ? (
              <li className="insurer" id="patient-insurer" data-patient-insurer={insurer}>
                <b>Insurer:</b> {insurer}
              </li>
            ) : null}
            {dob ? (
              <>
                <li className="dob" id="patient-dob" data-patient-dob={dob}>
                  <b>DOB:</b> {Formatter.formatDOB(dob)}
                </li>
                <li className="age" id="patient-age" data-patient-age={Formatter.getAgeByDOB(dob)}>
                  <b>Age:</b> {Formatter.getAgeByDOB(dob)}
                </li>
              </>
            ) : null}
          </ul>
        </div>

        <div className="specification-column">
          <div className="blockTitle--wrapper">
            <div>
              <span>Contact info</span>
            </div>
          </div>
          <ul>
            {location?.state ? (
              <li className="state" id="patient-state" data-patient-state={location.state}>
                <b>State:</b> {location.state}
              </li>
            ) : null}
            {location?.city ? (
              <li className="city" id="patient-city" data-patient-city={location.city}>
                <b>City:</b> {location.city}
              </li>
            ) : null}
            {location?.addressLine1 ? (
              <li
                className="street"
                id="patient-street"
                data-patient-street={location.addressLine1}
              >
                <b>Street:</b> {location.addressLine1}
              </li>
            ) : null}
            {location?.zip ? (
              <li className="zip_code" id="patient-zip-code" data-patient-zip-code={location.zip}>
                <b>Zip Code:</b> {location.zip}
              </li>
            ) : null}
            {email ? (
              <li className="email" id="patient-email" data-patient-email={email}>
                <b>Email:</b> {email}
              </li>
            ) : null}
          </ul>
        </div>
        <div className="specification-column">
          <div className="blockTitle--wrapper">
            <div>
              <span>Insurance</span>
            </div>
          </div>
          <ul>
            {insurer ? (
              <li className="insurer" id="patient-insurer" data-patient-insurer={insurer}>
                <b>Insurer:</b> {insurer}
              </li>
            ) : null}
            {ehrInsurance ? (
              <li
                className="ehrInsurance"
                id="patient-ehrInsurance"
                data-patient-ehrInsurance={ehrInsurance}
              >
                <b>EHR Insurance:</b> {ehrInsurance}
              </li>
            ) : null}
            {groupId ? (
              <li className="groupId" id="patient-groupId" data-patient-groupId={groupId}>
                <b>Group ID:</b> {groupId}
              </li>
            ) : null}
            {payerId ? (
              <li className="payerId" id="patient-payerId" data-patient-payerId={payerId}>
                <b>Payer ID:</b> {payerId}
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PatientProfile;
