import React, { FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '../loader';
import './index.less';

export type ButtonColor =
  | 'gray'
  | 'darkGray'
  | 'green'
  | 'red'
  | 'orange'
  | 'white'
  | 'extraDarkGray';
const btnColor: { [x in ButtonColor]: string } = {
  gray: '#6E6E6E',
  darkGray: '#5C5C5C',
  extraDarkGray: '#32333C',
  green: '#278600',
  red: '#B12704',
  orange: '#B55A00',
  white: '#FFFFFF',
};

const btnType = {
  tiny: 'btn-tiny',
  tinyRounded: 'btn-tiny-rounded',
  small: 'btn-small',
  large: 'btn-big',
  huge: 'btn-huge',
  pill: 'btn-small btn-pill',
  link: 'btn-inline-link',
  outline: 'btn-outline',
  dropdown: 'btn-dropdown',
  dropdownOption: 'btn-dropdown-option',
  tab: 'btn-tab',
};

const btnWidth = {
  default: '',
  block: 'btn-block',
  small: 'btn-min-100',
  smallMedium: 'btn-min-160',
  medium: 'btn-min-300',
};

const btnBgColor = {
  default: 'default-color',
  gray: 'btn-grey',
  lightGray: 'btn-light-grey',
  white: 'btn-outline',
  red: 'btn-red',
  themedOutline: 'btn-outline-themed',
  outlineDark: 'btn-outline-dark',
  groupOptionOutline: 'btn-group-option-outline',
  none: 'no-bg',
};

export const buttonPropTypes = {
  text: PropTypes.string,
  buttonType: PropTypes.oneOf(Object.keys(btnType) as Array<keyof typeof btnType>),
  width: PropTypes.oneOf(Object.keys(btnWidth) as Array<keyof typeof btnWidth>),
  className: PropTypes.string,
  bgColor: PropTypes.oneOf(Object.keys(btnBgColor) as Array<keyof typeof btnBgColor>),
  multiLines: PropTypes.bool,
  underline: PropTypes.bool,
  isLoading: PropTypes.bool,
  selected: PropTypes.bool,
  color: PropTypes.oneOf(Object.keys(btnColor) as Array<keyof typeof btnColor>),
  disabled: PropTypes.bool,
  styleOverrides: PropTypes.shape({
    border: PropTypes.string,
    selected: PropTypes.object,
  }),
};

type HtmlButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export type ButtonProps = PropTypes.InferProps<typeof buttonPropTypes> & HtmlButtonProps;

export type BtnType = ButtonProps['buttonType'];
export type BtnWidth = ButtonProps['width'];
export type BtnBgColor = ButtonProps['bgColor'];
export type BtnColor = ButtonProps['color'];

const Button: FunctionComponent<ButtonProps> = React.forwardRef(
  (
    {
      text,
      buttonType,
      width,
      color,
      bgColor,
      multiLines,
      underline,
      isLoading,
      className,
      selected,
      children,
      disabled,
      type,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    const isDisabled = isLoading || disabled;
    const inlineColorStyle = color ? { color: btnColor[color] } : {};
    const outlineStyle =
      buttonType === 'outline'
        ? {
            backgroundColor: undefined,
            color: 'var(--main-color)',
            borderColor: 'var(--main-color)',
          }
        : {};
    const dynamicStyle = {
      ...inlineColorStyle,
      ...outlineStyle,
    };
    const loaderColor =
      bgColor && ['white', 'themedOutline', 'groupOptionOutline', 'none'].includes(bgColor)
        ? 'grey'
        : 'white';

    return (
      <button
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={classNames(
          'btnx',
          btnType[buttonType!],
          btnWidth[width!],
          btnBgColor[bgColor!],
          { 'two-lines-btn': multiLines },
          { 'text-underline': underline },
          { selected },
          className,
        )}
        style={dynamicStyle}
        disabled={isDisabled}
        {...rest}
      >
        {isLoading && <Loader type="dots" size="small" padding="none" color={loaderColor} />}
        {!isLoading && text}
        {!isLoading && children}
      </button>
    );
  },
) as FunctionComponent<ButtonProps>;

Button.propTypes = buttonPropTypes;
Button.defaultProps = {
  text: '',
  buttonType: 'large',
  width: 'default',
  className: '',
  bgColor: 'default',
  multiLines: false,
  underline: false,
  isLoading: false,
  selected: false,
  disabled: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  type: 'button',
};

export default Button;
