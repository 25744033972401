import React, { InputHTMLAttributes, RefObject } from 'react';
import classNames from 'classnames';
import './index.less';

interface LabeledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  active?: boolean;
  label?: string;
  htmlFor?: string;
  refs?: RefObject<HTMLInputElement>;
}

export default function LabeledInput({
  active,
  htmlFor,
  label,
  refs,
  ...input
}: LabeledInputProps) {
  return (
    <div className="input">
      <label
        aria-hidden="true"
        className={classNames('atomic-label', { 'input-active': active })}
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <input {...input} ref={refs} />
    </div>
  );
}
