import React from 'react';
import { ReactComponent as IconNoResults } from '../../assets/new-icons/no-results.svg';

import './styles.less';

const NoResultsFound = ({ searchItem }: { searchItem: string }) => {
  return (
    <div className="no-results">
      <span className="no-results__title">No content found</span>
      <span>There are no {searchItem}</span>
      <div>
        <IconNoResults />
      </div>
    </div>
  );
};

export default NoResultsFound;
