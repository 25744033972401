import React from 'react';
import { Button } from '@getvim/atomic-ui';

export const NewOrganizationButton = ({ handleClick }: { handleClick: any }) => {
  return (
    <Button className="new-organization-btn-v2" buttonType="small" onClick={handleClick}>
      <i className="icon-plus-fat i-va-fix-2" />
      &nbsp;
      <span>New Organization</span>
    </Button>
  );
};
