import React from 'react';
import { InputStyle, InputStyleWrapper, LabeledInput } from '@getvim/atomic-ui';
import { ReactComponent as IconSearch } from '../../assets/new-icons/search-right.svg';

import './styles.less';

interface SearchInputProps {
  placeholder: string;
  handleChange: (event: any) => void;
}

const SearchInput = ({ placeholder, handleChange }: SearchInputProps) => {
  return (
    <InputStyleWrapper
      leftIcon={<IconSearch />}
      className="search-input-wrapper"
      inputStyle={InputStyle.medium}
    >
      <LabeledInput
        id="search-filter"
        className="provider-search"
        autoComplete="off"
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
      />
    </InputStyleWrapper>
  );
};

export default SearchInput;
