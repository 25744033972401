import React from 'react';

export default function SvgVimHeart() {
  /* eslint-disable */
  return (
    <svg aria-hidden="true" focusable="false" width="65px" height="54px" viewBox="0 0 65 54" version="1.1" className="svg-icon svg-vim-heart">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g>
            <path className="right-shape" d="M61.3584069,21.7258127 C66.0069363,15.8346814 64.8995833,7.36816561 58.8851225,2.81530588 C52.8701716,-1.73779819 44.2260539,-0.653155656 39.5775245,5.23773122 L32.11625,14.6923738 L49.5123284,36.7375683 L61.3584069,21.7258127 Z" fill="#083A6B"></path>
            <path className="left-shape" d="M24.655098,5.23773122 C20.0068137,-0.653155656 11.362451,-1.73779819 5.3475,2.81530588 C-0.666960784,7.36816561 -1.77431373,15.8346814 2.87421569,21.7258127 L14.7202941,36.7375683 L25.745049,50.708419 C27.1737255,52.6487538 29.4872059,53.924229 32.1163725,53.924229 C34.7452941,53.924229 37.0590196,52.6487538 38.4876961,50.708419 L49.5122059,36.7375683 L24.655098,5.23773122 Z" fill="#09ACE4"></path>
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable */
}
