import React from 'react';
import classNames from 'classnames';
import { AlignEnum, SizeEnum } from '../../types';
import Text from '../text';
import Tooltip, { TooltipProps } from '../tooltip';
import './index.less';

export interface ChipProps {
  text?: string | JSX.Element;
  leftIcon?: string;
  rightIcon?: string;
  tooltipText?: string;
  className?: string;
  bgColor?: string;
  tooltipShadow?: boolean;
  bottomTooltip?: boolean;
  tooltipBgColor?: TooltipProps['bgColor'];
  tooltipWidth?: TooltipProps['width'];
}

const Chip = ({
  text,
  leftIcon,
  rightIcon,
  tooltipText,
  className,
  bgColor,
  tooltipShadow,
  tooltipBgColor = 'light',
  tooltipWidth,
  bottomTooltip = false,
}: ChipProps) => {
  return (
    <div
      className={classNames('atomic-chip', className, {
        'no-left-icon': !leftIcon,
        'no-right-icon': !rightIcon && !tooltipText,
      })}
      style={{ backgroundColor: bgColor }}
    >
      {leftIcon && (
        <div className="chip-left-icon">
          <img src={leftIcon} />
        </div>
      )}
      <Text size={SizeEnum['12px']} align={AlignEnum.center} className="chip-text" text={text} />
      {(rightIcon || tooltipText) && (
        <div className="chip-right-icon">
          {tooltipText ? (
            <Tooltip
              className={classNames('chip-tooltip', { 'show-bottom-tooltip': bottomTooltip })}
              tooltipContent={tooltipText}
              bgColor={tooltipBgColor}
              width={tooltipWidth}
              placement="top"
              showShadow={tooltipShadow}
              portal={!bottomTooltip}
            >
              {rightIcon ? <img src={rightIcon}></img> : <i className="icon-help" />}
            </Tooltip>
          ) : (
            <img src={rightIcon}></img>
          )}
        </div>
      )}
    </div>
  );
};

export default Chip;
