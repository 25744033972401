import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { deprecated } from '@getvim/atomic-ui';

import Formatter from '../../utils/formatter';
import { useUserData } from '../../hooks/useUserData';
import Loader from '../loader';
import { Patient } from '../../types';

import './styles.less';
import { IconPen, IconTrash } from '../../assets/icons';
import defaultAvatarImage from '../../assets/icons/icon-profile.svg';
import ConfirmDeleteModal from '../confirm-delete-modal';
import { UserRole } from '../../types/user';
import { roleAccess } from '../../utils/roles';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField, InputAdornment } from '@mui/material';
import { debounce } from 'lodash-es';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import { editDeletePatientsForNonAdminFF } from '../../utils/featureFlagClient';

interface PatientsListProps {
  patients: { data: Patient[]; meta: { total: number } };
  organizations: { id: number; alias: string; name: string }[];
  isLoading: boolean;
  getPatients: ({
    offset,
    limit,
    search,
  }: {
    offset: number;
    limit: number;
    search?: string;
  }) => void;
  handleSelect(item: Patient): void;
  activePage: number;
  setActivePage(page: number): void;
  deletePatient(id: string): void;
  setFilter(text: string): void;
  setOrganizationIdFilter(id: number): void;
}

const PATIENTS_LIMIT = 10;

const PatientsList = (props: PatientsListProps) => {
  const {
    patients,
    organizations,
    isLoading,
    getPatients,
    handleSelect,
    activePage,
    setActivePage,
    deletePatient,
    setOrganizationIdFilter,
    setFilter,
  } = props;
  const user = useUserData();

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [patientToBeDeleted, setPatientToBeDeleted] = useState<Patient | null>(null);

  const { isAdmin, organization } = useUserData();
  const [searchOrganization, setSearchOrganization] = useState<any>(organization);

  const [enableEditDeletePatientsForNonAdmin] = useFeatureFlag({
    flagName: editDeletePatientsForNonAdminFF,
    defaultValue: false,
    team: Team.Interfaces,
  });

  useEffect(() => {
    if (deleteConfirmed && patientToBeDeleted) {
      deletePatient(patientToBeDeleted.id);
      setPatientToBeDeleted(null);
      setDeleteConfirmed(false);
    }
  }, [deleteConfirmed]);

  const getPagesAmount = (itemsAmount: number, pageSize: number): number => {
    const remainder = itemsAmount % pageSize;
    const fullPagesAmount = (itemsAmount - remainder) / pageSize;

    return fullPagesAmount + (remainder ? 1 : 0);
  };

  const debouncedSetFilter = debounce((value?: string) => {
    setFilter(value || '');
  }, 1000);

  return (
    <div className="patients-list">
      {isLoading && <Loader />}

      <div className="filters-block">
        {roleAccess({ allowed: [UserRole.ADMIN], userRole: user.role }) && (
          <Autocomplete
            disableClearable
            className="organization-filter"
            value={searchOrganization}
            options={organizations}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Organization" variant="outlined" required />
            )}
            onChange={(event, newValue) => {
              if (newValue) {
                setSearchOrganization(newValue);
                setOrganizationIdFilter(newValue.id);
              }
            }}
          />
        )}
        <TextField
          label="Type patient name"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            debouncedSetFilter(event.target?.value);
          }}
        />
      </div>

      {!isLoading &&
        patients.data.map((patient: Patient) => {
          const { id, firstName, lastName, gender, dob, insurer, profilePicture, externalId, mrn } =
            patient;
          const age = dob ? Formatter.getAgeByDOB(dob) : null;
          const userUrl = `/patient/${id}`;

          return (
            <Link to={userUrl} className="patient-item" key={`patient-${id}`}>
              <div className="patient-details-copntainer">
                <div className="patient-avatar">
                  <img src={profilePicture || defaultAvatarImage} alt="" />
                </div>
                <div className="patient-text-side">
                  <div className="patient-title">
                    <h4 className="patient-title__text">
                      {Formatter.getFullName(firstName, lastName)}
                    </h4>
                  </div>
                  {gender ? (
                    <div className="gender">
                      <b>Gender:</b> {gender}
                    </div>
                  ) : null}
                  {age ? (
                    <div className="age">
                      <b>Age:</b> {age}
                    </div>
                  ) : null}
                  {insurer ? (
                    <div className="insurer">
                      <b>Insurer:</b> {insurer}
                    </div>
                  ) : null}
                  {externalId ? (
                    <div className="externalId">
                      <b>External ID:</b> {externalId}
                    </div>
                  ) : null}
                  {mrn ? (
                    <div className="mrn">
                      <b>MRN:</b> {mrn}
                    </div>
                  ) : null}
                </div>
              </div>
              {(isAdmin || enableEditDeletePatientsForNonAdmin) && (
                <div className="patient-title__action-btns-wrapper">
                  <div className="patient-title__action-btn">
                    <IconPen
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleSelect(patient);
                      }}
                    />
                  </div>
                  <div className="patient-title__action-btn">
                    <IconTrash
                      onClick={(e: any) => {
                        e.preventDefault();
                        setPatientToBeDeleted(patient);
                        setConfirmDeleteModalOpen(true);
                      }}
                    />
                  </div>
                </div>
              )}
            </Link>
          );
        })}
      {!isLoading && (
        <deprecated.Pagination
          active={Number(activePage)}
          maxButtons={3}
          pagesNumber={getPagesAmount(patients.meta.total, PATIENTS_LIMIT)}
          onClick={(newPageNumber) => {
            getPatients({ offset: (newPageNumber - 1) * PATIENTS_LIMIT, limit: PATIENTS_LIMIT });
            setActivePage(newPageNumber);
          }}
        />
      )}
      {confirmDeleteModalOpen && patientToBeDeleted && (
        <ConfirmDeleteModal
          dataToBeDeleted={Formatter.getFullName(
            patientToBeDeleted.firstName,
            patientToBeDeleted.lastName,
          )}
          onConfirmed={() => {
            setDeleteConfirmed(true);
            setConfirmDeleteModalOpen(false);
          }}
          onClose={() => setConfirmDeleteModalOpen(false)}
          isOpen={confirmDeleteModalOpen}
        />
      )}
    </div>
  );
};

export default PatientsList;
