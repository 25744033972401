import { logger } from '@getvim/scheduling-logger';

function snippetChecks(apiKey: string, systemName: string) {
  if (!apiKey) {
    return Promise.reject(new Error(`Can not init ${systemName} without an api key!`));
  }
  if (typeof document === 'undefined') {
    return Promise.reject(
      new Error(`document was not found, can only init ${systemName} on the client side!`),
    );
  }

  return Promise.resolve();
}

const loadDynamicScript = (id: string, src: string) => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);

    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = src;
      script.id = id;
      document.body.appendChild(script);

      script.onload = resolve;
      script.onerror = () => {
        reject('Failed to download Google API script');
      };
    }

    if (existingScript) resolve(true);
  });
};

const loadGooglePlacesAutoCompleteApi = async (apiKey = '') => {
  try {
    // @ts-ignore
    if (!window?.google?.maps) {
      await snippetChecks(apiKey, 'Google places auto complete api');
      await loadDynamicScript(
        'googleMaps',
        `https://maps.googleapis.com/maps/api/js?key=&libraries=places`,
      );

      // @ts-ignore
      if (typeof window?.google?.maps === 'undefined') {
        throw new Error('Google api for places auto complete failed to initialize');
      }

      Promise.resolve(true);
    }
  } catch (error) {
    logger.error(`Failed to init google API: ${error}`, {
      clientName: 'mock-ehr',
      scope: 'google-api',
      metadata: {
        error,
      },
    });

    throw new Error('Failed to initialize google API script');
  }

  return true;
};

export default loadGooglePlacesAutoCompleteApi;
