import React from 'react';
import { BkmdModal, ModalHeader } from '@getvim/atomic-ui';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './styles.less';

interface ConfirmDeleteModalProps {
  onConfirmed(): void;
  onClose(): void;
  isOpen: boolean;
  dataToBeDeleted?: string;
  warnMessage?: string | JSX.Element;
  closeOnBackdrop?: boolean;
}

const ConfirmDeleteModal = ({
  warnMessage = 'Please confirm your action, this cannot be undone.',
  dataToBeDeleted,
  onConfirmed,
  isOpen,
  onClose,
  closeOnBackdrop = true,
}: ConfirmDeleteModalProps) => {
  return (
    <BkmdModal
      isOpen={isOpen}
      handleClose={onClose}
      autoFocus={false}
      closeOnBackdrop={closeOnBackdrop}
    >
      <div className="dialog-title-container">
        <div className="dialog-title">Are you sure?</div>
        <div
          className="dialog-title__close-button"
          onClick={() => {
            onClose();
          }}
        />
      </div>
      <div className="dialog-body-v2">
        {dataToBeDeleted ? (
          <>
            You are about to delete {dataToBeDeleted}. {warnMessage}
          </>
        ) : (
          warnMessage
        )}
      </div>
      <div className="dialog-footer">
        <Row className="footer-btns-v2">
          <Col xs={6}>
            <Button
              bsPrefix="btn btn-secondary-v2"
              className="footer-btns-v2__cancel-btn"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              type="submit"
              bsPrefix="btn btn-primary-v2"
              className="footer-btns-v2__confirm-btn"
              onClick={() => {
                onConfirmed();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </BkmdModal>
  );
};

export default ConfirmDeleteModal;
