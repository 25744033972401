import React, { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useApi from '../hooks/useApi';
import { isUserAdmin } from '../utils/auth';
import { dispatch, ACTION_TYPE } from '../hooks/useUserData';
import { setAdapterConfig } from '../utils/local-storage';
import { GlobalContext } from '../contexts/global';

export default (Component: React.FC): React.FC => {
  return (props: any) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    const api = useApi();
    const { enableUserAutoProvisioning } = useContext(GlobalContext);

    useEffect(() => {
      if (isAuthenticated) {
        fetchUserData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const fetchUserData = async () => {
      try {
        const user = (await api.getCurrentUser()) as any;
        setAdapterConfig(user?.email ?? user?.data?.email); // TODO: fix after https://github.com/bookmd/bookmd/pull/28734 will be merged on mock-ehr-server

        dispatch({
          type: ACTION_TYPE.SET_USER,
          payload: {
            ...(user?.data ? user.data : user), // TODO: fix after https://github.com/bookmd/bookmd/pull/28734 will be merged on mock-ehr-server
            isAdmin: isUserAdmin(user?.data ? user.data : user), // TODO: fix after https://github.com/bookmd/bookmd/pull/28734 will be merged on mock-ehr-server
            loaded: true,
            error: false,
          },
        });
      } catch (error: any) {
        if (error.statusCode === 404) {
          if (enableUserAutoProvisioning) {
            await tryToAutoProvisionUser();
          } else {
            dispatch({
              type: ACTION_TYPE.SET_USER_NOT_FOUND,
            });
          }
        } else {
          dispatch({
            type: ACTION_TYPE.SET_USER_ERROR,
          });
        }
        return null;
      }
    };

    const tryToAutoProvisionUser = async () => {
      try {
        await api.provisionUser();
        await getAccessTokenSilently({ ignoreCache: true });
        const user = (await api.getCurrentUser()) as any;
        setAdapterConfig(user?.email ?? user?.data?.email);

        dispatch({
          type: ACTION_TYPE.SET_USER,
          payload: {
            ...(user?.data ? user.data : user),
            isAdmin: isUserAdmin(user?.data ? user.data : user),
            loaded: true,
            error: false,
          },
        });
      } catch (error) {
        dispatch({
          type: ACTION_TYPE.SET_USER_NOT_FOUND,
        });
      }
    };

    return <Component {...props} isLoading={isLoading} fetchUserData={fetchUserData} />;
  };
};
