import { parsePhoneNumberFromString } from 'libphonenumber-js';
import moment from 'moment';

class Formatter {
  static getAgeByDOB(dateString: string | number | Date) {
    const birthDate = moment.utc(dateString);
    const age = moment().diff(birthDate, 'years');

    return age;
  }

  static formatDOB(dateString: string | number | Date) {
    if (!dateString) return undefined;

    const utcBirthDate = moment.utc(dateString);

    return utcBirthDate.format('MM/DD/YYYY');
  }

  static dateViewFormatter(date: string): string {
    if (!date) return '';

    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);

    return `${month}/${day}/${year}`;
  }

  static valuePhoneNumber = (number: string) => {
    const phoneNumber = parsePhoneNumberFromString(number, 'US');
    return phoneNumber?.formatNational();
  };

  static formatPhoneNumber = (number: string) => {
    const phoneNumber = parsePhoneNumberFromString(number, 'US');
    return {
      number: (phoneNumber?.nationalNumber as string) || '',
      countryDialingCode: phoneNumber?.countryCallingCode as string,
    };
  };

  static addressFormat(address: any, isFiveDigitZip = true) {
    let stringAddress = '';
    if (!address) {
      return stringAddress;
    }
    if (address.addressLine1) {
      stringAddress = address.addressLine1;
    }
    if (address.addressLine2) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.addressLine2}`;
    }
    if (address.city) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.city}`;
    }
    if (address.state) {
      stringAddress += `${stringAddress ? ', ' : ''}${address.state}`;
    }
    if (address.zip) {
      stringAddress += `${stringAddress ? ' ' : ''}${
        isFiveDigitZip ? address.zip.substring(0, 5) : address.zip
      }`;
    }

    return stringAddress;
  }

  static getFullName(firstName: string, lastName: string) {
    return `${firstName} ${lastName}`;
  }

  static toSentenceCase(text: string | undefined) {
    if (!text) return;

    return text
      .toLowerCase()
      .replace(/(?<=\b)[a-z]/, (s) => s.toUpperCase())
      .replace(/(?<=\s-\s)[a-z]/, (s) => s.toUpperCase());
  }
}

export default Formatter;
