import React, { useEffect, useState, useRef } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import { isString } from 'lodash-es';
import useApi from '../../hooks/useApi';

import './styles.less';

type SubjectiveProps = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  isApptLocked: boolean;
};
const GENERAL_NOTES_KEY = 'objective_general_notes';
const PHYSICAL_EXAMINATION_KEY = 'objective_physical_examination';

const Objective: React.FC<SubjectiveProps> = ({ selectedAppointment, isApptLocked }) => {
  const [generalNotes, setGeneralNotes] = useState<undefined | string>();
  const [physicalExamination, setPhysicalExamination] = useState<undefined | string>();

  const api = useApi();

  const timeouts = useRef({
    [GENERAL_NOTES_KEY]: null,
    [PHYSICAL_EXAMINATION_KEY]: null,
  });

  const saveMetadataField = (key, value) => {
    //@ts-ignore
    clearTimeout(timeouts.current[key]);

    //@ts-ignore
    timeouts.current[key] = setTimeout(() => {
      api.upsertMetadata(selectedAppointment.id, { key, value });
    }, 3000);
  };

  const handleGeneralNotesChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setGeneralNotes(value);

    saveMetadataField(GENERAL_NOTES_KEY, value);
  };
  const handlePhysicalExaminationChanges = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    const value = event.target.value;
    setPhysicalExamination(value);

    saveMetadataField(PHYSICAL_EXAMINATION_KEY, value);
  };

  const parseSubjective = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      switch (key) {
        case GENERAL_NOTES_KEY:
          setGeneralNotes(value);
          break;
        case PHYSICAL_EXAMINATION_KEY:
          setPhysicalExamination(value);
          break;
      }
    });
  };

  useEffect(() => {
    getSubjective();
  }, [selectedAppointment]);

  const getSubjective = async () => {
    if (selectedAppointment) {
      parseSubjective(selectedAppointment.metadata);
    }
  };

  return (
    <Box className="text-area-wrapper-v2" display="flex" flexDirection="column" flexGrow={1}>
      <span className="main-section-title">Objective</span>
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          data-type-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          maxRows={8}
          minRows={7}
          value={generalNotes}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleGeneralNotesChanges}
        />
      </Box>
      <Box>
        <div className="title">
          <span>Physical examination</span>
        </div>
        <TextareaAutosize
          data-test-id="physical-exam-text"
          data-type-id="physical-exam-text"
          style={{ width: '100%', resize: 'none' }}
          maxRows={8}
          minRows={7}
          value={physicalExamination}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handlePhysicalExaminationChanges}
        />
      </Box>
    </Box>
  );
};

export default Objective;
