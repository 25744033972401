import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#32333d',
      dark: 'black',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    fontSize: 22,
  },
  shape: {
    borderRadius: 5,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});
