import { useReducer } from 'react';
import { singletonHook } from 'react-singleton-hook';

import { initialApiResponse } from '../utils/apiUtils';

interface IAction {
  type: string;
  payload?: any;
}

interface IState {
  id: string;
  email: string;
  firstName?: string;
  role: null | string;
  emailVerified: null | boolean;
  isAdmin: boolean;
  loaded: boolean;
  error: boolean;
  organization: { id: number; alias: string; name: string };
}

const initialState = {
  id: null,
  email: '',
  role: null,
  emailVerified: null,
  isAdmin: false,
  notFound: false,
  organization: {},
  ...initialApiResponse,
};

export const ACTION_TYPE = {
  SET_USER: 'setUser',
  SET_USER_ERROR: 'setUserError',
  SET_USER_NOT_FOUND: 'setUserNotFound',
};

function reducer(state: IState, action: IAction) {
  switch (action.type) {
    case ACTION_TYPE.SET_USER:
      return { ...state, ...action.payload };
    case ACTION_TYPE.SET_USER_ERROR:
      return { ...initialState, error: true };
    case ACTION_TYPE.SET_USER_NOT_FOUND:
      return { ...initialState, loaded: true, notFound: true };
    default:
      throw new Error();
  }
}

let globalDispatch: any;

const useUserImpl = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  globalDispatch = dispatch;
  return state;
};

export const dispatch = (action: IAction) => globalDispatch(action);
export const useUserData = singletonHook(initialState, useUserImpl);
