/* eslint-disable */
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';

import ModalBackButton from './ModalBackButton';
import ModalCloseButton from './ModalCloseButton';
import './dialog.less';

const propTypes = {
  /**
   * Header top level class name
   */
  className: PropTypes.string,
  /**
   * Header title.
   * Can be either string or an intl message obj
   */
  title: PropTypes.string,
  /**
   * Method hook.
   * Called when the user requests to close the modal via the header.
   */
  onClose: PropTypes.func,
  /**
   * Method hook.
   * If provided a back button will be added to the modal.
   * Method will be called for every user click.
   */
  onBack: PropTypes.func,
};

type ModalHeaderType = InferProps<typeof propTypes>;

const defaultProps: ModalHeaderType = {
  title: '',
  onBack: null,
  className: null,
  onClose: null,
};

/**
 * Simple header for BkmdModal
 */
export default class ModalHeaderComponent extends React.Component<ModalHeaderType> {
  static propTypes = propTypes;

  static defaultProps = defaultProps;

  render() {
    const { className, onBack, onClose, title } = this.props;
    return (
      <div className={classNames('dialog-header', className)}>
        {!onBack ? null : <ModalBackButton onBack={onBack} />}
        {title && <h1 className="modal-title font-color-brand-main">{title}</h1>}
        {!onClose ? null : (
          <ModalCloseButton className="close-icon pull-right" handleClose={onClose} />
        )}
        {this.props.children}
      </div>
    );
  }
}
