import React from 'react';
import { Link } from 'react-router-dom';
import './styles.less';

const NotFound = () => {
  return (
    <div className="block-404">
      <h1>Whoops!</h1>
      <h4>We can’t seem to find the page that you’re looking for.</h4>
      <Link className="btn" to="/">
        Go back
      </Link>
    </div>
  );
};

export default NotFound;
