import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ZoomIn from '@mui/icons-material/ZoomIn';
import MaterialTable, { Column, Query, QueryResult } from 'material-table';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { styles } from './style';
import { JsonDialog } from './dialog/JsonDialog';
import { Audit as IAudit, AuditOrderType } from '../../types';
import useApi from '../../hooks/useApi';
import './styles.less';
import { tableIcons } from '../../assets/icons/material-table-icons';

const AUDIT_LIMIT = 10;

export const AuditList = () => {
  const EMPTY_TABLE: QueryResult<IAudit> = { data: [], page: 0, totalCount: 0 };

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>('actionName');

  const [isNewSearch, setIsNewSearch] = useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState();
  const [dialogTitle, setDialogTitle] = useState<string>('');

  const { getAuditLogs } = useApi();

  const materialTableRef = useRef(null);

  useEffect(() => {
    if (!dialogOpen) {
      setDialogData(undefined);
      setDialogTitle('');
    }
  }, [dialogOpen]);

  const dialogOpenHandler = (json: any, title: string) => {
    setDialogTitle(title);
    setDialogData(json);
    setDialogOpen(true);
  };

  const getColumns = () => {
    const columns: any = [];

    columns.push(
      {
        title: 'Created (UTC)',
        field: 'createdAt',
        render: (audit: IAudit) => (
          <div style={{ textAlign: 'center' }}>
            {moment(audit.createdAt).utc().format('MM/DD/YYYY - HH:mm:ss')}
          </div>
        ),
      },
      {
        title: 'Action Name',
        field: 'actionName',
        render: (audit: IAudit) => <div>{audit.actionName}</div>,
      },
      {
        title: 'Acting User',
        field: 'actingUser',
        render: (audit: IAudit) => <div>{audit.actingUser}</div>,
      },
      {
        title: 'Acting Role',
        field: 'actingRole',
        render: (audit: IAudit) => <div>{audit.actingRole}</div>,
      },
      {
        title: 'Affected Entity',
        field: 'affectedEntity',
        sorting: false,
        render: (audit: IAudit) => (
          <IconButton
            onClick={() => dialogOpenHandler(audit.affectedEntity, 'Affected Entity')}
            size="small"
          >
            <ZoomIn />
            View
          </IconButton>
        ),
      },
      {
        title: 'Affected ID',
        sorting: false,
        render: (audit: IAudit) => <div>{audit.affectedEntity.id}</div>,
      },
      {
        title: 'Source IP',
        field: 'sourceIp',
        sorting: false,
      },
      {
        title: 'Action Result',
        field: 'actionResult',
      },
    );

    return columns.filter((column) => !!column) as Column<any>[];
  };

  const parseAudit = (postAuditLog: IAudit[]): IAudit[] => {
    return postAuditLog?.map((auditLog: IAudit) => {
      const { affectedFields } = auditLog.affectedEntity;

      const affectedFieldsNew = affectedFields?.map((affectedField) => {
        const newValue = affectedField?.newValue ?? null;
        const previousValue = affectedField?.previousValue ?? null;

        return { ...affectedField, newValue, previousValue };
      });
      return {
        ...auditLog,
        affectedEntity: { ...auditLog.affectedEntity, affectedFields: affectedFieldsNew },
      };
    });
  };

  const getTableData = async (query: Query<IAudit>): Promise<QueryResult<IAudit>> => {
    try {
      const { page, pageSize: limit, orderDirection, orderBy } = query;

      const {
        data,
        meta: { total },
      } = await getAuditLogs({
        offset: page * AUDIT_LIMIT,
        limit,
        search: searchQuery,
        searchBy,
        order: (orderDirection.toUpperCase() as AuditOrderType) || 'DESC',
        sort: orderBy?.field || 'createdAt',
      });

      const audits = data;

      const parsedAudits = parseAudit(audits);

      setIsNewSearch(false);

      if (isEmpty(audits)) {
        return EMPTY_TABLE;
      }

      return {
        data: parsedAudits,
        totalCount: total,
        page: isNewSearch ? 0 : page,
      };
    } catch (error) {
      return EMPTY_TABLE;
    }
  };

  const onQueryChange = () => (materialTableRef.current as any).onQueryChange();

  return (
    <>
      <Box sx={styles.box}>
        <TextField
          label="Search for Audits"
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setIsNewSearch(true);
              onQueryChange();
            }
          }}
          value={searchQuery}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
          sx={styles.flexGrow}
        />
        <FormControl variant="outlined" sx={styles.flexGrow}>
          <InputLabel id="search-by">Search by</InputLabel>
          <Select
            className="searchBySelect"
            labelId="search-by"
            label="Search by"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value as string)}
          >
            <MenuItem value="actionName">Action Name</MenuItem>
            <MenuItem value="actingUser">Acting User</MenuItem>
            <MenuItem value="actingRole">Acting Role</MenuItem>
            <MenuItem value="affectedID">Affected ID</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          sx={styles.button}
          color="secondary"
          onClick={() => {
            setSearchBy('actionName');
            setSearchQuery('');
            setIsNewSearch(true);
            onQueryChange();
          }}
        >
          Reset
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<SearchIcon />}
          sx={styles.button}
          onClick={() => {
            setIsNewSearch(true);
            onQueryChange();
          }}
        >
          Search
        </Button>
      </Box>
      <MaterialTable
        icons={tableIcons}
        columns={getColumns()}
        data={getTableData}
        tableRef={materialTableRef}
        options={{
          pageSize: 10,
          sorting: true,
          pageSizeOptions: [],
          search: false,
          showTitle: false,
          draggable: false,
          toolbar: false,
          headerStyle: { backgroundColor: '#8e8c97', color: '#FFF' },
        }}
      />
      <JsonDialog
        open={dialogOpen}
        data={dialogData}
        title={dialogTitle}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default AuditList;
