import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { ImageGalleryModal } from './imageGallery';

export const ImageUploader = ({
  updateFormData,
  formData,
  currentProfilePicture,
  setCurrentProfilePicture,
  avatars,
}) => {
  useEffect(() => {
    updateFormData({
      field: 'profilePicture',
      value: currentProfilePicture.imageURL,
    });
  }, [currentProfilePicture]);

  const [isPhotoUploadModalOpen, setPhotoUploadModalOpen] = useState(false);

  const handleUploadClick = () => {
    setPhotoUploadModalOpen(true);
  };

  const handleRemovePicture = () => {
    updateFormData({
      field: 'profilePicture',
      value: null,
    });
    setCurrentProfilePicture({ file: null, imageURL: null });
  };

  return (
    <>
      <div>
        <div className="clean-input-label">Profile picture</div>
        <label htmlFor="" className="provider-avatar-label">
          {formData.profilePicture ? (
            <>
              <div className="provider-avatar" onClick={handleUploadClick}>
                <img src={formData.profilePicture} alt="" />
              </div>
              <DeleteIcon
                className="upload-image-modal__remove-icon"
                onClick={handleRemovePicture}
              />
            </>
          ) : (
            <Button variant="outline-info" onClick={handleUploadClick}>
              Upload
            </Button>
          )}
        </label>
      </div>
      {isPhotoUploadModalOpen && (
        <ImageGalleryModal
          isOpen={isPhotoUploadModalOpen}
          onClose={() => setPhotoUploadModalOpen(false)}
          currentProfilePicture={currentProfilePicture}
          setCurrentProfilePicture={setCurrentProfilePicture}
          avatars={avatars}
        />
      )}
    </>
  );
};
