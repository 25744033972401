import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import './styles.less';

export const PAGINATOR_INITIAL_PAGE = 1;

export interface PaginatorProps {
  className?: string;
  page?: number;
  pagesAmount: number;
  onPageChange(page: number): void;
  disabled?: boolean;
  displayOnOnePage?: boolean;
}

const Paginator: FC<PaginatorProps> = ({
  pagesAmount,
  page: pageProp,
  onPageChange,
  className,
  disabled,
  displayOnOnePage = false,
}) => {
  const [pageState, setPageState] = useState(1);

  const page = pageProp ?? pageState;

  useEffect(() => {
    if (pageProp) {
      setPageState(pageProp);
    }
  }, [pageProp]);

  const goPrevious = () => {
    setPageState((currentPage) => {
      onPageChange(currentPage - 1);
      return currentPage - 1;
    });
  };

  const goNext = () => {
    setPageState((currentPage) => {
      onPageChange(currentPage + 1);
      return currentPage + 1;
    });
  };

  const shouldBeDisplayed = displayOnOnePage || pagesAmount > 1;

  return (
    <div className={classNames('paginator', className)}>
      {shouldBeDisplayed && (
        <>
          <button
            disabled={disabled || page === PAGINATOR_INITIAL_PAGE}
            onClick={goPrevious}
            type="button"
          >
            <i className="icon-chevron-left" />
          </button>
          <div className="label">
            {page} out of {pagesAmount}
          </div>
          <button disabled={disabled || page >= pagesAmount} onClick={goNext} type="button">
            <i className="icon-chevron-right" />
          </button>
        </>
      )}
    </div>
  );
};

export default Paginator;
