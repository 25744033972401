import React, { useEffect, useState, useRef } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';

import './styles.less';

type SubjectiveProps = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  isApptLocked: boolean;
};

const GENERAL_NOTES_KEY = 'subjective_general_notes';
const CHIEF_COMPLAINT_KEY = 'subjective_chief_complaint';
const HPI_NOTES_KEY = 'subjective_hpi';
const ROS_NOTES_KEY = 'subjective_ros';

const Subjective: React.FC<SubjectiveProps> = ({ selectedAppointment, isApptLocked }) => {
  const [hpiSubjective, setHpiSubjective] = useState<undefined | string>();
  const [rosSubjective, setRosSubjective] = useState<undefined | string>();
  const [generalNotesSubjective, setGeneralNotesSubjective] = useState<undefined | string>();
  const [chiefComplaintSubjective, setChiefComplaintSubjective] = useState<undefined | string>();

  let typingTimeout = useRef(null);

  const timeouts = useRef({
    [GENERAL_NOTES_KEY]: null,
    [CHIEF_COMPLAINT_KEY]: null,
    [HPI_NOTES_KEY]: null,
    [ROS_NOTES_KEY]: null,
  });

  const api = useApi();

  const saveMetadataField = (key, value) => {
    //@ts-ignore
    clearTimeout(timeouts.current[key]);

    //@ts-ignore
    timeouts.current[key] = setTimeout(() => {
      api.upsertMetadata(selectedAppointment.id, { key, value });
    }, 3000);
  };

  const handleHpiSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setHpiSubjective(value);

    saveMetadataField(HPI_NOTES_KEY, value);
  };
  const handleRosSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setRosSubjective(value);

    saveMetadataField(ROS_NOTES_KEY, value);
  };
  const handleGeneralNotesSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setGeneralNotesSubjective(value);

    saveMetadataField(GENERAL_NOTES_KEY, value);
  };
  const handleChiefComplaintSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setChiefComplaintSubjective(value);

    saveMetadataField(CHIEF_COMPLAINT_KEY, value);
  };

  const parseSubjective = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      switch (key) {
        case GENERAL_NOTES_KEY:
          setGeneralNotesSubjective(value);
          break;
        case CHIEF_COMPLAINT_KEY:
          setChiefComplaintSubjective(value);
          break;
        case HPI_NOTES_KEY:
          setHpiSubjective(value);
          break;
        case ROS_NOTES_KEY:
          setRosSubjective(value);
          break;
      }
    });
  };

  useEffect(() => {
    getSubjective();
  }, [selectedAppointment]);

  const getSubjective = async () => {
    if (selectedAppointment) {
      parseSubjective(selectedAppointment.metadata);
    }
  };

  return (
    <Box className="text-area-wrapper-v2" display="flex" flexDirection="column" flexGrow={1}>
      <span className="main-section-title">Subjective</span>
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          data-type-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={2}
          maxRows={3}
          placeholder="Enter notes"
          value={generalNotesSubjective}
          disabled={isApptLocked}
          onChange={handleGeneralNotesSubjective}
        />
      </Box>

      <Box>
        <div className="title">
          <span>Chief Complaint</span>
        </div>
        <TextareaAutosize
          data-test-id="chief-compaint-text"
          data-type-id="chief-compaint-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={1}
          maxRows={2}
          placeholder="Enter"
          value={chiefComplaintSubjective}
          disabled={isApptLocked}
          onChange={handleChiefComplaintSubjective}
        />
      </Box>

      <Box>
        <div className="title">
          <span>History of Present Illness notes</span>
        </div>
        <TextareaAutosize
          data-test-id="hpi-text"
          data-type-id="hpi-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={2}
          maxRows={2}
          value={hpiSubjective}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleHpiSubjective}
        />
      </Box>
      <Box>
        <div className="title">
          <span>Review of Systems notes</span>
        </div>
        <TextareaAutosize
          data-test-id="ros-text"
          data-type-id="ros-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={2}
          maxRows={2}
          placeholder="Enter notes"
          value={rosSubjective}
          disabled={isApptLocked}
          onChange={handleRosSubjective}
        />
      </Box>
    </Box>
  );
};

export default Subjective;
