import cssFilterConverter from 'css-filter-converter';

export const hexToFilter = (hex: string) => {
  const base = hex.slice(0, 7);
  const opacity = hex.slice(7);
  const filter = cssFilterConverter.hexToFilter(base);
  if (filter.error || !filter.color) {
    return '';
  }
  if (opacity) {
    return filter.color?.concat(` opacity(${opacity}%)`);
  }
  return filter.color;
};
