import React, { useEffect, useState } from 'react';
import { Toast } from '@getvim/atomic-ui';
import Loader from '../loader';
import './styles.less';
import { Button, Table } from 'react-bootstrap';
import { SelectTypeahead } from '../select-typeahead';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import { ItemModal } from '../problems-modal/itemModal';
import useApi from '../../hooks/useApi';
import moment from 'moment';
import { Patient, PatientProblem, PatientProblemStatus } from '../../types';
import Formatter from '../../utils/formatter';

const { ToastTypes, createToast } = Toast;

const ProblemsList = ({ patient }: { patient: Patient }) => {
  const api = useApi();

  const [isCreateProblemModalOpen, setCreateProblemModalOpenStatus] = useState(false);
  const [activeProblems, setActiveProblems] = useState<PatientProblem[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getActiveProblemList();
  }, []);

  const getActiveProblemList = async () => {
    const status = [
      PatientProblemStatus.Active,
      PatientProblemStatus.Stable,
      PatientProblemStatus.Remission,
    ];
    const data = await api.getPatientProblems(patient.id, { status });
    setLoading(false);
    setActiveProblems([...data]);
  };

  const openModal = () => {
    setCreateProblemModalOpenStatus(true);
  };
  const closeModal = () => {
    setCreateProblemModalOpenStatus(false);
    getActiveProblemList();
  };

  const removeItem = async (item: any) => {
    setLoading(true);
    await api.deletePatientProblem({ patientId: patient.id, id: item.id });
    getActiveProblemList();
  };

  const onUpdateProblemStatus = async (item, status: PatientProblemStatus) => {
    try {
      setLoading(true);
      await api.updatePatientProblem(patient.id, item.id, { status });
      getActiveProblemList();
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.statusCode != '400' ? 'Something went wrong' : err.error?.message?.[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const problemStatusOptions = Object.values(PatientProblemStatus).map(function (status) {
    return { name: status };
  });

  return (
    <div className="problem-list-content">
      <div className="headerb">
        <div className="headerb__title"> Problems List </div>
        <Button bsPrefix="btn btn-primary btn-remove" onClick={openModal}>
          + New Problem
        </Button>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <Table striped borderless hover>
          <thead>
            <tr>
              <th className="column__label">ICD</th>
              <th>Description</th>
              <th>Notes</th>
              <th className="column__status">Status</th>
              <th className="column__data">Added On</th>
              <th className="column__data">Modified On</th>
              <th>Modified By</th>
              <th className="column__remove-icon" />
            </tr>
          </thead>
          <tbody>
            {activeProblems.map((item: PatientProblem) => {
              const selectedStatus = [
                problemStatusOptions.find((option) => option.name === item.status),
              ];
              const providerName = `${item.notes[0]?.provider?.firstName} ${item.notes[0]?.provider?.lastName}`;
              return (
                <>
                  <tr key={item.id}>
                    <td>{item.taxonomy?.label}</td>
                    <td>
                      <div className="wrapped">
                        <span title={Formatter.toSentenceCase(item.taxonomy?.description)}>
                          {Formatter.toSentenceCase(item.taxonomy?.description)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="wrapped">
                        <span title={item.notes[0]?.notes}>{item.notes[0]?.notes} </span>
                      </div>
                    </td>
                    <td>
                      <SelectTypeahead
                        labelKey="name"
                        disableFilter
                        // @ts-ignore
                        dropup
                        inputProps={{ readOnly: true }}
                        options={problemStatusOptions}
                        renderMenuItemChildren={(name: React.ReactNode) => {
                          return <div>{name}</div>;
                        }}
                        onChange={(option: { name: PatientProblemStatus }[]) => {
                          if (selectedStatus[0]?.name == option[0].name) return;
                          onUpdateProblemStatus(item, option[0].name);
                        }}
                        description=""
                        className="status-selector"
                        error={undefined}
                        selected={selectedStatus}
                      />
                    </td>
                    <td>{moment(item.notes.at(-1)?.createdAt).format('DD/MM/YYYY')}</td>
                    <td>{moment(item.notes.at(0)?.createdAt).format('DD/MM/YYYY')}</td>
                    <td>{providerName}</td>
                    <td className="column__remove-icon">
                      <IconTrash className="trash-icon" onClick={() => removeItem(item)} />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      )}
      {isCreateProblemModalOpen ? (
        <ItemModal patient={patient} onClose={closeModal} onSave={() => {}} />
      ) : null}
    </div>
  );
};

export default ProblemsList;
