import { withAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const connection = 'vim-console-saml-idp';
const IdpInitiatedLogin = (props: {
  auth0: { loginWithRedirect?: any; isAuthenticated?: any };
}) => {
  useEffect(() => {
    props.auth0.loginWithRedirect({
      connection,
      redirect_uri: window.location.origin,
    });
  }, []);

  return null;
};

export default withAuth0(IdpInitiatedLogin);
