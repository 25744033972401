import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import './styles.less';

interface DateInputProps {
  ref?: any;
  error: any;
  label: string;
  submitted?: boolean;
}

export const DateInput = forwardRef((props: DateInputProps, ref: any) => {
  const { error, submitted, label } = props;

  return (
    <div className={`${error && submitted && 'has-error'} date`} ref={ref}>
      <span className="date-title">{label}</span>
      <DatePicker {...(props as any)} dateFormat="dd/MM/yyyy" />
    </div>
  );
});
