import React from 'react';
import { Toast } from '@getvim/atomic-ui';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import Router from './Router';
import RouterV2 from './RouterV2';
import AuthProvider from './components/auth-provider';
import { ClientConfigProvider } from './config/useClientConfig';

import './App.less';
import { GlobalContext } from './contexts/global';
import { SdkWrapper } from './SdkWrapper';
import { useUserData } from './hooks/useUserData';

const { Slide, ToastContainer } = Toast;

function App() {
  const user = useUserData();

  const [enableImageUploader] = useFeatureFlag({
    flagName: 'mock_ehr.enableImageUploader',
    defaultValue: false,
  });

  const [enableUserAutoProvisioning, isLoadingUserAutoProvisioningFlag] = useFeatureFlag({
    flagName: 'mock_ehr.enableUserAutoProvisioning',
    defaultValue: false,
    team: Team.VimOs,
  });

  const [useUpdatedV2OnRegularRoutes] = useFeatureFlag({
    flagName: 'mock_ehr.useUpdatedV2OnRegularRoutes',
    defaultValue: false,
    team: Team.Interfaces,
    flagContext: {
      vimUserEmail: user.email,
    },
  });

  return (
    <ClientConfigProvider>
      <AuthProvider>
        <GlobalContext.Provider
          value={{
            enableImageUploader,
            enableUserAutoProvisioning,
          }}
        >
          <SdkWrapper>
            <ToastContainer
              className="toast"
              position="bottom-right"
              hideProgressBar
              autoClose={3000}
              transition={Slide}
            />
            {!isLoadingUserAutoProvisioningFlag && !useUpdatedV2OnRegularRoutes && <Router />}
            {useUpdatedV2OnRegularRoutes && <RouterV2 />}
          </SdkWrapper>
        </GlobalContext.Provider>
      </AuthProvider>
    </ClientConfigProvider>
  );
}

export default App;
