import React, { useRef, useEffect, useState, useCallback } from 'react';
import './index.less';
import Tooltip, { TooltipProps } from '../tooltip';

type EllipsisTextProps = {
  text?: string;
  className?: string;
} & TooltipProps;

const EllipsisText: React.FC<EllipsisTextProps> = ({
  text,
  className,
  referenceClassName,
  ...other
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const { offsetWidth, scrollWidth } = textRef.current;
        setIsTruncated(scrollWidth > offsetWidth);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [text]);

  const handleMouseEnter = useCallback(() => {
    if (isTruncated) {
      setShowTooltip(true);
    }
  }, [isTruncated]);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <>
      <div
        ref={textRef}
        className="ellipsis-text-box"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {isTruncated && showTooltip ? (
          <Tooltip
            {...other}
            className={className}
            referenceClassName="ellipsis-text-box"
            tooltipContent={text}
            portal
          >
            {text}
          </Tooltip>
        ) : (
          <>{text}</>
        )}
      </div>
    </>
  );
};

export default EllipsisText;
