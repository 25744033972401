import React from 'react';
import { Button } from 'react-bootstrap';

import './styles.less';

export const NewItemButton = ({
  handleClick,
  newItemTitle,
  disabled = false,
}: {
  handleClick: any;
  newItemTitle: string;
  disabled?: boolean;
}) => {
  return (
    <Button
      disabled={disabled}
      bsPrefix=""
      className="referrals-orders__new-item-btn"
      onClick={handleClick}
    >
      <i className="icon-plus-fat i-va-fix-2" />
      &nbsp;
      <span>{newItemTitle}</span>
    </Button>
  );
};
