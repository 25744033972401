import React, { useState, Fragment } from 'react';

import { isEmpty } from 'lodash-es';
import { ListItem } from './listItem';
import { ItemModal } from '../referral-order-modal/itemModal';
import { NewItemButton } from './newItemButton';
import { IListItem, IPatientProps } from './types';
import { ListType } from '../../types';

import './styles.less';

interface ListProps {
  patient: IPatientProps;
  items: any;
  listType: ListType;
  newItemTitle: string;
  modalTitle: string;
  prepareReferToText(referTo: IListItem['referTo']): string;
  handleDeleteItem(referralId: number): Promise<void>;
  handleCreateItem(referral: any): Promise<void>;
  handleUpdateItem(referralId: number, referral: any): Promise<void>;
}

export const List = ({
  patient,
  items,
  listType,
  newItemTitle,
  modalTitle,
  prepareReferToText,
  handleDeleteItem,
  handleCreateItem,
  handleUpdateItem,
}: ListProps) => {
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IListItem | null>(null);

  const isNewItem = !selectedItem;

  const openModal = () => {
    setItemModalOpen(true);
  };

  const itemModalToggle = () => {
    setItemModalOpen(!itemModalOpen);
  };

  const processListItems = (items: IListItem[], listType: ListType) => {
    if (isEmpty(items)) {
      return <div className="referrals-orders__list-no-results">No {listType} added</div>;
    }

    return items.map((item: IListItem) => {
      return (
        <Fragment key={item.id}>
          <ListItem
            item={item}
            prepareReferToText={prepareReferToText}
            handleDeleteItem={handleDeleteItem}
            handleSelect={(item) => {
              setSelectedItem(item);
              openModal();
            }}
          />
        </Fragment>
      );
    });
  };

  return (
    <div id={`${listType}-list`} className="referrals-orders__list-column">
      <div className="referrals-orders__list-header">
        <div className="referrals-orders__list-title-wrapper">
          <div className="referrals-orders__list-title" key={listType}>
            <span>Open {listType}</span>
          </div>
        </div>
        <div className="referrals-orders__new-item-wrapper">
          <NewItemButton handleClick={() => openModal()} newItemTitle={newItemTitle} />
        </div>
      </div>
      <div className="referrals-orders__list-wrapper">{processListItems(items, listType)}</div>
      {itemModalOpen && (
        <ItemModal
          patient={patient}
          modalTitle={modalTitle}
          newItemTitle={newItemTitle}
          isNewItem={isNewItem}
          isOpen={itemModalOpen}
          onUpdate={async (id, body) => {
            await handleUpdateItem(id, body);
          }}
          onSave={async (body) => {
            await handleCreateItem(body);
            setItemModalOpen(false);
            setSelectedItem(null);
          }}
          onClose={() => {
            itemModalToggle();
            setSelectedItem(null);
          }}
          listType={listType}
          referral={selectedItem}
        />
      )}
    </div>
  );
};
