import React, { useEffect, useState } from 'react';

import { debounce } from 'lodash-es';
import { Loader } from '@getvim/atomic-ui';
import { Provider } from '../../types';
import ProviderIcon from '../../assets/icons/icon-provider.svg';
import './styles.less';
import { useUserData } from '../../hooks/useUserData';
import ConfirmDeleteModal from '../confirm-delete-modal';
import Paginator from '../paginator';
import SelectDropdown from '../select-dropdown';
import SearchInput from '../search-input';
import { IconTrash } from '../../assets/icons';

const PROVIDERS_LIMIT = 10;

interface ProviderItem extends Provider {
  me?: boolean;
}

interface ProvidersListProps {
  providers: { data: ProviderItem[]; total: number };
  organizations: { id: number; alias: string; name: string }[];
  isLoading: boolean;
  handleSelect(item: Provider): void;
  activePage: number;
  setActivePage(page: number): void;
  getProviders: ({
    offset,
    limit,
    search,
  }: {
    offset: number;
    limit: number;
    search?: string;
  }) => void;
  deleteProvider(id: string): void;
  setFilter(text: string): void;
  setOrganizationIdFilter(id: number): void;
}

const ProvidersList = (props: ProvidersListProps) => {
  const {
    providers,
    isLoading = true,
    organizations,
    handleSelect,
    activePage,
    setActivePage,
    getProviders,
    deleteProvider,
    setOrganizationIdFilter,
    setFilter,
  } = props;

  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [providerToBeDeleted, setProviderToBeDeleted] = useState<Provider | null>(null);

  const ALL_ORGANIZATIONS_OPTION = {
    name: 'All organizations',
  };

  const [initialOptions, setInitialOptions] = useState<any[]>([]);
  const [searchOrganization, setSearchOrganization] = useState<any>(ALL_ORGANIZATIONS_OPTION);

  const { isAdmin } = useUserData();

  useEffect(() => {
    if (deleteConfirmed && providerToBeDeleted) {
      deleteProvider(providerToBeDeleted.id);
      setProviderToBeDeleted(null);
      setDeleteConfirmed(false);
    }
  }, [deleteConfirmed]);

  useEffect(() => {
    const initialOptions = [ALL_ORGANIZATIONS_OPTION, ...organizations];
    setInitialOptions(initialOptions);
  }, [organizations]);

  const getPagesAmount = (itemsAmount: number, pageSize: number): number => {
    const remainder = itemsAmount % pageSize;
    const fullPagesAmount = (itemsAmount - remainder) / pageSize;

    return fullPagesAmount + (remainder ? 1 : 0);
  };

  const debouncedSetFilter = debounce((value?: string) => {
    setFilter(value || '');
  }, 1000);

  const onChange = (newOptions) => {
    if (newOptions?.length) {
      setSearchOrganization(newOptions?.[0]);
      setOrganizationIdFilter(newOptions?.[0]?.id);
    }
  };

  return (
    <div className="providers-list">
      {isLoading && <Loader type="dots" size="small" label="Loading" />}

      <div className="filters-block">
        <SelectDropdown
          key={searchOrganization?.id}
          valueField="id"
          labelField="name"
          label="Organization"
          options={initialOptions}
          onChange={onChange}
          selectedOption={searchOrganization}
          highlightValueOnFocus
        />
        <SearchInput
          placeholder="Type provider name"
          handleChange={(event) => debouncedSetFilter(event.target?.value)}
        />
      </div>

      {!isLoading && Boolean(providers.data?.length) && (
        <>
          <div className="providers-list-titles">
            <div>Name</div>
            <div>Gender</div>
            <div>Organization</div>
          </div>
          {providers.data.map((provider: ProviderItem) => {
            const {
              id,
              fullName,
              gender,
              profilePicture,
              me,
              organization: { name: orgName },
            } = provider;

            return (
              <div
                onClick={() => {
                  handleSelect(provider);
                }}
                className="provider-item card-style-item"
                key={`provider-${id}`}
              >
                <div className="provider-avatar">
                  <img
                    src={profilePicture || ProviderIcon}
                    alt=""
                    className={profilePicture ? 'patient-img' : 'default-img'}
                  />
                </div>
                <div className="provider-text-side">
                  <div>
                    <h4 className="provider-title__text">
                      {fullName}
                      {me ? ' (Me)' : ''}
                    </h4>
                  </div>
                  <div className="gender">{gender}</div>
                  <div className="organization">{orgName}</div>
                </div>
                <div className="actions">
                  {!me && isAdmin && (
                    <>
                      <div className="card-style-action-btn icon-delete">
                        <IconTrash
                          className=""
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setProviderToBeDeleted(provider);
                            setConfirmDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
          <Paginator
            pagesAmount={getPagesAmount(providers.total, PROVIDERS_LIMIT)}
            page={Number(activePage)}
            onPageChange={(newPageNumber) => {
              getProviders({
                offset: (newPageNumber - 1) * PROVIDERS_LIMIT,
                limit: PROVIDERS_LIMIT,
              });
              setActivePage(newPageNumber);
            }}
          />
        </>
      )}
      {confirmDeleteModalOpen && providerToBeDeleted && (
        <ConfirmDeleteModal
          dataToBeDeleted={providerToBeDeleted.fullName}
          onConfirmed={() => {
            setDeleteConfirmed(true);
            setConfirmDeleteModalOpen(false);
          }}
          onClose={() => setConfirmDeleteModalOpen(false)}
          isOpen={confirmDeleteModalOpen}
          closeOnBackdrop={false}
        />
      )}
    </div>
  );
};

export default ProvidersList;
