import React, { useState } from 'react';
import { Toast } from '@getvim/atomic-ui';
import { Organization } from '../../types';
import SearchInput from '../search-input';
import ConfirmDeleteModal from '../confirm-delete-modal';
import { IconTrash } from '../../assets/icons';
import { debounce } from 'lodash-es';
import { useUserData } from '../../hooks/useUserData';
import useApi from '../../hooks/useApi';

import './styles.less';

const { ToastTypes, createToast } = Toast;

const OrganizationsList = ({
  organizationsList,
  onSelect,
  setFilter,
  isLoading,
  onDelete,
}: {
  organizationsList: Organization[];
  onSelect: (organization: Organization) => void;
  setFilter: (filter: string) => void;
  isLoading: boolean;
  onDelete: () => void;
}) => {
  const {
    organization: { id: currentOrganizationId },
  } = useUserData();

  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [organizationToBeDeleted, setOrganizationToBeDeleted] = useState<Organization | null>(null);

  const api = useApi();

  const debouncedSetFilter = debounce((value?: string) => {
    setFilter(value || '');
  }, 1000);

  const handleDelete = async (orgId: number) => {
    try {
      // @ts-ignore
      await api.deleteOrganization(orgId);
      onDelete();
    } catch (error) {
      createToast({
        title: 'Error!',
        message: `Oops, error! Make sure organization is not used by providers.`,
        type: ToastTypes.ERROR,
        html: true,
      });
    }
  };

  return (
    <>
      <div className="organizations-list-content-wrap">
        <SearchInput
          placeholder="Type organization name"
          handleChange={(event) => debouncedSetFilter(event.target?.value)}
        />

        {!isLoading && Boolean(organizationsList?.length) && (
          <>
            <div className="organizations-list-titles">
              <div>Organization Name</div>
              <div>ID</div>
              <div>Alias</div>
            </div>

            <div className="organizations-list" id="organizations">
              {organizationsList.map((organization: Organization) => {
                const { id, name, alias } = organization;

                return (
                  <div className="organization-item card-style-item" key={`organization-${id}`}>
                    <div
                      className="organization-details"
                      onClick={() => {
                        onSelect(organization);
                      }}
                    >
                      <div className="organization-text-side">
                        <h4 className="organization-title">{name}</h4>
                        <div className="id">{id}</div>
                        <div className="alias">{alias}</div>
                      </div>
                    </div>
                    {currentOrganizationId !== id && (
                      <div className="organization-title__action-btn">
                        <IconTrash
                          onClick={async (e: any) => {
                            e.preventDefault();
                            setOrganizationToBeDeleted(organization);
                            setConfirmDeleteModalOpen(true);
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {confirmDeleteModalOpen && organizationToBeDeleted && (
          <ConfirmDeleteModal
            dataToBeDeleted={`organization ${organizationToBeDeleted.name}`}
            onConfirmed={async () => {
              await handleDelete(organizationToBeDeleted.id);
              setOrganizationToBeDeleted(null);
              setConfirmDeleteModalOpen(false);
            }}
            onClose={() => setConfirmDeleteModalOpen(false)}
            isOpen={confirmDeleteModalOpen}
            closeOnBackdrop={false}
          />
        )}
      </div>
    </>
  );
};

export default OrganizationsList;
