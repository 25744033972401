import React from 'react';
import { BkmdModal, ModalHeader } from '@getvim/atomic-ui';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './styles.less';

const DEFAULT_WARN_MESSAGE = 'Please confirm your action, this cannot be undone!';

interface ConfirmDeleteModalProps {
  onConfirmed(): void;
  onClose(): void;
  isOpen: boolean;
  dataToBeDeleted?: string;
  warnMessage?: string | JSX.Element;
}

const ConfirmDeleteModal = ({
  warnMessage = 'Please confirm your action, this cannot be undone!',
  dataToBeDeleted,
  onConfirmed,
  isOpen,
  onClose,
}: ConfirmDeleteModalProps) => {
  return (
    <BkmdModal isOpen={isOpen} handleClose={onClose} autoFocus={false}>
      <div className="dialog-body">
        {dataToBeDeleted ? (
          <>
            You are about to delete
            <b>
              <i> {dataToBeDeleted}</i>
            </b>
            . {DEFAULT_WARN_MESSAGE}
          </>
        ) : (
          warnMessage
        )}
      </div>
      <div className="dialog-footer">
        <Row className="footer-btns">
          <Col xs={6}>
            <Button
              bsPrefix="btn btn-secondary"
              className="footer-btns__cancel-btn"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              type="submit"
              bsPrefix="btn btn-primary"
              className="footer-btns__confirm-btn"
              onClick={() => {
                onConfirmed();
              }}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </div>
    </BkmdModal>
  );
};

export default ConfirmDeleteModal;
