import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';
import Loader from '../loader';
import { Toast } from '@getvim/atomic-ui';
import { isString } from 'lodash-es';

type SubjectiveProps = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  onChange: (metadata: MetadataType[]) => void;
  isApptLocked: boolean;
};

const GENERAL_NOTES_KEY = 'subjective_general_notes';
const CHIEF_COMPLAINT_KEY = 'subjective_chief_complaint';
const HPI_NOTES_KEY = 'subjective_hpi';
const ROS_NOTES_KEY = 'subjective_ros';

const { ToastTypes, createToast } = Toast;

const Subjective: React.FC<SubjectiveProps> = ({
  selectedAppointment,
  loading,
  onChange,
  isApptLocked,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState({});
  const [hpiSubjective, setHpiSubjective] = useState<undefined | string>();
  const [rosSubjective, setRosSubjective] = useState<undefined | string>();
  const [generalNotesSubjective, setGeneralNotesSubjective] = useState<undefined | string>();
  const [chiefComplaintSubjective, setChiefComplaintSubjective] = useState<undefined | string>();

  const api = useApi();

  const handleHpiSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setHpiSubjective(event.target.value);
  };
  const handleRosSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setRosSubjective(event.target.value);
  };
  const handleGeneralNotesSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setGeneralNotesSubjective(event.target.value);
  };
  const handleChiefComplaintSubjective = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setChiefComplaintSubjective(event.target.value);
  };

  const parseSubjective = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      setInitialData((current) => {
        return {
          ...current,
          [key]: value,
        };
      });
    });
  };

  useEffect(() => {
    const data: MetadataType[] = [];
    if (isString(rosSubjective)) {
      data.push({ key: ROS_NOTES_KEY, value: rosSubjective });
    }
    if (isString(hpiSubjective)) {
      data.push({ key: HPI_NOTES_KEY, value: hpiSubjective });
    }
    if (isString(generalNotesSubjective)) {
      data.push({ key: GENERAL_NOTES_KEY, value: generalNotesSubjective });
    }
    if (isString(chiefComplaintSubjective)) {
      data.push({ key: CHIEF_COMPLAINT_KEY, value: chiefComplaintSubjective });
    }
    onChange(data);
  }, [rosSubjective, hpiSubjective, generalNotesSubjective, chiefComplaintSubjective]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    getSubjective();
  }, [selectedAppointment]);

  const getSubjective = async () => {
    if (selectedAppointment) {
      try {
        setLoading(true);
        const appointment = await api.getAppointment({ id: selectedAppointment.id });
        parseSubjective(appointment.metadata);
      } catch (err: any) {
        createToast({
          title: `Oops, error!`,
          message: err.error?.message[0],
          type: ToastTypes.ERROR,
          html: true,
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box className="text-area-wrapper" display="flex" flexDirection="column" flexGrow={1}>
      {isLoading && <Loader />}
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={3}
          placeholder="Enter notes"
          value={generalNotesSubjective ?? initialData[GENERAL_NOTES_KEY]}
          disabled={isApptLocked}
          onChange={handleGeneralNotesSubjective}
        />
      </Box>

      <Box>
        <div className="title">
          <span>Chief Complaint</span>
        </div>
        <TextareaAutosize
          data-test-id="chief-compaint-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={1}
          placeholder="Enter"
          value={chiefComplaintSubjective ?? initialData[CHIEF_COMPLAINT_KEY]}
          disabled={isApptLocked}
          onChange={handleChiefComplaintSubjective}
        />
      </Box>

      <Box>
        <div className="title">
          <span>History of Present Illness notes</span>
        </div>
        <TextareaAutosize
          data-test-id="hpi-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={3}
          value={hpiSubjective ?? initialData[HPI_NOTES_KEY]}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleHpiSubjective}
        />
      </Box>
      <Box>
        <div className="title">
          <span>Review of Systems notes</span>
        </div>
        <TextareaAutosize
          data-test-id="ros-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={3}
          placeholder="Enter notes"
          value={rosSubjective ?? initialData[ROS_NOTES_KEY]}
          disabled={isApptLocked}
          onChange={handleRosSubjective}
        />
      </Box>
    </Box>
  );
};

export default Subjective;
