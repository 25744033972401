import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface JsonDialogProps {
  title: string;
  data: any;
  open: boolean;
  onClose: () => void;
}

export const JsonDialog = ({ title, data, open, onClose }: JsonDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </Dialog>
  );
};
