import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from './pages-v2/dashboard';
import Patients from './pages-v2/patients';
import Providers from './pages-v2/providers';
import PatientProfile from './pages-v2/patient-profile';
import Organizations from './pages-v2/organizations';
import Login from './pages-v2/login';
import IdpInitiatedLogin from './pages-v2/idp-initiated-login';
import UserNotFound from './components-v2/user-not-found';
import NotFound from './pages-v2/not-found';
import Calendar from './pages-v2/calendar';
import Audit from './pages-v2/audit';
import DataVerification from './pages-v2/data-verification';
import Layout from './layout';
import PrivateRoute from './components-v2/private-route';
import LoadApiData from './hoks/loadApiData';
import { useUserData } from './hooks/useUserData';
import { UserRole } from './types/user';
import { getAccessToken } from './utils/local-storage';

const Index = (props: any) => {
  const { isLoading } = props;

  const user = useUserData();

  const alias = user?.organization?.alias;
  const isLoggedIn = !!getAccessToken();

  return (
    <BrowserRouter>
      {isLoading || (isLoggedIn && !alias) ? null : (
        <Layout>
          <Switch>
            <Route exact path="/idp-initiated-login" component={IdpInitiatedLogin} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute
              exact
              path="/providers"
              render={() => {
                return <Redirect to={`/${alias}/providers`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/patients"
              render={() => {
                return <Redirect to={`/${alias}/patients`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/calendar"
              render={() => {
                return <Redirect to={`/${alias}/calendar`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/organizations"
              render={() => {
                return <Redirect to={`/${alias}/organizations`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/patient/:id"
              render={(res: { match: { params: { id: any } } }) => {
                const { id } = res.match.params;
                return <Redirect to={`/${alias}/patient/${id}`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/audit"
              render={() => {
                return <Redirect to={`/${alias}/audit`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/data-verification"
              render={() => {
                return <Redirect to={`/${alias}/data-verification`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/"
              render={() => {
                return <Redirect to={`/${alias}`} />;
              }}
            />
            <PrivateRoute
              exact
              path="/:organizationAlias"
              render={() => {
                return <Dashboard />;
              }}
            />
            <PrivateRoute
              exact
              path="/:organizationAlias/providers"
              roles={[UserRole.ADMIN]}
              render={() => <Providers {...props} />}
            />
            <PrivateRoute exact path="/:organizationAlias/patients" render={() => <Patients />} />
            <PrivateRoute
              exact
              path="/:organizationAlias/audit"
              roles={[UserRole.ADMIN]}
              render={() => <Audit />}
            />
            <PrivateRoute
              exact
              path="/:organizationAlias/data-verification"
              roles={[UserRole.ADMIN, UserRole.DATA_ACCESS]}
              render={() => <DataVerification />}
            />
            <PrivateRoute exact path="/:organizationAlias/calendar" render={() => <Calendar />} />
            <PrivateRoute
              exact
              path="/:organizationAlias/organizations"
              roles={[UserRole.ADMIN]}
              render={() => <Organizations {...props} />}
            />
            <PrivateRoute
              exact
              path="/:organizationAlias/patient/:id"
              render={(res: { match: { params: { id: any } } }) => {
                const { id } = res.match.params;

                return <PatientProfile patientId={id} />;
              }}
            />
            <Route
              path="*"
              render={() => {
                return isLoggedIn ? <NotFound /> : <Redirect to="/login" />;
              }}
            />
          </Switch>
        </Layout>
      )}
      {!isLoading && user.notFound ? <UserNotFound /> : null}
    </BrowserRouter>
  );
};

export default LoadApiData(Index);
