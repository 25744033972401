import React from 'react';
import { LocationSelect } from '@getvim/atomic-ui';
import { logger } from '@getvim/scheduling-logger';
import useGoogleApi from '../../hooks/useGoogleApi';

import './styles.less';

interface LocationSelectWrapperProps {
  className: string;
  labelKey: string;
  description: string;
  error: any;
  submitted?: boolean;
  onChange: (event: any) => void;
  inputType: any;
  defaultAddressInput: string;
}

export const LocationSelectWrapper = (props: LocationSelectWrapperProps) => {
  const { className, defaultAddressInput, labelKey, description, error, submitted, onChange } =
    props;
  const isGoogleApiLoaded = useGoogleApi();

  return (
    <div id={labelKey} className={className}>
      <div
        className={` ${error && submitted && 'has-error'} freetext-input freetext-input-wrapper`}
      >
        <span className={`${className} clean-input-label`}>{description}</span>
        <LocationSelect
          requiredError={error}
          onChange={onChange}
          withState
          withCity
          isGoogleApiLoaded={isGoogleApiLoaded}
          placeholder=" "
          defaultAddressInput={defaultAddressInput}
          onEmptyAddressOptions={({ error, addressInput }) => {
            logger.error(`Failed to fetch google API suggestions: ${error}`, {
              clientName: 'mock-ehr',
              scope: 'google-api',
              metadata: {
                error,
                addressInput,
              },
            });
          }}
        />
      </div>
    </div>
  );
};
