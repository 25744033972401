import React, { FC, useState } from 'react';
import { hexToFilter } from '../../lib';

interface Props {
  iconUrl: string | undefined;
  fillColor?: string;
  alt?: string;
  className?: string;
}

const isDevPath = ['dev', 'staging'].includes(window.$vim_environment?.APP_ENV);

export const defaultAppIcon = `https://static${isDevPath ? '.dev' : ''}.getvim.com/${
  isDevPath ? 'dev' : 'prod'
}/vim-os-appstore/apps/icons/appDefaultIcon.svg`;
const defaultFillColor = '#001C36';

export const ApplicationSvgUrlIcon: FC<Props> = ({
  iconUrl,
  fillColor = defaultFillColor,
  alt = 'icon',
  className = '',
}) => {
  const [imageError, setImageError] = useState<unknown>();
  return (
    <img
      onError={setImageError}
      src={(!imageError && iconUrl) || defaultAppIcon}
      alt={alt}
      style={{ filter: hexToFilter(fillColor) }}
      className={className}
    />
  );
};
