export interface Provider {
  id: string;
  fullName: string;
  gender: string;
  dob: string;
  insurer: string;
  profilePicture: string;
  organization: {
    id: number;
    alias: string;
    name: string;
  };
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export enum UserRole {
  PROVIDER = 'PROVIDER',
  ADMIN = 'ADMIN',
}
export interface CreateProviderRequest {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  npi: string;
  phoneNumber: {
    number: string;
    countryDialingCode: string;
  };
  onBoarded: boolean;
  suffix: string;
  gender: Gender;
  clinics?: { id: number }[];
  role?: UserRole;
}

export const PROVIDER_ROLES = {
  PROVIDER: {
    label: 'Provider',
    value: 'PROVIDER',
  },
  DATA_ACCESS: {
    label: 'Data access',
    value: 'DATA_ACCESS',
  },
  ADMIN: {
    label: 'Admin',
    value: 'ADMIN',
  },
};
