import React, { useRef, useState, FunctionComponent } from 'react';
import './index.less';
import useAutosizeTextArea from './useAutosizeTextArea';

type AutosizeTextareaProps = {
  id?: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  onTextInputChange(value: string): void;
};

const AutosizeTextarea: FunctionComponent<AutosizeTextareaProps> = ({
  id,
  className,
  placeholder,
  maxLength,
  onTextInputChange,
  ...rest
}) => {
  const [value, setValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
    onTextInputChange(val);
  };

  return (
    <div className="autosize-textarea">
      <textarea
        className={className}
        id={id}
        onChange={handleChange}
        placeholder={placeholder}
        ref={textAreaRef}
        rows={1}
        maxLength={maxLength}
        value={value}
        {...rest}
      />
    </div>
  );
};

export default AutosizeTextarea;
