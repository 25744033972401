import React, { forwardRef } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';

import './styles.less';

interface MuiDatePickerProps {
  ref?: any;
  error: any;
  label: string;
  labelKey: string;
  submitted?: boolean;
  description: string;
  className: string;
  inputFormat: string;
}

// eslint-disable-next-line react/display-name
export const MuiDatePicker = forwardRef((props: MuiDatePickerProps, ref: any) => {
  const { error, submitted, className, description, labelKey, inputFormat } = props;

  return (
    <div id={labelKey} className={className}>
      <div
        className={` ${error && submitted && 'has-error'} freetext-input freetext-input-wrapper`}
      >
        <span className={`${className} clean-input-label`}>{description}</span>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            {...(props as any)}
            renderInput={(params: any) => {
              return (
                <TextField
                  {...params}
                  ref={ref}
                  variant="outlined"
                  className="text-field-input"
                  helperText={submitted && error}
                />
              );
            }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
});
