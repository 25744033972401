import { FeatureFlagsClient } from '@getvim/feature-flags';

export const featureFlagClient = new FeatureFlagsClient({});

const APP_NAME = 'mock_ehr';

export const editDeletePatientsForNonAdminFF = `${APP_NAME}.enable_edit_delete_patients_for_non_admins`;

export const enableLockUnlockApptFF = `${APP_NAME}.enable_lock_unlock_appointment`;

export const enableSaveApptAsPdfButton = `${APP_NAME}.enable_save_appt_as_pdf_button`;
