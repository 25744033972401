import React from 'react';
import { some as lodashSome, has } from 'lodash-es';
import Loader from '../components/loader';

export default (Component: React.FC) => {
  return (props: React.ComponentProps<any>): JSX.Element | null => {
    const isApiLoading = lodashSome(props, (item: any) => has(item, 'loaded') && !item.loaded);

    if (isApiLoading) {
      return <Loader />;
    }

    return <Component {...props} />;
  };
};
