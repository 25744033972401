import { UserRole } from '../types/user';

export function isLoggedIn() {
  // return authorization header with basic auth credentials
  const user = JSON.parse(localStorage.getItem('user') as string);

  return !!(user && user.authdata);
}

export function getUser() {
  return JSON.parse(localStorage.getItem('user') as string);
}

export function isUserAdmin({ role }: any) {
  return role === UserRole.ADMIN;
}
