import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.less';

export enum InputStyle {
  clean = 'clean',
  large = 'large',
  smallMedium = 'small-medium',
  medium = 'medium',
  pill = 'pill',
  pillSm = 'pill sm',
  pillMedium = 'pill-medium',
}

export enum LeftIconColorEnum {
  gray = 'gray',
}

const inputStyleWrapperProps = {
  className: PropTypes.string,
  inputStyle: PropTypes.oneOf(Object.values(InputStyle)),
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  rightIconOnClick: PropTypes.func,
  leftIconColor: PropTypes.oneOf(Object.values(LeftIconColorEnum)),
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hasValidationMessage: PropTypes.bool,
  numberInput: PropTypes.bool,
};

export type InputStyleWrapperPropsType = PropTypes.InferProps<typeof inputStyleWrapperProps>;

const InputStyleWrapper: React.FunctionComponent<InputStyleWrapperPropsType> = ({
  className,
  children,
  inputStyle,
  leftIcon,
  leftIconColor,
  rightIcon,
  rightIconOnClick,
  disabled,
  hasError,
  hasValidationMessage,
  numberInput,
}) => (
  <div
    className={classNames(
      'input-style-wrapper',
      { 'show-left-icon': leftIcon },
      { 'show-right-icon': rightIcon },
      { 'has-error': hasError },
      { 'has-validation-message': hasValidationMessage },
      { 'number-input': numberInput },
      inputStyle,
      disabled,
      className,
    )}
  >
    {leftIcon && <div className={classNames('input-left-icon', leftIconColor)}>{leftIcon}</div>}
    <div className="inputs">{children}</div>
    {rightIcon && (
      <div className="input-right-icon" {...(rightIconOnClick && { onClick: rightIconOnClick })}>
        {rightIcon}
      </div>
    )}
  </div>
);

InputStyleWrapper.propTypes = inputStyleWrapperProps;
InputStyleWrapper.defaultProps = {
  className: '',
  inputStyle: InputStyle.clean,
  rightIcon: undefined,
  rightIconOnClick: undefined,
  leftIcon: undefined,
  leftIconColor: undefined,
  disabled: false,
  hasError: false,
  hasValidationMessage: false,
  numberInput: false,
};

export default InputStyleWrapper;
