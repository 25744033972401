import React, { useState, useEffect } from 'react';
import { deprecated } from '@getvim/atomic-ui';
import Search from '../../components-v2/search';
import OrganizationsList from '../../components-v2/organizations-list';
import { NewOrganizationButton } from '../../components-v2/organizations-list/newOrganizationButton';
import OrganizationModal from '../../components-v2/organization-modal';
import UserHeaderInfo from '../../components-v2/user-header-info';
import NoResultsFound from '../../components-v2/no-results';
import Paginator from '../../components-v2/paginator';
import { Loader } from '@getvim/atomic-ui';
import WithApiData from '../../hoks/withApiData';

import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';
import { debounce } from 'lodash-es';

import { Organization } from '../../types';

import './styles.less';

const ORGANIZATIONS_LIMIT = 10;

const Organizations = (props: any) => {
  const { fetchUserData } = props;
  const [activePage, setActivePage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isOrganizationModalOpen, setIsOrganizationModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization>();
  const [organizations, setOrganizations] = useState<{
    data: Organization[];
    meta: { total: number };
  }>({
    data: [],
    meta: { total: 0 },
  });
  const [filter, setFilter] = useState<string>('');

  const isNewItem = !selectedOrganization;
  const api = useApi();
  const user = useUserData();

  const getLimitAndSkip = (): { skip: number; limit: number } => {
    return { skip: (activePage - 1) * ORGANIZATIONS_LIMIT, limit: ORGANIZATIONS_LIMIT };
  };

  const getOrganizations = async ({
    offset,
    limit,
    search,
  }: {
    offset?: number;
    limit?: number;
    search?: string;
  }) => {
    const LimitOffsetQuery = !offset && !limit ? getLimitAndSkip() : { offset, limit };
    setIsLoading(true);

    const organizationsData = await api.getOrganizationsList({
      ...LimitOffsetQuery,
      search,
      sort: 'desc(updated_at)',
    });
    setOrganizations(organizationsData);
    setIsLoading(false);
  };

  useEffect(() => {
    debouncedGetOrganizations();
  }, [filter]);

  const debouncedGetOrganizations = debounce(() => {
    getOrganizations({ search: filter });
  }, 500);

  const getPagesAmount = (itemsNumber: number, pageSize: number): number => {
    if (itemsNumber === pageSize) return 0;

    const pageNumberNoRemainder = Math.floor(itemsNumber / pageSize);
    const remainder = itemsNumber % pageSize;

    return pageNumberNoRemainder + (remainder ? 1 : 0);
  };

  return (
    <div className="organizations-list gray-bg">
      <div className="header-content-v2">
        <Search />
        <UserHeaderInfo name={user.firstName} />
      </div>
      <div className="organizations-page__list-header">
        <h1 className="dashboard-title">Organizations</h1>
        <div>
          <NewOrganizationButton
            handleClick={() => {
              setIsOrganizationModalOpen(true);
            }}
          />
        </div>
      </div>

      {isLoading && <Loader type="dots" size="small" label="Loading" />}

      {organizations && (
        <div className="organizations-list-content-wrap">
          <OrganizationsList
            organizationsList={organizations.data}
            onSelect={(organization: Organization) => {
              setSelectedOrganization(organization);
              setIsOrganizationModalOpen(true);
            }}
            setFilter={setFilter}
            isLoading={isLoading}
            onDelete={() => {
              getOrganizations({});
            }}
          />
        </div>
      )}

      {!isLoading && !organizations.data?.length && <NoResultsFound searchItem="organizations" />}

      {!isLoading && (
        <Paginator
          pagesAmount={getPagesAmount(organizations.meta.total, ORGANIZATIONS_LIMIT)}
          page={Number(activePage)}
          onPageChange={(newPageNumber) => {
            getOrganizations({
              offset: (newPageNumber - 1) * ORGANIZATIONS_LIMIT,
              limit: ORGANIZATIONS_LIMIT,
            });
            setActivePage(newPageNumber);
          }}
        />
      )}

      {isOrganizationModalOpen && (
        <OrganizationModal
          isNewItem={isNewItem}
          organizationData={selectedOrganization}
          isOpen={isOrganizationModalOpen}
          onClose={() => {
            setIsOrganizationModalOpen(false);
            if (selectedOrganization) setSelectedOrganization(undefined);
          }}
          onSave={() => {
            setIsOrganizationModalOpen(false);
            getOrganizations({});
            if (selectedOrganization) {
              setSelectedOrganization(undefined);
              if (selectedOrganization.id === user?.organization?.id) {
                fetchUserData();
              }
            }
          }}
        />
      )}
    </div>
  );
};

export default WithApiData(Organizations);
