import { useState, useEffect } from 'react';
import loadGooglePlacesAutoCompleteApi from '../utils/googleApi';
import useClientConfig from '../config/useClientConfig';

export default function useGoogleApi() {
  const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);
  const { VIM_GOOGLE_API_KEY } = useClientConfig();

  useEffect(() => {
    loadGooglePlacesAutoCompleteApi(VIM_GOOGLE_API_KEY).then(setIsGoogleApiLoaded);
  }, [VIM_GOOGLE_API_KEY]);

  return isGoogleApiLoaded;
}
