import * as React from 'react';
import dayjs from 'dayjs';
import Highlight from 'react-highlighter';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Formatter from '../../utils/formatter';
import { styles } from './style';
import { Member } from '../../types';

interface Column {
  id:
    | 'firstName'
    | 'lastName'
    | 'memberId'
    | 'zip'
    | 'dateOfBirth'
    | 'mrn'
    | 'insurer'
    | 'lineOfBusiness';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: string | Date, searchValue: string) => string | JSX.Element;
}

const highlightWrapper = (value, searchValue) => {
  return value ? (
    <Highlight matchStyle={{ background: '#e3fcef' }} search={`${searchValue}`}>
      {value}
    </Highlight>
  ) : (
    <span> no data</span>
  );
};

const columns: readonly Column[] = [
  {
    id: 'firstName',
    label: 'First Name',
    minWidth: 150,
    format: (value, searchValue) => highlightWrapper(value, searchValue),
  },
  {
    id: 'lastName',
    label: 'Last Name',
    minWidth: 150,
    format: (value, searchValue) => highlightWrapper(value, searchValue),
  },
  {
    id: 'memberId',
    label: 'Member ID',
    minWidth: 120,
    format: (value, searchValue) => highlightWrapper(value, searchValue),
  },
  {
    id: 'zip',
    label: 'Zip Code',
    minWidth: 100,
    format: (value, searchValue) => highlightWrapper(value, searchValue),
  },
  {
    id: 'dateOfBirth',
    label: 'Date of birth',
    minWidth: 120,
    format: (value, searchValue) => {
      return (
        <Highlight
          matchStyle={{ background: '#e3fcef' }}
          search={`${dayjs(searchValue).format('MM/DD/YYYY')}`}
        >
          {Formatter.dateViewFormatter(value as string)}
        </Highlight>
      );
    },
  },
  {
    id: 'mrn',
    label: 'MRN',
    minWidth: 100,
    format: (value, searchValue) => highlightWrapper(value, searchValue),
  },
  {
    id: 'insurer',
    label: 'Insurer',
    minWidth: 100,
    format: (value, searchValue) => highlightWrapper(value, searchValue),
  },
  {
    id: 'lineOfBusiness',
    label: 'Line of Business',
    minWidth: 100,
    format: (value, searchValue) => highlightWrapper(value, searchValue),
  },
];

interface MembersResultProps {
  members: Member[];
  searchParams: any;
}
// withStyles(styles)( ({ members, searchParams, classes }: MembersResultProps & WithStyles<typeof styles>)
const MembersResult = ({ members, searchParams }: MembersResultProps) => {
  return (
    <TableContainer style={{ height: '340px' }} component={Paper}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ height: '274px', position: 'relative' }}>
          {members.length ? (
            members.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  {columns.map((column) => {
                    const value = row[column.id] ?? 'none';
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value, searchParams[column.id]) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          ) : (
            <Box sx={styles.absolutePosition}>
              No matches - try to remove some of the lookup filters
            </Box>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MembersResult;
