import moment from 'moment';
import {
  SelectInputSchema,
  SelectMultipleInputSchema,
  DateInputSchema,
  InputSchema,
  InputOptionalSchema,
} from '../../schemas';
import createValidator from '../../utils/createValidator';
import { SelectTypeahead } from '../select-typeahead';
import { DateInput } from '../date-input';
import { SelectLabelsDropdown } from '../select-label-dropdown';
import { CleanInput } from '../clean-input';

export const fields = {
  referringProvider: {
    name: 'referringProvider',
    field: 'referringProvider',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(value, schema.selectInput);
    },
  },
  referTo: {
    name: 'referTo',
    field: 'referTo',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(value, schema.selectInput);
    },
  },
  specialty: {
    name: 'specialty',
    field: 'specialty',
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { specialty: value } : value;
        }
        return undefined;
      };

      return createValidator(getValue(), schema.selectInput);
    },
  },
  type: {
    name: 'type',
    field: 'type',
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { type: value } : value;
        }
        return undefined;
      };
      return createValidator(getValue(), schema.selectInput);
    },
  },
  priority: {
    name: 'priority',
    field: 'priority',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(
        typeof value === 'string' ? { priority: value } : value,
        schema.selectInput,
      );
    },
  },
  cpt: {
    name: 'cpt',
    field: 'cpt',
    component: SelectLabelsDropdown,
    validate(value: any) {
      return createValidator(value, schema.selectMultipleInput);
    },
  },
  icd: {
    name: 'icd',
    field: 'icd',
    component: SelectLabelsDropdown,
    validate(value: any) {
      return createValidator(value, schema.selectMultipleInput);
    },
  },
  reason: {
    description: '* Reason for referral',
    name: 'reason',
    field: 'reason',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  reasonOptional: {
    description: 'Reason for referral',
    name: 'reason',
    field: 'reason',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  startDate: {
    name: 'startDate',
    field: 'startDate',
    component: DateInput,
    validate(value: Date) {
      if (!value || moment(value).isBefore(Date.now(), 'day')) {
        return 'invalid value';
      } else {
        return false;
      }
    },
  },
  endDate: {
    name: 'endDate',
    field: 'endDate',
    component: DateInput,
    validate(value: any) {
      return createValidator(value, schema.dateInput);
    },
  },
};

export const schema = {
  selectInput: SelectInputSchema.required(),
  selectMultipleInput: SelectMultipleInputSchema.required(),
  dateInput: DateInputSchema.required(),
  input: InputSchema.required(),
  inputOptional: InputOptionalSchema,
};
