import React from 'react';
import { ReferralsOrdersNotes } from '../referral-order-notes';
import { ListType } from '../../types';
import './styles.less';

export const Orders = ({
  patient,
  appointmentId,
  api,
  isApptLocked,
}: {
  patient: any;
  appointmentId: number;
  api: any;
  isApptLocked: boolean;
}) => {
  return (
    <ReferralsOrdersNotes
      title="Orders"
      modalTitle="Order"
      newItemTitle="New Order"
      patient={patient}
      appointmentId={appointmentId}
      isApptLocked={isApptLocked}
      listType={ListType.orders}
      getItemsByApi={async () => {
        return await api.getOrders({ patientId: patient.id, appointmentId });
      }}
      prepareReferToText={(referTo: any): string => {
        return `${referTo?.location}`;
      }}
      handleItemCreate={async (body) => {
        return await api.createOrder(body);
      }}
      handleItemDelete={async (orderId) => {
        return await api.deleteOrder(orderId);
      }}
      handleItemUpdate={async (orderId: number, body: any) => {
        return await api.updateOrder(orderId, body);
      }}
    />
  );
};
