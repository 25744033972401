export enum OrderType {
  Lab = 'Lab',
  Radiology = 'Radiology',
  Procedure = 'Procedure',
}

export enum OrderTypeToStateType {
  Lab = 'LAB',
  Procedure = 'PROCEDURE',
  Radiology = 'DI',
}
