import React, { useState } from 'react';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.less';

const CustomToolbar = ({ onView, onNavigate, label, date }) => {
  const [viewState, setViewState] = useState('week');

  const addWeeks = (weeks) => moment(date).add(weeks, 'weeks').toDate();
  const addMonths = (months) => moment(date).add(months, 'months').toDate();

  const goToWeekView = () => {
    onView('week');
    setViewState('week');
  };

  const goToAgendaView = () => {
    onView('agenda');
    setViewState('agenda');
  };

  const goToBack = () => {
    viewState === 'week' ? onNavigate('prev', addWeeks(-1)) : onNavigate('prev', addMonths(-1));
  };

  const goToNext = () => {
    viewState === 'week' ? onNavigate('prev', addWeeks(1)) : onNavigate('prev', addMonths(1));
  };

  const goToToday = () => {
    const now = new Date();
    date.setMonth(now.getMonth());
    date.setYear(now.getFullYear());
    date.setDate(now.getDate());
    onNavigate('current');
  };

  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group toolbar-button-group">
        <button type="button" className="btn btn-primary" onClick={goToToday}>
          Today
        </button>
        <div className="rbc-btn-group toolbar-arrows">
          <button
            type="button"
            onClick={goToBack}
            title={`Previous ${viewState === 'week' ? 'week' : 'month'}`}
          >
            &#8249;
          </button>
          <button
            type="button"
            onClick={goToNext}
            title={`Next ${viewState === 'week' ? 'week' : 'month'}`}
          >
            &#8250;
          </button>
        </div>
      </div>

      <label className="rbc-toolbar-label">{label}</label>

      <div className="rbc-btn-group">
        <button
          type="button"
          className={viewState === 'week' ? 'rbc-active' : undefined}
          onClick={goToWeekView}
        >
          Weekly
        </button>
        <button
          type="button"
          className={viewState === 'agenda' ? 'rbc-active' : undefined}
          onClick={goToAgendaView}
        >
          Agenda
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
