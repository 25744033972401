import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { featureFlagClient } from './utils/featureFlagClient';
import './config/index';
import './App.less';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';

ReactDOM.render(
  <React.StrictMode>
    <FeatureFlagProvider featureFlagsClient={featureFlagClient}>
      <App />
    </FeatureFlagProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
