export interface ConfigI {
  VIM_OAUTH0_CLIENT_ID: string;
  VIM_OAUTH0_DOMAIN: string;
  VIM_OAUTH0_AUDIENCE: string;
  VIM_GOOGLE_API_KEY: string;
  VIM_CONSOLE_SSO_LINK: string;
  rollout__key?: string;
  APP_ENV?: string;
  VIM_PUBLIC_KEY: string;
}

export default { ...(window as any).$vim_environment } as ConfigI;
