import React, { useState, useMemo, useEffect } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useForm } from 'react-form';
import * as uuid from 'uuid';
import { BkmdModal, ModalHeader, Toast } from '@getvim/atomic-ui';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import { fields } from './itemModalDef';
import Field from '../form-field';
import Formatter from '../../utils/formatter';
import Loader from '../loader';

import './styles.less';
import { Patient, TaxonomyType } from '../../types';
import useApi from '../../hooks/useApi';

interface NewItemModalProps {
  isOpen?: boolean;
  onSave: (patient: any) => void;
  onClose: () => void;
  patient: Patient;
}

const { ToastTypes, createToast } = Toast;

export const ItemModal = ({ isOpen = true, onSave, onClose, patient }: NewItemModalProps) => {
  const modalTitle = 'Add Problem';
  const api = useApi();
  const initialData = {
    icd: { label: '', description: '' },
    notes: '',
  };

  const [formData, setFormData] = useState<any>(initialData);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [icdCodes, setIcdCodes] = useState<any[]>([]);
  const [tempNotes, setTempNotes] = useState<any[]>([]);

  const existingIds = tempNotes.map((item) => {
    return item.taxonomy.id;
  });
  const icdCodesList = icdCodes.filter((item) => !existingIds.includes(item.id));

  /** It's need to having additional info in dropdown option */
  const icdCodesMap = {};
  icdCodesList.forEach((item) => {
    icdCodesMap[item.label] = item.description;
  });

  const getTaxonomiesList = async () => {
    setLoading(true);
    const { data } = await api.getTaxonomiesList({ type: TaxonomyType.ICD });
    setIcdCodes(data);
    setLoading(false);
  };

  const handleApplyClick = async () => {
    try {
      setLoading(true);
      const data = await api.createPatientProblem(patient.id, {
        taxonomyId: formData.icd.id,
        notes: formData.notes.trim(),
      });
      setTempNotes((prevState: any) => {
        return [
          ...prevState,
          { taxonomy: formData.icd, notes: formData.notes.trim(), id: data.problemId },
        ];
      });
      resetFormData();
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.statusCode != '400' ? 'Something went wrong' : err.error?.message?.[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const onRemoveNotes = async (value) => {
    try {
      setLoading(true);
      await api.deletePatientProblem({ patientId: patient.id, id: value.id });
      setTempNotes((prevState: any) => {
        return [...prevState.filter((item) => item.id !== value.id)];
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaxonomiesList();
  }, []);

  const defaultValues = useMemo(
    () => ({
      ...formData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formProps = useForm({
    defaultValues,
    onSubmit: async () => {
      handleApplyClick();
    },
  });

  const {
    Form,
    meta: { canSubmit },
  } = formProps;

  // Update Form Field
  const updateFormData = ({ field, value }: { field: string; value: any }) => {
    setFormData((prevState: any) => {
      return {
        ...prevState,
        [`${field}`]: value,
      };
    });
  };

  // Reset Form
  const resetFormData = () => {
    setFormData({
      ...initialData,
    });
  };

  // OnClose Click Handler
  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <BkmdModal isOpen={isOpen} autoFocus={false} name="item-modal" className="item-modal-wrapper">
        <div
          id={`item-modal ${uuid.v1()}`}
          className="dialog-wrapper"
          data-id="item-modal"
          data-item-data={JSON.stringify(formData)}
        >
          <Form method="post" className="item-modal-form">
            <ModalHeader onClose={handleClose}>
              <div className="header-title">
                <p>{modalTitle}</p>
              </div>
            </ModalHeader>

            <div className="dialog-body">
              <div
                className="patient-header"
                data-id="patient-info"
                data-patient-info={JSON.stringify(patient)}
              >
                <div className="patient-header-avatar">
                  <img src={patient.profilePicture} alt=" " />
                </div>
                <div className="patient-header-text-side">
                  <div
                    id="patient-name"
                    data-patient-first-name={patient.firstName}
                    data-patient-last-name={patient.lastName}
                    className="patient-header-title"
                  >
                    {`${patient.firstName} ${patient.lastName}`}
                  </div>
                  <div className="patient-header-description">
                    DoB: {Formatter.formatDOB(patient.dob)}, Insurer: {patient.insurer}
                  </div>
                </div>
              </div>

              <hr />

              {isLoading && <Loader />}
              <div className="inputs-block">
                <div className="block">
                  <Field
                    submitted={submitted}
                    description="* Icd"
                    className="select-container"
                    labelKey="label"
                    filterBy={['label', 'description']}
                    options={icdCodesList}
                    onChange={(value) => {
                      updateFormData({
                        field: 'icd',
                        value: value[0],
                      });
                    }}
                    selected={formData.icd ? [{ ...formData.icd }] : []}
                    renderMenuItemChildren={(label, option) => (
                      <div data-code={option.label}>
                        {option.label}
                        <div className="small-description" data-code={option.description}>
                          <small>{option.description}</small>
                        </div>
                      </div>
                    )}
                    {...fields.icd}
                  />
                </div>

                <div className="block">
                  <Field
                    inputType
                    className="clean-input-container"
                    submitted={submitted}
                    {...fields.notes}
                    labelKey="notes"
                    value={formData.notes}
                    description="Notes"
                    onChange={(e: { target: { value: any } }) => {
                      updateFormData({
                        field: 'notes',
                        value: e.target.value?.replace(/^\s*/, ''),
                      });
                    }}
                  />
                </div>

                <Button
                  type="submit"
                  bsPrefix="btn btn-primary btn-add"
                  className="apply-btn add-problem-btn"
                  onClick={() => setSubmitted(true)}
                  disabled={isLoading || !formData.icd?.label}
                >
                  + Add
                </Button>
              </div>
              <Table striped borderless hover>
                <thead>
                  <tr>
                    <th>ICD</th>
                    <th>Description</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {tempNotes.map((item: any) => {
                    return (
                      <>
                        <tr key={item.id}>
                          <td>{item.taxonomy?.label}</td>
                          <td>{item.taxonomy?.description}</td>
                          <td>{item.notes}</td>
                          <td>
                            <IconTrash className="trash-icon" onClick={() => onRemoveNotes(item)} />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <div className="dialog-footer">
              <Row className="footer-btns">
                <Col xs={6}>
                  <Button
                    bsPrefix="btn btn-secondary"
                    className="cancel-btn"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    Close
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </BkmdModal>
    </div>
  );
};
