import React, { forwardRef } from 'react';

import './styles.less';

interface InputProps {
  ref?: any;
  error: any;
  label: string;
  submitted?: boolean;
  className?: string;
}

export const Input = forwardRef((props: InputProps, ref: any) => {
  const { error, submitted, label, className } = props;

  return (
    <div className={`${error && submitted && 'has-error'} date`} ref={ref}>
      <span className="input-title">{label}</span>
      <input {...props} className={`${className} input`} />
    </div>
  );
});
