import React from 'react';

import UserHeaderInfo from '../../components/user-header-info';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import SearchMember from './search-member';
import './styles.less';

const DataVerification = () => {
  const user = useUserData();

  return (
    <div className="data-verification">
      <div className="header-content">
        <UserHeaderInfo name={user.firstName} />
      </div>
      <div className="data-verification__header">
        <h1 className="data-verification__header__title">Data Verification</h1>
      </div>
      <SearchMember />
    </div>
  );
};

export default WithApiData(DataVerification);
