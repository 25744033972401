import React, { useState } from 'react';
import moment from 'moment';
import { Button } from '@getvim/atomic-ui';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.less';

const CustomToolbar = ({ onView, onNavigate, label, date }) => {
  const [viewState, setViewState] = useState('week');

  const addWeeks = (weeks) => moment(date).add(weeks, 'weeks').toDate();
  const addMonths = (months) => moment(date).add(months, 'months').toDate();

  const goToWeekView = () => {
    onView('week');
    setViewState('week');
  };

  const goToAgendaView = () => {
    onView('agenda');
    setViewState('agenda');
  };

  const goToBack = () => {
    viewState === 'week' ? onNavigate('prev', addWeeks(-1)) : onNavigate('prev', addMonths(-1));
  };

  const goToNext = () => {
    viewState === 'week' ? onNavigate('prev', addWeeks(1)) : onNavigate('prev', addMonths(1));
  };

  const goToToday = () => {
    const now = new Date();
    date.setMonth(now.getMonth());
    date.setYear(now.getFullYear());
    date.setDate(now.getDate());
    onNavigate('current');
  };

  return (
    <div className="rbc-toolbar v2">
      <div className="rbc-btn-group toolbar-button-group v2">
        <Button type="button" buttonType="tiny" onClick={goToToday}>
          Today
        </Button>
        <div className="rbc-btn-group toolbar-arrows">
          <Button
            bgColor="btn-group-option-outline"
            className="previous-day-btn"
            buttonType="tiny"
            type="button"
            onClick={goToBack}
            title={`Previous ${viewState === 'week' ? 'week' : 'month'}`}
          >
            &#8249;
          </Button>
          <Button
            bgColor="btn-group-option-outline"
            buttonType="tiny"
            className="next-day-btn"
            type="button"
            onClick={goToNext}
            title={`Next ${viewState === 'week' ? 'week' : 'month'}`}
          >
            &#8250;
          </Button>
        </div>
      </div>

      <label className="rbc-toolbar-label">{label}</label>

      <div className="rbc-btn-group view-options">
        <Button
          bgColor="btn-group-option-outline"
          buttonType="tiny"
          type="button"
          className={viewState === 'week' ? 'rbc-active' : undefined}
          onClick={goToWeekView}
        >
          Weekly
        </Button>
        <Button
          bgColor="btn-group-option-outline"
          buttonType="tiny"
          type="button"
          className={viewState === 'agenda' ? 'rbc-active' : undefined}
          onClick={goToAgendaView}
        >
          Agenda
        </Button>
      </div>
    </div>
  );
};

export default CustomToolbar;
