import React from 'react';

export default function SvgCalendarLoader() {
  /* eslint-disable */
  return (
    <svg
      id="e22ef94dsfoqi1"
      viewBox="0 0 134 110"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      width="134"
      height="110"
    >
      <defs>
        <path
          id="e22ef94dsfoqi2"
          d="M108.960632,15C112.979526,15,116.237483,18.076426,116.237483,21.871389L126.691765,87.149584C126.691765,90.944547,123.433808,94.020973,119.414914,94.020973L47.731132,94.020973C43.712239,94.020973,40.454282,90.944547,40.454282,87.149584L18,21.871389C18,18.076426,21.257957,15,25.276851,15L108.960632,15Z"
        />
        <path
          id="e22ef94dsfoqi3"
          d="M91.106754,-0C95.132103,-0,98.395294,3.285319,98.395294,7.337965L98.395294,77.048630C98.395294,81.101276,95.132103,84.386595,91.106754,84.386595L7.288540,84.386595C3.263191,84.386595,-0,81.101276,-0,77.048630L-0,7.337965C-0,3.285319,3.263191,-0,7.288540,-0L91.106754,-0Z"
        />
      </defs>
      <g id="e22ef94dsfoqi4">
        <g id="e22ef94dsfoqi5" transform="matrix(1 0 0 1 -38 -131)">
          <g id="e22ef94dsfoqi6" transform="matrix(1 0 0 1 39 131)">
            <g id="e22ef94dsfoqi7">
              <use
                id="e22ef94dsfoqi8"
                width="108.691765"
                height="79.020973"
                xlinkHref="#e22ef94dsfoqi2"
                fill="rgb(255,255,255)"
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="e22ef94dsfoqi9"
                d="M108.960632,14.500000L25.276851,14.500000C23.132753,14.500000,21.192764,15.322612,19.788060,16.649047C18.353022,18.004125,17.475862,19.884126,17.500506,21.956451L39.954789,87.234649C39.978695,89.239611,40.846676,91.054026,42.242342,92.371926C43.647046,93.698361,45.587035,94.520973,47.731132,94.520973L119.414914,94.520973C121.559011,94.520973,123.499001,93.698361,124.903705,92.371926C126.328450,91.026567,127.203292,89.163789,127.191650,87.109083L116.737368,21.830888C116.725894,19.808256,115.855422,17.976705,114.449423,16.649047C113.044719,15.322612,111.104730,14.500000,108.960632,14.500000Z"
                fill="rgb(0,46,91)"
                fillOpacity="0.35"
                fillRule="evenodd"
                stroke="rgb(0,46,91)"
                strokeWidth="1"
              />
            </g>
            <g id="e22ef94dsfoqi10" transform="matrix(1 0 0 1 0 94.33799999999999)">
              <line
                id="e22ef94dsfoqi11"
                x1="26"
                y1="14.461958"
                x2="80.552920"
                y2="14.461958"
                fill="none"
                fillRule="evenodd"
                stroke="rgb(50,51,60)"
                strokeWidth="1"
                strokeLinecap="round"
              />
              <line
                id="e22ef94dsfoqi12"
                x1="77"
                y1="9.341958"
                x2="116.680000"
                y2="9.341958"
                fill="none"
                fillRule="evenodd"
                stroke="rgb(50,51,60)"
                strokeWidth="1"
                strokeLinecap="round"
              />
              <line
                id="e22ef94dsfoqi13"
                x1="0"
                y1="9.341958"
                x2="20.480000"
                y2="9.341958"
                fill="none"
                fillRule="evenodd"
                stroke="rgb(50,51,60)"
                strokeWidth="1"
                strokeLinecap="round"
              />
              <line
                id="e22ef94dsfoqi14"
                x1="118.500000"
                y1="1.161958"
                x2="132.077647"
                y2="1.243892"
                fill="none"
                fillRule="evenodd"
                stroke="rgb(50,51,60)"
                strokeWidth="1"
                strokeLinecap="round"
              />
            </g>
            <g id="e22ef94dsfoqi15" transform="matrix(1 0 0 1 17 14)">
              <g id="e22ef94dsfoqi16">
                <mask id="e22ef94dsfoqi17" mask-type="luminance">
                  <use
                    id="e22ef94dsfoqi18"
                    width="98.395294"
                    height="84.386597"
                    xlinkHref="#e22ef94dsfoqi3"
                    fill="rgb(255,255,255)"
                    fillRule="evenodd"
                    stroke="none"
                    strokeWidth="1"
                  />
                </mask>
              </g>
              <use
                id="e22ef94dsfoqi19"
                width="98.395294"
                height="84.386597"
                xlinkHref="#e22ef94dsfoqi3"
                fill="rgb(255,255,255)"
                fillRule="evenodd"
                stroke="rgb(0,46,91)"
                strokeWidth="1"
              />
            </g>
            <rect
              id="cell1"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 26 36)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell2"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 48 36)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell3"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 70.75510000000000 36.29180000000000)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell4"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 93.05159999999999 36.29180000000000)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell5"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 25.67750000000000 56)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell6"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 47.97400000000000 55.80310000000000)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell7"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 70.40000000000001 56)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell8"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 93.05159999999999 55.80310000000000)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell9"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 25.67750000000000 75.80230000000000)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell10"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 47.97400000000000 75.80230000000000)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <rect
              id="cell11"
              width="14.056500"
              height="14.145700"
              rx="3.840000"
              ry="3.840000"
              transform="matrix(1 0 0 1 70.75510000000000 75.80230000000000)"
              opacity="1"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="rgb(0,46,91)"
              strokeWidth="1"
            />
            <path
              id="e22ef94dsfoqi31"
              d="M108.106754,14C112.132103,14,115.395294,17.208955,115.395294,21.167402L115.395294,28.103597C115.395294,32.062044,17,32.062044,17,28.103597L17,21.167402C17,17.208955,20.263191,14,24.288540,14L108.106754,14Z"
              fill="rgb(0,46,91)"
              fillOpacity="0.15"
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
            <line
              id="e22ef94dsfoqi32"
              x1="42.399847"
              y1="19.219339"
              x2="42.399847"
              y2="8.000312"
              fill="none"
              fillRule="evenodd"
              stroke="rgb(50,51,60)"
              strokeWidth="1.280000"
              strokeLinecap="round"
            />
            <line
              id="e22ef94dsfoqi33"
              x1="90.385729"
              y1="19.219339"
              x2="90.385729"
              y2="8.000312"
              fill="none"
              fillRule="evenodd"
              stroke="rgb(50,51,60)"
              strokeWidth="1.280000"
              strokeLinecap="round"
            />
            <line
              id="e22ef94dsfoqi34"
              x1="6.122941"
              y1="96.500000"
              x2="15.368823"
              y2="96.533069"
              fill="none"
              fillRule="evenodd"
              stroke="rgb(50,51,60)"
              strokeWidth="1"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
