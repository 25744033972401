import React from 'react';
import { Chip } from '@getvim/atomic-ui';
import './styles.less';

interface AppointmentLockStatusChipProps {
  isLocked?: boolean;
  text?: string;
}

const LOCKED_BG_COLOR = '#fd71714d';

export const AppointmentLockStatusChip = ({ text = 'Locked' }: AppointmentLockStatusChipProps) => {
  return <Chip className="appointment-lock-status-chip" bgColor={LOCKED_BG_COLOR} text={text} />;
};
