import React, { HTMLInputTypeAttribute } from 'react';

import './styles.less';

interface CleanInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  labelKey?: string;
  description: string;
  error?: any;
  submitted?: boolean;
  inputType?: HTMLInputTypeAttribute;
  showError?: boolean;
}

export const CleanInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, CleanInputProps>(
  (props: CleanInputProps, ref) => {
    const {
      className = '',
      labelKey,
      description,
      error,
      submitted,
      inputType,
      showError,
      ...inputProps
    } = props;

    const hasErrorToSHow = showError && error?.trim() && submitted;
    return (
      <div id={labelKey} className={`${className} v2-input`}>
        <div
          className={` ${error && submitted && 'has-error'} freetext-input freetext-input-wrapper`}
        >
          <span className={`${className} clean-input-label`}>{description}</span>
          {inputType === 'textarea' ? (
            <textarea
              ref={ref as React.Ref<HTMLTextAreaElement>}
              className="input textarea"
              {...(inputProps as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
            />
          ) : (
            <input
              type={inputType}
              ref={ref as React.Ref<HTMLInputElement>}
              className="input"
              {...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
            />
          )}

          {hasErrorToSHow ? <span className="clean-error-message">{error} </span> : <></>}
        </div>
      </div>
    );
  },
);
CleanInput.displayName = 'CleanInput';
