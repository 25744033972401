import React from 'react';
import { omit, isNil } from 'lodash-es';
import { useField, splitFormProps } from 'react-form';
import Formatter from '../../utils/formatter';
import './styles.less';

type Props = {
  name: string;
  component: any;
  submitted?: boolean;
  [key: string]: any;
  inputType?: boolean | string;
  numberType?: boolean;
  dateType?: boolean;
  placeholder?: string;
};

const Field: React.FC<Props> = React.forwardRef((props, ref) => {
  const { component, inputClassName, submitted, inputType, numberType, dateType } = props;

  const [field, fieldOptions, rest] = splitFormProps(props);
  const fieldProps = useField(field, fieldOptions);

  const {
    getInputProps,
    setValue,
    value,
    meta: { error, isTouched },
    placeholder = ' ',
  } = fieldProps;

  const Component = component;
  return (
    <Component
      className={inputClassName}
      {...getInputProps({
        ref,
        ...omit(rest, ['component']),
      })}
      {...props}
      placeholder={placeholder}
      error={error}
      isTouched={isTouched}
      submitted={submitted}
      onChange={(value: any) => {
        const valueToSubmit = value;
        if (inputType === 'tel') {
          setValue(Formatter.formatPhoneNumber(value.target.value.replace(/\D/g, '')));
        } else if (inputType === 'location') {
          setValue({
            addressLine1: value?.address || undefined,
            city: value?.city || undefined,
            state: value?.state || undefined,
            zip: value?.zip || undefined,
          });
        } else if (inputType) {
          setValue(value.target.value);
        } else if (numberType) {
          setValue(Number(value.target.value));
        } else if (dateType) {
          setValue(value);
        } else {
          setValue(!isNil(valueToSubmit) ? valueToSubmit[0] : valueToSubmit);
        }
        props.onChange(valueToSubmit);
      }}
      onAdd={(onAddValue: any) => {
        props.onAdd(onAddValue);
        setValue([...value, onAddValue.value]);
      }}
      onRemove={(onRemoveValue: any) => {
        props.onRemove(onRemoveValue);
        setValue([...value.filter((item: string) => item !== onRemoveValue)]);
      }}
    />
  );
});

export default Field;
