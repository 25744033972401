import React from 'react';
import { withAuth0, WithAuth0Props, useAuth0 } from '@auth0/auth0-react';
import { removeItem } from '../../utils/local-storage';

import './styles.less';

const UserNotFound = (props: WithAuth0Props & { title?: string }) => {
  const { logout, isAuthenticated } = useAuth0();
  const { title = 'Requested user not found in our system' } = props;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="login-page">
      <h2 className="login-form-title">{title}</h2>
      <form name="form">
        <div className="form-group login-form-submit">
          <a
            className="btn btn-primary"
            onClick={() => {
              removeItem('access_token');
              removeItem('user');
              logout({ returnTo: window.location.origin });
            }}
          >
            Logout
          </a>
        </div>
      </form>
    </div>
  );
};

export default withAuth0(UserNotFound);
