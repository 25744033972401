import React from 'react';
import UserHeaderInfo from '../../components/user-header-info';
import WithApiData from '../../hoks/withApiData';
import { useUserData } from '../../hooks/useUserData';
import { AuditList } from '../../components/audit-list';
import './styles.less';

const Audit = () => {
  const user = useUserData();

  return (
    <div className="audit-page">
      <div className="header-content">
        <UserHeaderInfo name={user.firstName} />
      </div>
      <div className="audit-page__list-header">
        <h1 className="audit-page__list-header__title">Audit list</h1>
      </div>
      <AuditList />
    </div>
  );
};

export default WithApiData(Audit);
