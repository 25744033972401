import React, { useEffect, useState, useRef } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import { isString } from 'lodash-es';
import useApi from '../../hooks/useApi';

import './styles.less';

type Props = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  isApptLocked: boolean;
};

const GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY = 'patient-instructions_general_notes';

const PatientInstructions: React.FC<Props> = ({ selectedAppointment, isApptLocked }) => {
  const [generalNotes, setGeneralNotes] = useState<undefined | string>();

  const generalNotesTimeout = useRef(null);

  const api = useApi();

  const handleGeneralNotesChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setGeneralNotes(value);

    //@ts-ignore
    clearTimeout(generalNotesTimeout.current);
    //@ts-ignore
    generalNotesTimeout.current = setTimeout(() => {
      api.upsertMetadata(selectedAppointment.id, {
        key: GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY,
        value,
      });
    }, 3000);
  };

  const parseAppointmentMetaData = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      if (key === GENERAL_NOTES_PATIENT_INSTRUCTIONS_KEY) {
        setGeneralNotes(value);
      }
    });
  };

  useEffect(() => {
    getAppointmentMedataData();
  }, [selectedAppointment]);

  const getAppointmentMedataData = async () => {
    if (selectedAppointment) {
      parseAppointmentMetaData(selectedAppointment.metadata);
    }
  };

  return (
    <Box className="text-area-wrapper-v2" display="flex" flexDirection="column" flexGrow={1}>
      <span className="main-section-title">Patient Instructions</span>
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          data-type-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          maxRows={12}
          minRows={5}
          value={generalNotes}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleGeneralNotesChanges}
        />
      </Box>
    </Box>
  );
};

export default PatientInstructions;
