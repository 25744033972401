import React, { useState, useMemo } from 'react';
import { BkmdModal, ModalHeader, Button } from '@getvim/atomic-ui';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-form';
import { omit } from 'lodash-es';
import { Organization } from '../../types';

import useApi from '../../hooks/useApi';

import './styles.less';

interface IOrganizationModal {
  isOpen: boolean;
  isNewItem: boolean;
  onClose: () => void;
  onSave: () => void;
  organizationData?: { id: number; alias: string; name: string };
}

const OrganizationModal = ({
  isOpen,
  isNewItem,
  onClose,
  onSave,
  organizationData,
}: IOrganizationModal) => {
  const initialData = {
    id: null,
    alias: '',
    name: '',
  };
  const [formData, setFormData] = useState(organizationData ?? initialData);

  const defaultValues = useMemo(
    () => ({
      ...initialData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const api = useApi();

  const canSaveOrganization = (): boolean => {
    const isNewOrgComplete = isNewItem && formData.name && formData.alias;
    const isOrgUpdated =
      !isNewItem &&
      formData.name &&
      formData.name.toLocaleLowerCase() !== organizationData?.name.toLocaleLowerCase();

    return Boolean(isNewOrgComplete || isOrgUpdated);
  };

  const handleSave = async () => {
    try {
      await api.createOrganization(omit(formData, 'id'));
      await onSave();
    } catch (error) {
      setMeta({
        error: `Oops, error! Make sure Alias and Name are not used by other Organizations.`,
      });
    }
  };

  const handleUpdate = async () => {
    try {
      // @ts-ignore
      await api.updateOrganization(formData.id, omit(formData, ['id', 'alias']));
      await onSave();
    } catch (e) {
      setMeta({ error: `Oops, error! Make sure Name is not used by other Organizations.` });
    }
  };

  const formProps = useForm({
    onSubmit: async (values) => {
      isNewItem ? await handleSave() : handleUpdate();
    },
    defaultValues: organizationData ?? defaultValues,
    validate: (values) => {
      if (!values.alias || !values.name) {
        return 'Please, enter Alias and Name';
      }
      return false;
    },
  });

  const {
    Form,
    meta: { error },
    setFieldValue,
    setMeta,
  } = formProps;

  const updateFormData = (newData: Partial<Organization>) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        ...newData,
      };
    });
  };

  const formatAliasInput = (newValue: string) => {
    return newValue
      ?.replace(/[±§!@#$%^&*()+=,<>.?|/\\"'`;:[\]{}~_]/g, '')
      ?.replace(/[A-Z]/g, '')
      ?.replace(/^\s*/, '')
      ?.replace(/^-*/, '')
      ?.replace(/\s{1,}/g, '-')
      ?.replace(/-{1,}/g, '-');
  };

  return (
    <div>
      <BkmdModal
        isOpen={isOpen}
        handleClose={onClose}
        autoFocus={false}
        name="organization-modal"
        className="organization-modal-wrapper-v2 sidebar-modal left"
        baseClassName="left-menu-backdrop"
      >
        <div className="dialog-wrapper">
          <Form className="organization-modal-form">
            <ModalHeader onClose={onClose}>
              <div className="header-title">
                {isNewItem ? (
                  'Add New Organization'
                ) : (
                  <>
                    Edit Organization
                    <span className="text-normal"> / {organizationData?.name}</span>
                  </>
                )}
              </div>
            </ModalHeader>
            <div className="dialog-body">
              <div className="select-container clean-input-container v2-input">
                <div className="freetext-input">
                  <span className="select-container select-label">Name *</span>
                  <input
                    value={formData?.name}
                    className="input"
                    placeholder=" "
                    onChange={(event) => {
                      updateFormData({ name: event.target.value });
                      setFieldValue('name', event.target.value);
                    }}
                  />
                </div>
              </div>
              {formData?.id && (
                <div className="select-container clean-input-container v2-input">
                  <div className="freetext-input">
                    <span className="select-container select-label">Id</span>
                    <input value={formData?.id} className="input" disabled />
                  </div>
                </div>
              )}
              <div className="select-container clean-input-container v2-input">
                <div className="freetext-input">
                  <span className="select-container select-label">Alias *</span>
                  <input
                    value={formData?.alias}
                    className="input"
                    placeholder=" "
                    disabled={!isNewItem}
                    onChange={(event) => {
                      const formattedAlias = formatAliasInput(event.target.value);
                      updateFormData({ alias: formattedAlias });
                      setFieldValue('alias', formattedAlias);
                    }}
                  />
                </div>
              </div>
              <div className="error">{error && <span>{error}</span>}</div>
            </div>
            <div className="dialog-footer">
              <Row className="footer-btns">
                <Col xs={6}>
                  <Button
                    bsPrefix="btn btn-secondary"
                    className="cancel-btn"
                    onClick={onClose}
                    buttonType="small"
                    width="small"
                    bgColor="themedOutline"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    type="submit"
                    bsPrefix="btn btn-primary"
                    className="apply-btn"
                    disabled={!canSaveOrganization()}
                    buttonType="small"
                    width="small"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </BkmdModal>
    </div>
  );
};

export default OrganizationModal;
