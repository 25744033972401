import moment from 'moment';
import {
  InputSchema,
  InputOptionalSchema,
  SelectInputSchema,
  SelectMultipleInputSchema,
  DateInputOptionalSchema,
  SelectInputOptionalSchema,
  SelectMultipleInputOptionalSchema,
} from '../../schemas';
import createValidator from '../../utils/createValidator';
import { SelectTypeahead } from '../select-typeahead';
import { DateInput } from '../date-input';
import { SelectLabelsDropdown } from '../select-label-dropdown';
import { CleanInput } from '../clean-input';

const commonFieldsOptional = {
  referringProvider: {
    description: 'Referring provider',
    name: 'referringProvider',
    field: 'referringProvider',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(value, schema.selectInputOptional);
    },
  },
  referTo: {
    description: 'Refer to',
    name: 'referTo',
    field: 'referTo',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(value, schema.selectInputOptional);
    },
  },
  specialty: {
    description: 'Specialty',
    name: 'specialty',
    field: 'specialty',
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { specialty: value } : value;
        }
        return undefined;
      };

      return createValidator(getValue(), schema.selectInputOptional);
    },
  },
  priority: {
    description: 'Priority',
    name: 'priority',
    field: 'priority',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(
        typeof value === 'string' ? { priority: value } : value,
        schema.selectInputOptional,
      );
    },
  },
  icd: {
    description: 'ICD',
    name: 'icd',
    field: 'icd',
    component: SelectLabelsDropdown,
    validate(value: any) {
      return createValidator(value, schema.selectMultipleInputOptional);
    },
  },
  cpt: {
    description: 'CPT',
    name: 'cpt',
    field: 'cpt',
    component: SelectLabelsDropdown,
    validate(value: any) {
      return createValidator(value, schema.selectMultipleInputOptional);
    },
  },
  reason: {
    description: 'Reason',
    name: 'reason',
    field: 'reason',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  startDate: {
    label: 'Start date',
    name: 'startDate',
    field: 'startDate',
    component: DateInput,
    validate(value: Date) {
      return createValidator(value, schema.dateInputOptional);
    },
  },
  endDate: {
    label: 'End date',
    name: 'endDate',
    field: 'endDate',
    component: DateInput,
    validate(value: any) {
      return createValidator(value, schema.dateInputOptional);
    },
  },
};

export const referralFields = {
  ...commonFieldsOptional,
  specialty: {
    ...commonFieldsOptional.specialty,
    required: true,
    description: 'Specialty*',
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { specialty: value } : value;
        }
        return undefined;
      };

      return createValidator(getValue(), schema.selectInput);
    },
  },
  reason: {
    ...commonFieldsOptional.reason,
    required: true,
    description: 'Reason*',
    validate(value: any) {
      return createValidator(value, schema.input);
    },
  },
  icd: {
    ...commonFieldsOptional.icd,
    required: true,
    description: 'ICD*',
    name: 'icd',
    field: 'icd',
    component: SelectLabelsDropdown,
    validate(value: any) {
      return createValidator(value, schema.selectMultipleInput);
    },
  },
  notes: {
    description: 'Notes',
    name: 'notes',
    field: 'notes',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  numberOfVisits: {
    description: 'Number of visits',
    name: 'numberOfVisits',
    field: 'numberOfVisits',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  authCode: {
    description: 'Auth code',
    name: 'authCode',
    field: 'authCode',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
};

export const orderFields = {
  ...commonFieldsOptional,
  referringProvider: {
    ...commonFieldsOptional.referringProvider,
    required: true,
    description: 'Referring provider*',
    validate(value: any) {
      return createValidator(value, schema.selectInput);
    },
  },
  referTo: {
    ...commonFieldsOptional.referTo,
    required: true,
    description: 'Refer to*',
    validate(value: any) {
      return createValidator(value, schema.selectInput);
    },
  },
  type: {
    description: 'Type*',
    required: true,
    name: 'type',
    field: 'type',
    component: SelectTypeahead,
    validate(value: any) {
      const getValue = () => {
        if (value) {
          return typeof value === 'string' ? { type: value } : value;
        }
        return undefined;
      };
      return createValidator(getValue(), schema.selectInput);
    },
  },
};

export const schema = {
  input: InputSchema.required(),
  inputOptional: InputOptionalSchema,
  selectInput: SelectInputSchema.required(),
  selectInputOptional: SelectInputOptionalSchema,
  selectMultipleInput: SelectMultipleInputSchema.required(),
  selectMultipleInputOptional: SelectMultipleInputOptionalSchema,
  dateInputOptional: DateInputOptionalSchema,
};
