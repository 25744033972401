import { Team } from '@getvim/vim-code-ownership/team';

// 15s timeout, in case rollout is causing issues in their initialization..
export const TIMEOUT_INITIALIZING_ROLLOUT: number = 30000;

export const ROLLOUT_PING_TIMEOUT_IN_MS: number = 3000;

// Time interval between logs in milliseconds (2 minutes).
export const LOG_INTERVAL: number = 120000;

// for more details see: https://app.rollout.io/app/5bed72e46a25e63420fce3ca/settings/properties
export type FlagContext = {
  adapterName?: string;
  deployConfig?: string;
  /**
   * @deprecated use `deviceId` instead
   */
  devideId?: string;
  deviceId?: string | null;
  insurer?: string;
  /**
   * @deprecated use `organizationId_string` instead for better management in rollout
   */
  organizationId?: number;
  organizationId_string?: string; // easier setting in rollout than organizationId property (for multiple organizations)
  organizationKey?: string;
  organizationName?: string;
  url?: string;
  vimUserEmail?: string;
  vimVersion?: string; // semver vim version
  [someKey: string]: string | number | boolean | undefined | null;
  isGlobalSessionFlagConfigured?: boolean;
};

export interface FlagOptions<T extends string | boolean | number> {
  flagName: string;
  defaultValue: T;
  flagContext?: FlagContext;
  team?: Team;
  namespace?: string;
  isSessionFlag?: boolean;
  waitForContext?: boolean;
}

export interface ClientOptions {
  rolloutKey?: string;
  team?: Team;
  namespace?: string;
  defaultContext?: FlagContext;
}
