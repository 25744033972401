// TODO: Fix component! It's not working properly and not following react hooks rules.
/* eslint-disable */
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import { defineMessages } from 'react-intl';
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';
import withIntl, { WithIntlProps, WithLanguageProps } from '@getvim/components-hocs-with-intl';
import getGeoAddressAndZip from './geocodeByLocation';
import GeoType from './GeoType';
import es from './translations/es.json';

import './LocationSelect.less';

export type Geo = GeoType;
export interface LocationSelectProp {
  onChange: (value: GeoType | null) => void;
  onInputChange?: (value: string) => void;
  className?: string;
  isGoogleApiLoaded: boolean;
  placeholder?: string;
  label?: string;
  defaultOptions?: GeoType[];
  geo?: GeoType | null;
  inputRef?: any;
  onSubmit?: () => void;
  requiredError?: boolean;
  withState?: boolean;
  withCity?: boolean;
  defaultAddressInput?: string;
  showChevronIcon?: boolean;
  onEmptyAddressOptions?: ({ error, addressInput }: { error: string, addressInput: string }) => void;
}

const searchOptions = {
  componentRestrictions: { country: ['US', 'PR', 'VI', 'GU', 'MP'] },
};

const messages = defineMessages({
  selectLocationPlaceHolder: {
    defaultMessage: 'Search Location...',
    id: 'selectLocation.placeholder',
  },
  loading: {
    defaultMessage: 'Loading...',
    id: 'selectLocation.loading',
  },
});

const LocationSelect: FunctionComponent<LocationSelectProp & WithIntlProps & WithLanguageProps> = ({
  language,
  geo,
  className,
  onChange,
  isGoogleApiLoaded,
  defaultOptions,
  placeholder,
  label,
  intl,
  inputRef: onInputRefCreated,
  onSubmit,
  requiredError = false,
  withState = false,
  withCity = false,
  defaultAddressInput = '',
  showChevronIcon = false,
  onInputChange = () => { },
  onEmptyAddressOptions = () => { },
}) => {
  const placeHolderToShow = placeholder || intl.formatMessage(messages.selectLocationPlaceHolder);
  if (!isGoogleApiLoaded) return null;
  const [focused, setFocused] = useState(false);
  const [inputRef, setInputRef] = useState<any>(null);
  const [addressInput, setAddressInput] = useState(defaultAddressInput);
  const [lastValidAddress, setLastValidAddress] = useState<string | null>(null);
  const [selectedGeo, setSelectedGeo] = useState<GeoType>();
  const [fullDefaultOptions, setFullDefaultOptions] = useState<GeoType[]>();
  const prevDefaultOptions = usePrevious<GeoType[] | undefined>(defaultOptions);

  const enrichGeoOptions = async (options: GeoType[]) => {
    const results = await Promise.all(
      options.map((option) => getGeoAddressAndZip(option, withState, withCity)),
    );
    setFullDefaultOptions(results);
  };

  useEffect(() => {
    if (defaultAddressInput && !geo) {
      setAddressInput(defaultAddressInput);
      return;
    }
    if (!geo) setAddressInput('');
    else {
      getGeoAddressAndZip(geo, withState, withCity).then((result) => {
        setSelectedGeo(result);
        setAddressInput(result.address || '');
        if (result.address) setLastValidAddress(result.address);
      });
    }
  }, [geo, defaultAddressInput]);

  useEffect(() => {
    onInputChange(addressInput);
  }, [addressInput]);

  useEffect(() => {
    if (!prevDefaultOptions && defaultOptions) enrichGeoOptions(defaultOptions);
  }, [defaultOptions]);

  const addressSelect = async (address: string, placeId: string | null) => {
    setLastValidAddress(null);
    setAddressInput(address);
    if (placeId === null && onSubmit) onSubmit();
    if (address && address !== selectedGeo?.address) {
      const result = await getGeoAddressAndZip({ address }, withState, withCity);
      onChange(result);
      if (result.address) setLastValidAddress(result.address);
    }
  };

  const onBlur = () => {
    setFocused(false);
    if (lastValidAddress) setAddressInput(lastValidAddress);
  };

  const clearSelectedLocation = () => {
    setAddressInput('');
    setSelectedGeo(undefined);
    addressSelect('', null);
    onChange(null);
    if (inputRef) inputRef.focus();
  };

  return (
    <div
      className={classNames('places-autocomplete', {
        'with-value': !!addressInput,
        required: requiredError,
        ...(language ? { [language]: true } : {}),
      })}
    >
      <PlacesAutocomplete
        onSelect={addressSelect}
        onChange={setAddressInput}
        onError={(error) => onEmptyAddressOptions({ error, addressInput })}
        value={addressInput}
        searchOptions={searchOptions}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <div
            className={classNames('clean-input location-autocomplete-wrap', {
              'in-focus': focused,
              className,
            })}
          >
            <input
              onFocus={() => setFocused(true)}
              {...getInputProps({
                placeholder: placeHolderToShow,
                className: 'clean-input location-autocomplete-select',
                ref: (ref) => {
                  if (onInputRefCreated) onInputRefCreated(ref);
                  setInputRef(ref);
                },
                onBlur,
              })}
            />
            {focused ? (
              <div className="autocomplete-dropdown-container location-autocomplete-options">
                {loading && <div>{intl.formatMessage(messages.loading)}</div>}
                {suggestions && suggestions.length
                  ? suggestions.map((suggestion) => (
                      <div
                        {...getSuggestionItemProps(suggestion)}
                        key={suggestion.placeId}
                        className={suggestion.active ? 'active' : undefined}
                      >
                        <strong>
                          <Highlighter
                            searchWords={addressInput.split(/[\s,]/).filter((word) => word)}
                            textToHighlight={suggestion.formattedSuggestion.mainText}
                          />
                        </strong>
                        <br />
                        {suggestion.formattedSuggestion.secondaryText}
                      </div>
                    ))
                  : fullDefaultOptions?.map((option) => (
                      <div
                        {...getSuggestionItemProps((option as unknown) as Suggestion, {
                          onClick: () => addressSelect(option.address!, option.address!),
                          className: 'suggestion-item custom-option',
                        })}
                        key={option.address}
                      >
                        {option.address}
                      </div>
                    ))}
              </div>
            ) : null}
            {showChevronIcon && (
              <div
                className={classNames(
                  'select-chevron-icon',
                  suggestions && suggestions.length && 'focused',
                )}
              >
                <i className="icon-chevron-down" />
              </div>
            )}
            <button
              className="btn btn-inline-link clear-input-icon"
              onClick={clearSelectedLocation}
              aria-label="Clear"
              type="button"
            >
              <i className="icon-x-2" />
            </button>
          </div>
        )}
      </PlacesAutocomplete>
      {label && <label className="places-autocomplete-label">{label}</label>}
    </div>
  );
};

export { getGeoAddressAndZip };

export default withIntl<LocationSelectProp>({ es })(LocationSelect);

function usePrevious<T>(value: T): T {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current as T;
}
