import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserData } from '../../hooks/useUserData';
import { UserRole } from '../../types/user';

const defaultRoles = [UserRole.ADMIN, UserRole.DATA_ACCESS, UserRole.PROVIDER];

const PrivateRoute = (props: any) => {
  const { roles = defaultRoles, location, isV2 = false } = props;
  const user = useUserData();

  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  if (!roles.includes(user.role)) {
    const pathname = isV2 ? '/v2/' : '/';
    return <Redirect to={{ pathname, state: { from: location } }} />;
  }
  return <Route {...props} />;
};

export default PrivateRoute;
