import React from 'react';
import { ReactComponent as IconProfile } from '../../assets/icons/icon-profile.svg';
import './styles.less';

const UserHeaderInfo = ({ name }: { name?: string }) => {
  return (
    <div className="user-header-info">
      <span className="user-name">Dr. {name}</span>
      <div className="icon-container">
        <IconProfile />
      </div>
    </div>
  );
};

export default UserHeaderInfo;
