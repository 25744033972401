import React, { ChangeEvent, useRef, useState } from 'react';
import { BkmdModal } from '@getvim/atomic-ui';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';

import addImage from '../../assets/icons/file_upload.svg';
import { ReactComponent as RevertIcon } from '../../assets/icons/icon-revert.svg';

import './styles.less';

export const ImageGalleryModal = ({
  isOpen,
  onClose,
  avatars = [],
  currentProfilePicture,
  setCurrentProfilePicture,
}) => {
  const [image, setImage] = useState(currentProfilePicture);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const openFileDialog = () => {
    hiddenFileInput!.current!.click();
  };
  const handleClose = () => {
    onClose();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files?.[0];
    reader.onloadend = () => {
      setImage({
        // @ts-ignore
        file,
        // @ts-ignore
        imageURL: reader.result,
      });
      /** because onChange won't work if you try to upload the same file twice */
      if (hiddenFileInput.current) {
        hiddenFileInput.current.value = '';
      }
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleRevertImage = () => {
    setImage(currentProfilePicture);
  };

  const handleConfirm = () => {
    setCurrentProfilePicture(image);
    handleClose();
  };

  return (
    <BkmdModal
      isOpen={isOpen}
      handleClose={handleClose}
      autoFocus={false}
      name="item-modal"
      className="upload-image-modal__wrapper"
    >
      <div className="upload-image-modal__element upload-image-modal__header">
        <h2>Upload image</h2>
        <CloseButton className="upload-image-modal__close" onClick={handleClose} />
      </div>
      <div className="upload-image-modal__selected-image">
        {image?.imageURL ? (
          <img className="upload-image-modal__image-preview" src={image.imageURL} />
        ) : (
          <img
            className="upload-image-modal__upload-icon"
            src={addImage}
            onClick={openFileDialog}
          />
        )}
      </div>
      <div className="upload-image-modal__element">
        {image.imageURL && image.imageURL !== currentProfilePicture.imageURL ? (
          <div className="upload-image-modal__button-group">
            <Button
              variant="contained"
              className="upload-image-modal__button"
              onClick={handleRevertImage}
            >
              <RevertIcon className="icon" />
            </Button>
            <Button
              variant="outline-info"
              className="upload-image-modal__button"
              disabled={!image.imageURL}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </div>
        ) : (
          <>
            <Button
              variant="outline-info"
              className="upload-image-modal__button"
              onClick={openFileDialog}
            >
              Upload
            </Button>

            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              ref={hiddenFileInput}
              accept="image/png, image/jpeg"
            />
          </>
        )}
      </div>
      <div className="upload-image-modal__element upload-image-modal__subtitle">
        Or select from existing:
      </div>
      <Container fluid>
        <Row>
          {avatars.map((url) => (
            <img
              className="upload-image-modal__gallery-image"
              src={url}
              key={url}
              onClick={() => setImage({ file: null, imageURL: url })}
            />
          ))}
        </Row>
      </Container>
    </BkmdModal>
  );
};
