import React, { useState, useEffect, useRef } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Toast } from '@getvim/atomic-ui';
import { Box, TextareaAutosize } from '@mui/material';
import debounce from 'debounce';
import { ReactComponent as IconTrash } from '../../assets/icons/trash.svg';
import { AppointmentType, TaxonomyType, AssessmentType, Taxonomy, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';
import { CleanInput } from '../clean-input';
import { SelectTypeahead, DescriptionPosition } from '../select-typeahead';
import Formatter from '../../utils/formatter';
import { isUndefined } from 'lodash-es';

import './styles.less';

const { ToastTypes, createToast } = Toast;

const PLAN_NOTES_METADATA_KEY = 'plans_notes';

const AssessmentCPT = ({
  selectedAppointment,
  type,
  isApptLocked, addedCpts,
  onUpdateCptList,
  handleLoading,
  sectionTitle,
  timeUpdateForWbTriggered,
  isWithGeneralNotes,
}: {
  selectedAppointment: AppointmentType;
  type: TaxonomyType;
  isApptLocked: boolean;
  addedCpts: AssessmentType[];
  onUpdateCptList: () => void;
  handleLoading: (value: boolean) => void;
  sectionTitle: string;
  timeUpdateForWbTriggered?: Date;
  isWithGeneralNotes?: boolean;
}) => {
  const [selected, setSelected] = useState<Taxonomy[]>([]);
  const [notes, setNotes] = useState<string>('');
  const [generalNotes, setGeneralNotes] = useState<string>();

  const [cptCodes, setCptCodes] = useState<Taxonomy[]>([]);
  const [assessments, setAssessments] = useState<AssessmentType[]>([]);

  const generalNotesTimeout = useRef(null);

  const api = useApi();

  useEffect(() => {
    if (isWithGeneralNotes) {
      getGeneralNotes();
    }
  }, [timeUpdateForWbTriggered]);

  useEffect(() => {
    if (isWithGeneralNotes && isUndefined(generalNotes)) {
      getGeneralNotes();
    }
  }, [selectedAppointment]);

  useEffect(() => {
    getAssessments();
  }, [addedCpts]);

  useEffect(() => {
    getTaxonomiesList();
  }, [assessments]);

  const getAssessments = async () => {
    if (addedCpts) {
      try {
        handleLoading(true);
        const filteredAssessments = addedCpts.reduce(
          (filtered: AssessmentType[], option: AssessmentType) => {
            if (option.taxonomy.type === type) {
              filtered.push(option);
            }
            return filtered;
          },
          [],
        );

        setAssessments(filteredAssessments);
      } catch (e) {
      } finally {
        handleLoading(false);
      }
    }
  };

  const getGeneralNotes = () => {
    if (selectedAppointment) {
      const generalNotes = parseGeneralNotesMetadata(selectedAppointment.metadata);
      setGeneralNotes(generalNotes);
    }
  };

  const getTaxonomiesList = async (search?: string) => {
    const { data } = await api.getTaxonomiesList({ type, search });
    const filteredData = data.filter(
      (taxonomy: { id: number }) =>
        !assessments.find((assessment) => assessment.taxonomy.id === taxonomy.id),
    );

    setCptCodes(filteredData);
  };

  const deleteCptCode = async (item: AssessmentType) => {
    handleLoading(true);

    const assessmentList = assessments.filter((assessment) => assessment.note?.id !== item.note.id);
    await api.removeAssessmentFromAppointment({
      noteId: item.note.id,
      appointmentId: selectedAppointment.id,
    });
    onUpdateCptList();

    cptCodes.push(item.taxonomy);
    setCptCodes([...cptCodes]);
    setAssessments([...assessmentList]);
    handleLoading(false);
  };

  const addCptCodes = async ({ taxonomy }: { taxonomy: Taxonomy }) => {
    try {
      handleLoading(true);
      const itemToAdd = { taxonomy, note: { notes, id: -1 } };
      const { noteId } = await api.addAssessmentToAppointment({
        appointmentId: selectedAppointment.id,
        taxonomyId: itemToAdd.taxonomy.id,
        notes: itemToAdd.note.notes?.trim(),
      });
      onUpdateCptList();

      itemToAdd.note.id = noteId;
      const cptCodesList = cptCodes.filter((item) => item.id !== taxonomy.id);
      setCptCodes([...cptCodesList]);
      setAssessments([itemToAdd, ...assessments]);
      setSelected([]);
      setNotes('');
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });
    } finally {
      handleLoading(false);
    }
  };

  const parseGeneralNotesMetadata = (metadata: MetadataType[]) => {
    return metadata?.find((item) => item.key === PLAN_NOTES_METADATA_KEY)?.value;
  };

  const handleGeneralNotesChange = (event) => {
    const value = event.target?.value;
    setGeneralNotes(value);

    //@ts-ignore
    clearTimeout(generalNotesTimeout.current);
    //@ts-ignore
    generalNotesTimeout.current = setTimeout(() => {
      api.upsertMetadata(selectedAppointment.id, { key: PLAN_NOTES_METADATA_KEY, value });
    }, 3000);
  };

  return (
    <>
      <span className="main-section-title">{sectionTitle}</span>
      <div className="assessment-v2">
        <div className="billing-info-block-v2">
          <div className="assessment-v2__select-wrapper">
            <SelectTypeahead
              description={`* ${type.toUpperCase()}`}
              className="select-container-v2"
              labelKey="label"
              disableFilter
              options={cptCodes}
              selected={selected}
              onInputChange={debounce((searchTerm: string) => getTaxonomiesList(searchTerm), 300)}
              onChange={(value) => {
                setSelected(value);
              }}
              descriptionPosition={DescriptionPosition.TOP}
              disabled={isApptLocked}
              // @ts-ignore
              renderMenuItemChildren={(label, option: Taxonomy) => (
                <div data-code={option.label}>
                  {option.label}
                  <div className="small-description" data-code={option.description}>
                    <small>{Formatter.toSentenceCase(option.description)}</small>
                  </div>
                </div>
              )}
            />

            <CleanInput
              disabled={isApptLocked}
              className="clean-input-container-v2"
              labelKey="notes"
              value={notes}
              description="Notes"
              onChange={(e: { target: { value: any } }) => {
                setNotes(e.target.value?.replace(/^\s*/, ''));
              }}
            />

            <Button
              disabled={!selected.length || isApptLocked}
              id="add-icd-btn"
              className="assessment-v2__add-icd-btn"
              bsPrefix="btn btn-primary-v2"
              onClick={() => {
                addCptCodes({ taxonomy: selected[0] });
              }}
            >
              <i className="icon-plus-fat i-va-fix-2" />
              &nbsp;
              <span>Add</span>
            </Button>
          </div>

          {isWithGeneralNotes && (
            <div>
              <Box
                className="text-area-wrapper-v2 no-padding"
                display="flex"
                flexDirection="column"
                flexGrow={1}
              >
                <div className="title">
                  <span>General notes</span>
                </div>
                <Box>
                  <TextareaAutosize
                    data-test-id="general-notes-text"
                    data-type-id="general-notes-text"
                    style={{ width: '100%', resize: 'none' }}
                    maxRows={2}
                    minRows={2}
                    value={generalNotes}
                    disabled={isApptLocked}
                    onChange={handleGeneralNotesChange}
                  />
                </Box>
              </Box>
            </div>
          )}

          <div className="table-wrapper-v2">
            <Table borderless hover>
              <thead>
                <tr>
                  <th className="label-column">{type.toUpperCase()}</th>
                  <th className="description-column">Description</th>
                  <th>Notes</th>
                  <th className="drop-icon-column" />
                </tr>
              </thead>
              <tbody>
                {assessments.map((item: any) => {
                  return (
                    <>
                      <tr key={item.taxonomy.id} className="main-row-v2">
                        <td className="label-column">{item.taxonomy.label}</td>
                        <td className="description-column" title={item.taxonomy.description}>
                          {Formatter.toSentenceCase(item.taxonomy.description)}
                        </td>
                        <td className="" title={item.note?.notes}>
                          {item.note?.notes || '-'}
                        </td>
                        <td>
                          <IconTrash
                            className={`trash-icon${isApptLocked ? ' disabled' : ''}`}
                            onClick={() => !isApptLocked && deleteCptCode(item)}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentCPT;
