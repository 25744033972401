import { InputOptionalSchema, SelectInputSchema } from '../../schemas';
import createValidator from '../../utils/createValidator';
import { CleanInput } from '../clean-input';
import { SelectTypeahead } from '../select-typeahead';

export const fields = {
  notes: {
    name: 'notes',
    field: 'notes',
    component: CleanInput,
    validate(value: any) {
      return createValidator(value, schema.inputOptional);
    },
  },
  icd: {
    name: 'icd',
    field: 'icd',
    component: SelectTypeahead,
    validate(value: any) {
      return createValidator(value, schema.selectInput);
    },
  },
};

export const schema = {
  inputOptional: InputOptionalSchema,
  selectInput: SelectInputSchema,
};
