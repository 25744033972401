import React, { useEffect, useState } from 'react';
import { Box, TextareaAutosize } from '@mui/material';
import { AppointmentType, MetadataType } from '../../types';
import useApi from '../../hooks/useApi';
import Loader from '../loader';
import { Toast } from '@getvim/atomic-ui';
import { isString } from 'lodash-es';

type SubjectiveProps = {
  selectedAppointment: AppointmentType;
  loading: boolean;
  onChange: (metadata: MetadataType[]) => void;
  isApptLocked: boolean;
};
const GENERAL_NOTES_KEY = 'objective_general_notes';
const PHYSICAL_EXAMINATION_KEY = 'objective_physical_examination';

const { ToastTypes, createToast } = Toast;

const Objective: React.FC<SubjectiveProps> = ({
  selectedAppointment,
  loading,
  onChange,
  isApptLocked,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState({});
  const [generalNotes, setGeneralNotes] = useState<undefined | string>();
  const [physicalExamination, setPhysicalExamination] = useState<undefined | string>();

  const api = useApi();

  const handleGeneralNotesChanges = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setGeneralNotes(event.target.value);
  };
  const handlePhysicalExaminationChanges = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setPhysicalExamination(event.target.value);
  };

  const parseSubjective = (metadata: MetadataType[]) => {
    metadata.forEach(({ key, value }) => {
      setInitialData((current) => {
        return {
          ...current,
          [key]: value,
        };
      });
    });
  };

  useEffect(() => {
    const data: MetadataType[] = [];
    if (isString(generalNotes)) {
      data.push({ key: GENERAL_NOTES_KEY, value: generalNotes });
    }
    if (isString(physicalExamination)) {
      data.push({ key: PHYSICAL_EXAMINATION_KEY, value: physicalExamination });
    }
    onChange(data);
  }, [generalNotes, physicalExamination]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    getSubjective();
  }, [selectedAppointment]);

  const getSubjective = async () => {
    if (selectedAppointment) {
      try {
        setLoading(true);
        const appointment = await api.getAppointment({ id: selectedAppointment.id });
        parseSubjective(appointment.metadata);
      } catch (err: any) {
        createToast({
          title: `Oops, error!`,
          message: err.error?.message[0],
          type: ToastTypes.ERROR,
          html: true,
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box className="text-area-wrapper" display="flex" flexDirection="column" flexGrow={1}>
      {isLoading && <Loader />}
      <Box>
        <div className="title">
          <span>General notes</span>
        </div>
        <TextareaAutosize
          data-test-id="general-notes-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={8}
          value={generalNotes ?? initialData[GENERAL_NOTES_KEY]}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handleGeneralNotesChanges}
        />
      </Box>
      <Box>
        <div className="title">
          <span>Physical examination</span>
        </div>
        <TextareaAutosize
          data-test-id="physical-exam-text"
          style={{ width: '100%', resize: 'none' }}
          minRows={8}
          value={physicalExamination ?? initialData[PHYSICAL_EXAMINATION_KEY]}
          placeholder="Enter notes"
          disabled={isApptLocked}
          onChange={handlePhysicalExaminationChanges}
        />
      </Box>
    </Box>
  );
};

export default Objective;
