export interface PatientProblem {
  id: number;
  status: PatientProblemStatus;
  notes: {
    id: number;
    createdAt: string;
    notes: string;
    provider: {
      id: string;
      firstName: string;
      lastName: string;
    };
  }[];
  taxonomy: {
    id: number;
    label: string;
    description: string;
  };
}

export enum PatientProblemStatus {
  Active = 'active',
  Stable = 'stable',
  Resolved = 'resolved',
  Remission = 'remission',
}

export interface CreatePatientProblemRequest {
  taxonomyId?: number;
  notes?: string;
  noteId?: number;
}
