import React from 'react';
import { Organization } from '../../types';

import './styles.less';

const OrganizationsList = ({
  organizationsList,
  onSelect,
}: {
  organizationsList: Organization[];
  onSelect: (organization: Organization) => void;
}) => {
  return (
    <>
      <div className="organizations-list-content-wrap">
        <div className="organizations-list" id="organizations">
          {organizationsList.map((organization: Organization) => {
            const { id, name, alias } = organization;

            return (
              <div
                className="organization-item"
                key={`organization-${id}`}
                onClick={() => {
                  onSelect(organization);
                }}
              >
                <div className="organization-avatar">
                  <h1>{name[0].toUpperCase()}</h1>
                </div>
                <div className="organization-text-side">
                  <h4 className="organization-title">{name}</h4>
                  <div className="id">
                    <b>Id:</b> {id}
                  </div>
                  <div className="alias">
                    <b>Alias:</b> {alias}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OrganizationsList;
