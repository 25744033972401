import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-form';
import { isEmpty, isArray, omit, get, isEqual } from 'lodash-es';
import * as uuid from 'uuid';
import debounce from 'debounce';
import { BkmdModal, ModalHeader } from '@getvim/atomic-ui';
import Loader from '../loader';

import { fields } from './itemModalDef';
import useApi from '../../hooks/useApi';
import { useUserData } from '../../hooks/useUserData';
import { ListType, OrderTypeToStateType, TaxonomyType } from '../../types';
import Field from '../form-field';
import { getItem, removeItem } from '../../utils/local-storage';

import './styles.less';
import { EhrSDKContext } from '../../SdkWrapper';
import dayjs from 'dayjs';
import { querySelectorAll } from 'dom-helpers';

const MAX_CPT_NUMBER = 4;
const MAX_ICD_NUMBER = 4;
const REFERS_LIMIT = 30;
const REFERS_OFFSET = 0;

interface NewItemModalProps {
  patient: { id: string; firstName: string; lastName: string; memberId: string };
  appointmentId?: number;
  isOpen: boolean;
  modalTitle: string;
  newItemTitle: string;
  isNewItem: boolean;
  onSave: (body: any) => void;
  onUpdate: (referralId: number, body: any) => void;
  onClose: () => void;
  listType: ListType;
  referral?: any;
}

interface ITaxonomy {
  label: string;
  description: string;
}

export const ItemModal = ({
  patient,
  appointmentId,
  isOpen,
  modalTitle,
  newItemTitle,
  isNewItem,
  onSave,
  onUpdate,
  onClose,
  listType,
  referral,
}: NewItemModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();
  const {
    isAdmin,
    organization: { id: organizationId },
    ...referringProvider
  } = useUserData();
  const { isSdkLoaded } = useContext(EhrSDKContext);

  const referToNameRef = useRef(null);

  const baseInitialData = {
    appointmentId,
    cpt: [],
    endDate: undefined,
    icd: [],
    priority: undefined,
    reason: undefined,
    notes: undefined,
    referTo: undefined,
    referringProvider: isAdmin ? undefined : referringProvider,
    startDate: undefined,
  };

  const initialData =
    listType === ListType.referrals
      ? {
          ...baseInitialData,
          specialty: '',
          numberOfVisits: undefined,
          authCode: undefined,
        }
      : {
          ...baseInitialData,
          type: '',
        };

  const [taxonomies, setTaxonomies] = useState<{
    specialty: ITaxonomy[];
    icd: ITaxonomy[];
    cpt: ITaxonomy[];
    priority: ITaxonomy[];
  }>({
    specialty: [],
    icd: [],
    cpt: [],
    priority: [],
  });
  const [providers, setProviders] = useState([]);
  const [onBoardedProviders, setOnBoardedProviders] = useState([]);
  const [formData, setFormData] = useState<any>(
    referral ? { ...referral, appointmentId, type: referral.referTo?.type } : { ...initialData },
  );
  const [refers, setRefers] = useState<any>({ keys: [], values: {} });
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [lastSetState, setLastSetState] = useState<any>(null);
  const [lastStateOrderId, setLastStateOrderId] = useState<string>('');
  const [newOrderStateDate, setNewOrderStateDate] = useState<any>();

  const handleWriteBackEvent = (e: any) => {
    e.preventDefault();
    const writeBackReferralHtml = document.querySelector('#referral-wb-payload');

    let writeBackReferral;
    if (writeBackReferralHtml) {
      const data = writeBackReferralHtml.getAttribute('data-item-data');
      writeBackReferral = data ? JSON.parse(data) : {};
    } else {
      writeBackReferral = getItem('referral_write_back');
    }

    const writebackPriority = get(writeBackReferral, 'priority');
    const writebackNotes = get(writeBackReferral, 'notes');
    const writebackReason = get(writeBackReferral, 'reason');

    let writeBackReferralFormatted = { ...writeBackReferral };

    // Converting the priority value from writeback to taxonomies format
    if (writebackPriority) {
      const priorityInTaxonomies = taxonomies.priority.find(({ label }) => {
        return label.toLowerCase() === writebackPriority.toLowerCase();
      });
      writeBackReferralFormatted.priority = priorityInTaxonomies?.label ?? formData.priority;
    }

    if (writebackNotes) {
      writeBackReferralFormatted.notes = formData.notes
        ? `${formData.notes}; ${writebackNotes}`
        : `${writebackNotes}`;
    }

    if (writebackReason) {
      writeBackReferralFormatted.reason = formData.reason
        ? `${formData.reason}; ${writebackReason}`
        : `${writebackReason}`;
    }

    for (const [field, value] of Object.entries(writeBackReferralFormatted)) {
      isArray(value)
        ? value.forEach((valueItem) => updateFormData({ field, value: valueItem }))
        : updateFormData({ field, value });
    }

    for (const field in writeBackReferralFormatted) {
      const isIcdCptArray = isArray(writeBackReferralFormatted[field]);
      isIcdCptArray
        ? setFieldValue(`${field}`, [...formData[field], ...writeBackReferralFormatted[field]])
        : setFieldValue(`${field}`, writeBackReferralFormatted[field]);
    }
    removeItem('referral_write_back');
  };

  const defaultValues = useMemo(
    () => ({
      ...formData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formProps = useForm({
    defaultValues,
    onSubmit: async () => {
      await handleApplyClick();
    },
  });

  const {
    Form,
    setFieldValue,
    meta: { canSubmit },
  } = formProps;

  useEffect(() => {
    getTaxonomiesList(TaxonomyType.SPECIALTY);
    getTaxonomiesList(TaxonomyType.CPT);
    getTaxonomiesList(TaxonomyType.ICD);
    getTaxonomiesList(TaxonomyType.PRIORITY);
    getOnBoardedProviders();
    getProviders();
    getRefers();
  }, []);

  useEffect(() => {
    if (!isSdkLoaded) return;
    // will be enhancing with referral
    setOrdersState();
  }, [isSdkLoaded, formData, lastSetState]);

  const setOrdersState = () => {
    const isItemWasNotSet =
      listType === ListType.orders && formData.id !== lastStateOrderId && !isNewItem;
    const isNewItemFilled =
      listType === ListType.orders &&
      isNewItem &&
      formData.icd.length &&
      formData.cpt.length &&
      formData.startDate;
    if (isItemWasNotSet || isNewItemFilled) {
      const order = mapEntityDataToState(formData);
      if (!isEqual(lastSetState, order)) {
        setLastStateOrderId(formData.id);
        setLastSetState(order);
        globalThis.VimConnectSDK.ehr.setState('orders', [order]);
      }
    }
  };

  // Fetch Data
  const getRefers = async () => {
    const refersList = await api.getRefersList({ limit: REFERS_LIMIT, offset: REFERS_OFFSET });
    const obj = {} as any;

    refersList.forEach((item: { type: string; id: number; location: string }) => {
      obj[item.type] = obj[item.type]
        ? [...obj[item.type], { id: item.id, location: item.location }]
        : [{ id: item.id, location: item.location }];
    });

    setRefers({ keys: Object.keys(obj), values: obj });
  };

  const getProviders = async (searchTerm?: string) => {
    const providers = await api.getProviders({ search: searchTerm, onBoarded: false });

    setProviders(providers.data);
  };

  const getOnBoardedProviders = async (searchTerm?: string) => {
    const providers = await api.getProviders({ search: searchTerm });

    setOnBoardedProviders(providers.data);
  };

  const getTaxonomiesList = async (type: TaxonomyType) => {
    const { data } = await api.getTaxonomiesList({ type });
    setTaxonomies((prevState) => {
      return { ...prevState, [type]: data };
    });
  };

  // Get ReferringProvider Field Default Selected Provider
  const getDefaultReferringProvider = () => {
    if (!isEmpty(referral)) {
      return [
        {
          referringProviderName: `${formData?.referringProvider?.firstName} ${formData?.referringProvider?.lastName}`,
        },
      ];
    }

    return isAdmin
      ? []
      : [
          {
            referringProviderName: `${referringProvider?.firstName} ${referringProvider?.lastName}`,
          },
        ];
  };

  // Get ReferTo Field Default Selected Provider
  const getReferToDefaultSelected = () => {
    if (isEmpty(formData?.referTo)) return [];

    return [
      {
        referToName:
          listType === ListType.referrals
            ? `${formData.referTo?.firstName} ${formData.referTo?.lastName}`
            : `${formData.referTo?.location}`,
      },
    ];
  };

  // Refer To Menu Options
  const getReferToOptions = () => {
    return listType === ListType.referrals
      ? providers
          .map((referTo: { firstName: string; lastName: string; id: string }) => {
            return {
              ...referTo,
              referToName: `${referTo?.firstName} ${referTo?.lastName}`,
            };
          })
          .filter((item: any) => item.id !== formData.referringProvider?.id)
      : (() => {
          if (!formData?.type || !refers.values[formData?.type]) return [];

          return refers.values[formData?.type].map((referTo: { location: any }) => {
            return {
              ...referTo,
              referToName: `${referTo.location}`,
            };
          });
        })();
  };

  // Update Form Field
  const updateFormData = ({ field, value }: { field: string; value: any }) => {
    if (field === 'type') {
      const instance = referToNameRef.current as any;
      setFieldValue('referTo', undefined);
      instance?.clear(); // Clear referTo field after type change
    }

    setFormData((prevState: any) => {
      return field === 'cpt' || field === 'icd'
        ? {
            ...prevState,
            [`${field}`]: [...prevState[field], value],
          }
        : field === 'type'
        ? {
            ...prevState,
            type: value,
            referTo: undefined,
          }
        : {
            ...prevState,
            [`${field}`]: value,
          };
    });
  };

  // Delete CPT, ICD codes
  const deleteArrayValueFromReferral = (label: string, removeValue: string) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [`${label}`]: prevState[label].filter((value: string) => value !== removeValue),
    }));
  };

  // Delete all added CPT, ICD codes
  const deleteAllValuesFromReferralByLabel = (label: string) => {
    setFormData((prevState: any) => ({
      ...prevState,
      [`${label}`]: [],
    }));
  };

  // Reset Form
  const resetFormData = () => {
    setFormData({
      ...initialData,
    });
  };

  // OnClose Click Handler
  const handleClose = () => {
    resetFormData();
    onClose();
  };

  // Full Name helper
  const formatPatientToSubtitle = ({
    firstName,
    lastName,
    memberId,
  }: NewItemModalProps['patient']) => {
    return `(${firstName} ${lastName}${memberId ? `, ${memberId}` : ''})`;
  };

  // State helper
  const mapEntityDataToState = (entity) => {
    if (listType === ListType.orders) {
      const orderCreatedDate = dayjs(new Date()).format('YYYY-MM-DD');
      const createdAt = entity?.createdAt
        ? dayjs(new Date(entity.createdAt)).format('YYYY-MM-DD')
        : orderCreatedDate;
      if (!entity?.createdAt) {
        setNewOrderStateDate(orderCreatedDate);
      }
      return {
        id: `${entity.id}`,
        type: OrderTypeToStateType[entity.type],
        icd: entity.icd.map((elem) => {
          const parsedCode = elem.split('-');
          return {
            code: parsedCode[0].trim(),
            description: parsedCode[1].trim(),
          };
        }),
        cpts: entity.cpt.map((elem) => {
          const parsedCode = elem.split('-');
          return {
            code: parsedCode[0].trim(),
            description: parsedCode[1].trim(),
          };
        }),
        encounterId: entity.appointmentId,
        createdAt,
      };
    }
  };

  // Handle Save Click (Create, Update)
  const handleApplyClick = async () => {
    const prepareSaveBody = () => {
      const createBody = {
        ...omit(
          {
            ...formData,
            patientId: patient.id,
            reason: formData.reason?.trim(),
            referringProviderId: formData.referringProvider.id,
            referTo: {
              ...(formData.referTo?.organization || formData.referTo?.organizationId
                ? {}
                : { organizationId }),
              ...omit(formData.referTo, [
                'phoneNumber',
                'gender',
                'suffix',
                'profilePicture',
                'clinics',
                'referToName',
                'email',
                'fullName',
                'organization',
                'role',
                'fax',
                'specialty',
                'degree',
                'visitType',
              ]),
            },
          },
          ['referringProvider'],
        ),
      };

      if (listType === ListType.orders) {
        return omit({ ...createBody, referToId: formData.referTo.id }, [
          'referTo',
          'type',
          'id',
          'location',
          'notes',
        ]);
      }

      return createBody;
    };

    const prepareUpdateBody = () => {
      const updateBody = omit(
        {
          ...formData,
          reason: formData.reason?.trim(),
          referringProviderId: formData?.referringProvider?.id,
          referTo: {
            ...(formData.referTo?.organization || formData.referTo?.organizationId
              ? {}
              : { organizationId }),
            ...omit(formData.referTo, [
              'phoneNumber',
              'gender',
              'suffix',
              'profilePicture',
              'clinics',
              'referToName',
              'email',
              'fullName',
              'organization',
              'role',
              'fax',
              'degree',
              'specialty',
              'visitType',
            ]),
          },
        },
        ['appointmentId', 'appointment', 'id', 'referringProvider', 'createdAt'],
      );

      if (listType === ListType.orders) {
        const updateBody = omit(
          {
            ...formData,
            referringProviderId: formData?.referringProvider?.id,
            referToId: formData.referTo.id,
          },
          ['appointment', 'appointmentId', 'id', 'referringProvider', 'createdAt'],
        );

        return {
          ...omit(updateBody, ['referTo', 'referToName', 'patient', 'type', 'location', 'notes']),
          patientId: updateBody.patient.id,
        };
      }

      return updateBody;
    };

    setIsLoading(true);

    try {
      isNewItem
        ? await onSave(prepareSaveBody())
        : await onUpdate(formData.id, prepareUpdateBody());
    } finally {
      setIsLoading(false);
    }
  };

  const getMinEndDate = () => {
    const today = new Date();
    if (!formData.startDate) {
      return today;
    }

    const startDateFomatted = new Date(formData.startDate);
    startDateFomatted.setDate(startDateFomatted.getDate() + 1);
    return startDateFomatted;
  };

  const getCptIcdOptions = (allOptions, addedValues) => {
    return allOptions.filter(
      (option: { label: string; description: string }) =>
        !addedValues.find((item: string) => {
          // need to support both cases when old CPT is code only or a new one with description too
          const isCodeOnlyAdded = option.label === item;
          const isCodeDescriptionAdded =
            item.includes(option.label) &&
            item.toLowerCase().includes(option.description.toLowerCase());
          return isCodeOnlyAdded || isCodeDescriptionAdded;
        }),
    );
  };

  return (
    <div>
      <BkmdModal
        isOpen={isOpen}
        handleClose={handleClose}
        autoFocus={false}
        name="item-modal"
        className="item-modal-wrapper"
      >
        <div
          id={`item-modal ${uuid.v1()}`}
          className="dialog-wrapper"
          data-id="item-modal"
          data-item-type={listType.toLowerCase()}
          data-is-new={isNewItem}
          data-item-id={referral?.id}
          data-item-data={JSON.stringify(formData)}
        >
          <Form method="post" className="item-modal-form" autoComplete="off">
            <ModalHeader onClose={handleClose}>
              <div className="header-title">
                <p>{isNewItem ? newItemTitle : `${modalTitle} #${referral?.id}`}</p>
                <p className="subtitle">{formatPatientToSubtitle(patient)}</p>
              </div>
            </ModalHeader>
            <div className="dialog-body">
              <Field
                submitted={submitted}
                description="* Referring provider"
                className="select-container"
                labelKey="referringProviderName"
                defaultSelected={getDefaultReferringProvider()}
                renderMenuItemChildren={(name: React.ReactNode, { npi }) => {
                  return <div data-code={npi}>{name}</div>;
                }}
                options={onBoardedProviders
                  .map((item: any) => ({
                    ...item,
                    referringProviderName: `${item.firstName} ${item.lastName}`,
                  }))
                  .filter((item) => item.onBoarded)
                  .filter((item) =>
                    formData?.referTo?.npi
                      ? item.npi !== formData?.referTo?.npi.toString()
                      : item.id !== formData.referTo?.id,
                  )}
                onInputChange={debounce((text: string) => getOnBoardedProviders(text), 300)}
                onChange={(value: any[]) => {
                  updateFormData({
                    field: 'referringProvider',
                    value: value[0],
                  });
                }}
                disabled={!isAdmin}
                {...fields.referringProvider}
              />

              {listType === ListType.referrals && (
                <Field
                  submitted={submitted}
                  description="* Speciality"
                  className="select-container"
                  labelKey="specialty"
                  filterBy={['specialty']}
                  options={taxonomies.specialty.map(({ label }) => {
                    return { specialty: label };
                  })}
                  onChange={(value: { specialty: any }[]) => {
                    updateFormData({
                      field: 'specialty',
                      value: value[0]?.specialty,
                    });
                  }}
                  renderMenuItemChildren={(specialty: React.ReactNode) => {
                    return <div>{specialty}</div>;
                  }}
                  // @ts-ignore
                  defaultSelected={[{ specialty: formData?.specialty ?? initialData?.specialty }]}
                  selected={isEmpty(formData?.specialty) ? [] : [formData?.specialty]}
                  clearButton
                  {...fields.specialty}
                />
              )}
              {listType === ListType.orders && (
                <Field
                  submitted={submitted}
                  description="* Type"
                  className="select-container"
                  labelKey="type"
                  disableFilter
                  inputProps={{ readOnly: true }}
                  options={refers.keys.map((type: any) => {
                    return { type };
                  })}
                  onChange={(value: { type: any }[]) => {
                    updateFormData({
                      field: 'type',
                      value: value[0]?.type,
                    });
                  }}
                  renderMenuItemChildren={(type: React.ReactNode) => {
                    return <div>{type}</div>;
                  }}
                  defaultSelected={[{ type: formData?.referTo?.type ?? '' }]}
                  {...fields.type}
                />
              )}
              <Field
                submitted={submitted}
                description="* Refer to"
                className="select-container"
                labelKey="referToName"
                options={getReferToOptions()}
                onInputChange={debounce((text: string) => getProviders(text), 300)}
                onChange={(value: any[]) => {
                  updateFormData({
                    field: 'referTo',
                    value: value[0],
                  });
                }}
                renderMenuItemChildren={(name: React.ReactNode, { npi }) => {
                  return <div data-code={npi}>{name}</div>;
                }}
                defaultSelected={getReferToDefaultSelected()}
                selected={getReferToDefaultSelected()}
                noResultsMessage={
                  listType === ListType.orders && isEmpty(formData?.referTo)
                    ? `Please, select Order Type first`
                    : undefined
                }
                ref={referToNameRef}
                clearButton
                {...fields.referTo}
              />
              <Field
                submitted={submitted}
                description="* Priority"
                className="select-container"
                labelKey="priority"
                disableFilter
                inputProps={{ readOnly: true }}
                options={taxonomies.priority.map(({ label }) => {
                  return { priority: label };
                })}
                onChange={(value: { priority: any }[]) => {
                  updateFormData({
                    field: 'priority',
                    value: value[0]?.priority,
                  });
                }}
                renderMenuItemChildren={(priority: React.ReactNode) => {
                  return <div>{priority}</div>;
                }}
                defaultSelected={formData?.priority ? [{ priority: formData?.priority }] : []}
                selected={formData?.priority ? [{ priority: formData?.priority }] : []}
                {...fields.priority}
              />
              <Field
                submitted={submitted}
                className="select-container"
                labelKey="cpt"
                smallDescriptionKey="description"
                addBy="cptFull"
                filterBy={['cpt', 'description']}
                description="* CPT"
                maxLabelsNumber={MAX_CPT_NUMBER}
                options={getCptIcdOptions(taxonomies.cpt, formData.cpt).map(
                  ({ label, description }) => {
                    return {
                      cpt: label,
                      description,
                      cptFull: `${label} - ${description}`,
                    };
                  },
                )}
                onAdd={(value: { field: string; value: string }) => {
                  updateFormData(value);
                }}
                onRemove={(value: string) => {
                  deleteArrayValueFromReferral('cpt', value);
                }}
                removeAdded={() => {
                  deleteAllValuesFromReferralByLabel('cpt');
                }}
                addedItems={formData.cpt}
                {...fields.cpt}
              />
              <Field
                submitted={submitted}
                className="select-container"
                labelKey="icd"
                smallDescriptionKey="description"
                addBy="icdFull"
                filterBy={['icd', 'description']}
                description="* ICD"
                maxLabelsNumber={MAX_ICD_NUMBER}
                options={getCptIcdOptions(taxonomies.icd, formData.icd).map(
                  ({ label, description }) => {
                    return { icd: label, description, icdFull: `${label} - ${description}` };
                  },
                )}
                onAdd={(value: { field: string; value: string }) => {
                  updateFormData(value);
                }}
                onRemove={(value: string) => {
                  deleteArrayValueFromReferral('icd', value);
                }}
                addedItems={formData.icd}
                removeAdded={() => {
                  deleteAllValuesFromReferralByLabel('icd');
                }}
                {...fields.icd}
              />
              <Field
                inputType
                className="select-container clean-input-container"
                submitted={submitted}
                labelKey="reason"
                value={formData.reason}
                onChange={(event) => {
                  updateFormData({
                    field: 'reason',
                    value: event.target.value?.replace(/^\s*/, ''),
                  });
                }}
                {...(listType === ListType.referrals ? fields.reason : fields.reasonOptional)}
              />
              {listType === ListType.referrals && (
                <div className="select-container">
                  <div className="freetext-input">
                    <span className="select-container select-label">Notes</span>
                    <input
                      value={formData.notes}
                      className="input"
                      onChange={(event) => {
                        updateFormData({
                          field: 'notes',
                          value: event.target.value?.replace(/^\s*/, ''),
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {listType === ListType.referrals && (
                <div className="select-container">
                  <div className="freetext-input">
                    <span className="select-container select-label">Number of Visits</span>
                    <input
                      value={formData.numberOfVisits}
                      className="input"
                      onChange={(event) => {
                        const fomattedNumberOfVisits = event.target.value?.replace(/[^\d]*/g, '');
                        updateFormData({
                          field: 'numberOfVisits',
                          value: fomattedNumberOfVisits ? +fomattedNumberOfVisits : null,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {listType === ListType.referrals && (
                <div className="select-container">
                  <div className="freetext-input">
                    <span className="select-container select-label">Auth Code</span>
                    <input
                      value={formData.authCode}
                      className="input"
                      onChange={(event) => {
                        const fomattedAuthCode = event.target.value?.replace(/[^\d]*/g, '');
                        updateFormData({
                          field: 'authCode',
                          value: fomattedAuthCode ? +fomattedAuthCode : null,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div>
                <div className="date-picker">
                  <Field
                    submitted={submitted}
                    dateType
                    value={
                      formData?.startDate ? new Date(formData?.startDate) : initialData.startDate
                    }
                    onChange={(date: any) => {
                      if (date >= formData.endDate) {
                        updateFormData({
                          field: 'endDate',
                          value: baseInitialData.endDate,
                        });
                      }

                      return updateFormData({
                        field: 'startDate',
                        value: date,
                      });
                    }}
                    selected={formData?.startDate && new Date(formData?.startDate)}
                    label="* Start date"
                    minDate={new Date()}
                    {...fields.startDate}
                  />
                  <Field
                    submitted={submitted}
                    dateType
                    showError
                    value={formData?.endDate ? new Date(formData?.endDate) : initialData.endDate}
                    onChange={(date: any) => {
                      return updateFormData({
                        field: 'endDate',
                        value: date,
                      });
                    }}
                    selected={formData?.endDate && new Date(formData?.endDate)}
                    label="* End date"
                    minDate={getMinEndDate()}
                    {...fields.endDate}
                  />
                  {isLoading && <Loader />}
                </div>
              </div>
            </div>
            <div className="dialog-footer">
              <Row className="footer-btns">
                <Col xs={6}>
                  <Button bsPrefix="btn btn-secondary" className="cancel-btn" onClick={handleClose}>
                    Cancel
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    type="submit"
                    bsPrefix="btn btn-primary"
                    className="apply-btn"
                    disabled={!canSubmit}
                    onClick={() => setSubmitted(true)}
                  >
                    Save
                  </Button>
                  <Button className="write-back-btn" onClick={handleWriteBackEvent}>
                    Write Back
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </BkmdModal>
    </div>
  );
};
