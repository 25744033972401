import React, { useState, forwardRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button } from '@getvim/atomic-ui';
import Formatter from '../../utils/formatter';

import { isEmpty } from 'lodash-es';

import './styles.less';

interface Option {
  [key: string]: string;
}

interface SelectLabelsDropdownProps {
  className: string;
  labelKey: string;
  smallDescriptionKey?: string;
  addBy?: string;
  filterBy: string[];
  description: string;
  onAdd({ field, value }: { field: string; value: string }): any;
  onRemove(value: string): any;
  addedItems: string[];
  options: any[];
  maxLabelsNumber?: number;
  error: any;
  submitted?: boolean;
  removeAdded(): any;
  isLoadingFlag?: boolean;
}

export const SelectLabelsDropdown = forwardRef((props: SelectLabelsDropdownProps, ref: any) => {
  const {
    className,
    labelKey,
    smallDescriptionKey,
    addBy,
    filterBy,
    description,
    onAdd,
    onRemove,
    addedItems = [],
    options = [],
    maxLabelsNumber,
    error,
    submitted,
    removeAdded,
    isLoadingFlag
  } = props;
  const [selectedItem, setSelectedItem] = useState<Option[]>([]);

  const isLabelsLimitReached: boolean = maxLabelsNumber
    ? addedItems.length >= maxLabelsNumber
    : false;

  return (
    <div id={labelKey} className={className}>
      <div
        className={`${className} ${
          error && submitted && 'has-error'
        } select-label-dropdown-wrapper`}
      >
        <div className={`${className} select-dropdown-label`}>
          <div className={`${className} select-dropdown-actions`}>
            <div className="dropdown">
              <Typeahead
                {...props}
                ref={ref}
                id="select-input"
                filterBy={filterBy ?? labelKey}
                renderMenuItemChildren={(value) => {
                  return (
                    <div id={labelKey} className="select-item" data-code={value.code} data-loading-status={isLoadingFlag}>
                      {value[labelKey]}
                      {smallDescriptionKey && (
                        <div>
                          <small>{Formatter.toSentenceCase(value[smallDescriptionKey])}</small>
                        </div>
                      )}
                    </div>
                  );
                }}
                onChange={(value) => {
                  setSelectedItem(value);
                }}
                options={options}
                multiple={false}
                selected={selectedItem}
                disabled={isLabelsLimitReached}
              />
              <span className={`${className} typeahead-select-label`}>{description}</span>
            </div>
            <div>
              <Button
                width="small"
                buttonType="small"
                disabled={isEmpty(selectedItem) || isLabelsLimitReached}
                id={`${labelKey} add-item-btn`}
                className="add-label-btn"
                onClick={() => {
                  onAdd({ field: labelKey, value: selectedItem[0][addBy ?? labelKey] });
                  setSelectedItem([]);
                }}
              >
                <i className="icon-plus-fat i-va-fix-2" />
                &nbsp;
                <span>Add</span>
              </Button>
            </div>
            <button
              className="clear-codes-button"
              data-id="codes"
              type="button"
              onClick={() => {
                if (!isEmpty(addedItems)) {
                  removeAdded();
                }
              }}
            >
              Clear codes
            </button>
          </div>
          {!isEmpty(addedItems) && (
            <div className="dropdown__item-chip">
              <div className="selected-labels-wrapper">
                {addedItems.map((item: string) => {
                  return (
                    <div key={item} data-code={item} className="selected-dropdown-label-item">
                      <div className="title">
                        <span>{Formatter.toSentenceCase(item)}</span>
                      </div>
                      <Button
                        buttonType="link"
                        bsPrefix="btn btn-secondary"
                        className="icon-x-2"
                        onClick={() => {
                          onRemove(item);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
