import React, { useEffect, useState, Fragment } from 'react';
import { Toast } from '@getvim/atomic-ui';

import { isNil, isEmpty } from 'lodash-es';
import Loader from '../loader';
import { NewItemButton } from '../referrals-orders-lists/newItemButton';
import { ItemModal } from '../referral-order-modal/itemModal';
import { ListItem } from '../referrals-orders-lists/listItem';
import { IListItem, IPatientProps } from '../referrals-orders-lists/types';
import { ListType } from '../../types';

import './styles.less';

interface ReferralsProps {
  title: string;
  modalTitle: string;
  patient: IPatientProps;
  appointmentId: number;
  isApptLocked: boolean;
  newItemTitle: string;
  getItemsByApi: ({ patient, appointmentId }: { patient: any; appointmentId: number }) => any;
  handleItemCreate: (body: any) => any;
  handleItemDelete: (id: number) => any;
  handleItemUpdate: (referralId: number, body: any) => any;
  prepareReferToText: (referTo: any) => string;
  listType: ListType;
}

const { ToastTypes, createToast } = Toast;

export const ReferralsOrdersNotes = ({
  title,
  modalTitle,
  patient,
  appointmentId,
  isApptLocked,
  listType,
  newItemTitle,
  getItemsByApi,
  handleItemCreate,
  handleItemDelete,
  handleItemUpdate,
  prepareReferToText,
}: ReferralsProps) => {
  const [items, setItems] = useState<any>();

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    const { data } = await getItemsByApi({ patient, appointmentId });
    setItems(data);
  };

  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IListItem | null>(null);

  const isNewItem = !selectedItem;

  const openModal = () => {
    setItemModalOpen(true);
  };

  const itemModalToggle = () => {
    setItemModalOpen(!itemModalOpen);
  };

  const processListItems = (items: IListItem[], listType: ListType) => {
    if (isEmpty(items)) {
      return <div className="referrals-orders__list-no-results">No {listType} added</div>;
    }

    return items.map((item: IListItem) => {
      return (
        <Fragment key={item.id}>
          <ListItem
            item={item}
            disabled={isApptLocked}
            prepareReferToText={prepareReferToText}
            handleDeleteItem={async () => {
              handleItemDelete(item?.id);
              getItems();
            }}
            handleSelect={(item) => {
              setSelectedItem(item);
              openModal();
            }}
          />
        </Fragment>
      );
    });
  };

  const errorToastWrapper = async (cb: any) => {
    try {
      await cb();
      setItemModalOpen(false);
    } catch (err: any) {
      createToast({
        title: `Oops, error!`,
        message: err.error?.message[0],
        type: ToastTypes.ERROR,
        html: true,
        position: 'top-right',
      });

      throw err;
    }
  };

  return (
    <>
      {isNil(items) && <Loader />}

      {!isNil(items) && (
        <div className="item-notes-wrapper">
          <div className="title">
            <span>{title}</span>
          </div>
          <div className="items-container">
            <div className="item-wrapper">{processListItems(items, listType)}</div>
            <div className="add-btn-wrapper">
              <NewItemButton
                handleClick={() => setItemModalOpen(true)}
                newItemTitle="Add"
                disabled={isApptLocked}
              />
            </div>
          </div>
          {itemModalOpen && (
            <ItemModal
              patient={patient}
              appointmentId={appointmentId}
              modalTitle={modalTitle}
              newItemTitle={newItemTitle}
              isNewItem={isNewItem}
              isOpen={itemModalOpen}
              onUpdate={async (id, body) => {
                const cb = async () => {
                  await handleItemUpdate(id, body);
                  await getItems();
                  setSelectedItem(null);
                };

                await errorToastWrapper(cb);
              }}
              onSave={async (body) => {
                const cb = async () => {
                  await handleItemCreate(body);
                  await getItems();
                  setSelectedItem(null);
                };

                await errorToastWrapper(cb);
              }}
              onClose={() => {
                itemModalToggle();
                setSelectedItem(null);
              }}
              listType={listType}
              referral={selectedItem}
            />
          )}
        </div>
      )}
    </>
  );
};
