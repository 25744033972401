import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import './index.less';

type InputValidationMessageProps = {
  className?: string;
};

function InputValidationMessage({
  className,
  children,
}: PropsWithChildren<InputValidationMessageProps>) {
  return (
    <div
      className={classNames('input-validation-message', className)}
      role="alert"
      aria-live="polite"
    >
      {children}
    </div>
  );
}

export default InputValidationMessage;
