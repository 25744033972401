import { createContext } from 'react';

export type GlobalContextObj = {
  enableImageUploader: boolean;
  enableUserAutoProvisioning: boolean;
};

export const GlobalContext = createContext<GlobalContextObj>({
  enableImageUploader: false,
  enableUserAutoProvisioning: false,
});
