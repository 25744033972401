export const styles = {
  inspect: {
    display: 'flex',
    alignItems: 'center',
  },
  box: { display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' },
  flexGrow: { flexGrow: 1 },
  button: { padding: '1.5rem' },
  gridRoot: {
    display: 'grid',
    gap: '20px',
  },
  userFormRoot: {
    margin: '20px 0',
    gap: '20px',
    gridTemplateColumns: 'auto',
  },
  memberProductsRow: {
    gridTemplateColumns: '30% 30%',
  },
  threeColumns: {
    gridTemplateColumns: 'auto auto auto',
  },
  absolutePosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loaderPosition: {
    position: 'absolute',
    top: '250px',
    left: '50%',
  },
};
